/* eslint-disable max-classes-per-file */
import React,
{
  useRef,
  useState,
} from 'react';
import {
  useStores,
} from 'hooks';
import {
  Assets,
} from 'assets';
import {
  baseScreen,
} from 'hoc';
import {
  FilterTypes,
  TableColumnTypes,
} from 'src/components/GeneralTableView/types';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import i18n from 'i18n-js';
import {
  useHistory,
} from 'react-router';
import SearchFilterDropdown from 'src/components/SearchFilterComponent';
import {
  PatientsTable,
} from './types';
import GeneralTableView from '../../components/GeneralTableView';
import {
  ListFilter,
} from '../doctors/types';

const patientsScreen: React.FC = () => {
  const {
    backend: {
      patients,
    },
  } = useStores();
  const history = useHistory();
  const ref = useRef<any|null>(null);
  const [
    currentFilter,
    setCurrentFilter,
  ] = useState((history.location.state as any)?.filter);
  const onFilterChange = (val: string) => {
    setCurrentFilter(val as ListFilter);
    ref.current?.onQueryChange();
  };
  return (
    <div>
      <SearchFilterDropdown
        label="Filter"
        style={{
          margin: 16,
        }}
        searchFilterDropdown={{
          filters: [
            {
              name: i18n.t('ALL'),
              value: ListFilter.ALL,
            },
            {
              name: i18n.t('RECENTLY_VIEWED'),
              value: ListFilter.RECENTLY_VIEWED,
            },

          ],
          value: currentFilter || 'all',
          initialValue: currentFilter || 'all',
          onChange: onFilterChange,
        }}
      />
      <GeneralTableView<PatientsTable>
        tableProps={{
          tableRef: ref,
          title: i18n.t('PATIENTS'),
          actions: [
            {
              icon: () => (
                <img
                  src={Assets.images.common.eye}
                  alt={i18n.t('VIEW')}
                />
              ),
              onClick: async (event, data) => {
                const patient: any = data;
                history.push(`/patient-details/${patient.id}`);
              },
            },
            {
              isFreeAction: true,
              icon: ImportExportIcon,
              onClick: () => {
                window.open(patients.download(), '_blanck');
              },
              tooltip: i18n.t('EXPORT_DATA'),
            },
          ],
        }}
        options={{
          search: true,
          actionsColumnIndex: 10,
          pageSize: 10,
        }}
        get={async (query) => {
          if (currentFilter === ListFilter.RECENTLY_VIEWED) {
            const data = patients._recentlyViewed || [];
            return ({
              data,
              totalCount: 20,
              page: 1,
            });
          }
          await patients.entityList.updateOptions(query);
          return {
            data: patients.entityList.data.map((item) => new PatientsTable(item)),
            totalCount: patients.entityList.meta.itemCount,
            page: patients.entityList.meta.page - 1,
          };
        }}
        tableColumns={[
          {
            field: 'name',
            title: i18n.t('NAME'),
            type: TableColumnTypes.STRING,
            filterType: FilterTypes.VALUE,
          },
          {
            field: 'mobileNumber',
            title: i18n.t('MOBILE_NUMBER'),
            type: TableColumnTypes.STRING,
            filterType: FilterTypes.VALUE,
          },
          {
            field: 'email',
            title: i18n.t('EMAIL'),
            type: TableColumnTypes.STRING,
          },
          {
            field: 'gender',
            title: i18n.t('GENDER'),
            type: TableColumnTypes.STRING,
          },
          {
            field: 'age',
            title: i18n.t('AGE'),
            type: TableColumnTypes.NUMERIC,
            filterType: FilterTypes.VALUE,
          },
        ]}
      />
    </div>
  );
};

export const PatientsScreen = baseScreen(patientsScreen, {
  allowedRoles: ['NONE', 'ADMIN'],
});
