/* eslint-disable */
import {
  LanguagesKey,
} from './types';

export const TRANSLATIONS = {
  [LanguagesKey.EN]: {
    '': '',
    EMAIL: 'Email',
    PASSWORD: 'Password',
    NEED_HELP: 'Need Help?',
    FORGOT_PASSWORD: 'Forgot Password?',
    DESC_LENGTH_VALIDATION: 'No more characters left',
    LOGIN: 'Login',
    SIGNUP: 'Sign up',
    REQUIRED_FIELD: 'This field is required',
    INVALID_FIELD: 'This field is invalid',
    WRONG_CREDENTIALS: 'Wrong email or password',
    HOME: 'Home',
    LOGOUT: 'Logout',
    COUNTRY_CODE: 'Country code',
    SEARCH: 'Search',
    DOCTOR_NOTES: 'Doctor\' Notes',
    PATIENT_NOTES: 'Patient\'s Notes',
    SERVICES: 'Services',
    REQUEST_ID: 'Request ID',
    DOCTORS: 'Doctors',
    NAME_VALIIDATION: 'Accepts English letters only',
    HELP_CENTER: 'Help center',
    PATIENTS: 'Patients',
    ADD: 'Add',
    PAYMENT: 'Payment',
    PROFILE: 'Profile',
    ALL: 'All',
    TYPE: 'Type',
    HOME_VISITS: 'Home visits',
    ONLINE_CONSULTATION: 'Online consultation',
    VIEW: 'View',
    EXPORT_DATA: 'Export Data in external csv file',
    CLINICS: 'Clinic',
    DATE: 'Date',
    ID: 'ID',
    DOCTOR_PHONE: 'Doctor Phone',
    DOCTOR: 'Doctor',
    STATUS: 'Status',
    TIME: 'Time',
    PATIENT: 'Patient',
    PATIENT_PHONE: 'Patient Phone',
    DELETE: 'Delete',
    Male: 'Male',
    Female: 'Female',
    DELETE_DOCTOR_CONFIRMATION: 'Are you sure you want to delete this doctor?',
    EDIT: 'Edit',
    ADD_NEW_DOCTOR: 'Add new doctor',
    CODE: 'Code',
    EDIT_PROFILE: 'Edit profile',
    AVATAR: 'Photo',
    NAME: 'Name',
    SYNDICATE_ID: 'Syndicate ID',
    WEBSITE: 'Website',
    SETTLEMENT: 'Settlement',
    SPECIALITY: 'Speciality',
    SPECIALITIES: 'Specialities',
    CONSULTATION_TYPE: 'Consultation Type',
    GRADE: 'Grade',
    DR: 'Doctor',
    Clinic: 'Clinic',
    'Home Visit': 'Home Visit',
    'Online Consultation': 'Online Consultation',
    MOBILE_NUMBER: 'Mobile Number',
    LAST_CHECKIN: 'Last check in',
    TODAYS_REQUESTS: 'Today\'s requests',
    ADD_DOCTOR_VERIFICATION_MESSAGE: 'An email will be sent to the doctor shortly, Thank you.',
    NOTES: 'Notes',
    CERTIFICATE_ID: 'Certificate ID',
    LANDLINE_NUMBER: 'Landline Number',
    LANGUAGES: 'Languages',
    GENDER: 'Gender',
    DOCTOR_NOT_FOUND: 'Doctor Not Found',
    REQUESTS_LIST: 'Consultation Requests:',
    CHANGE_LANGUAGE: 'Change Language',
    PASSWORD_VALIDATION: 'Password must include lowercase letters and uppercase letters and numbers',
    RECENTLY_ADDED: 'Recently added',
    RECENTLY_UPDATED: 'Recently updated',
    DESCRIPTION: 'Description',
    NATIONAL_ID_FRONT: 'National ID Front',
    ABOUT_DOCTOR: 'About Doctor',
    ENGLISH_NAME: 'English name',
    NATIONAL_ID_BACK: 'National ID Back',
    CERTIFICATES: 'Certificates',
    ARABIC_NAME: 'Arabic name',
    RECENTLY_VIEWED: 'Recently Viewed',
    TAX_ID: 'Tax ID',
    AGE: 'Age',
    IMAGE: 'Image',
    REQUEST_A_CALL: 'Request a call',
    ACCEPT_ONE_FILE: 'Only upload one file',
    REQUEST_CALL: 'Request',
    CITY: 'City',
    ACCEPTS_LETTERS_ONLY: 'Accepts letters only',
    ACCEPTS_NUMBERS_ONLY: 'Accepts numbers only',
    GOVERNORATE: 'Governorate',
    AREA: 'Area',
    SEND_FEEDBACK: 'Send feedback',
    FEEDBACK: 'Feedback',
    LOCATION: 'Location',
    STARTED_AT: 'Started at',
    INVALID_WEBSITE: 'invalid value ex(http://www.google.com)',
    FINISHED_AT: 'Finished at',
    ONLINE_CONSULTATION_REVENUE: 'Online consultation revenue',
    HOME_VISIT_REVENUE: 'Home visit revenue',
    CLINIC_REVENUE: 'Clinic revenue',
    GROSS_REVENUE: 'Gross revenue',
    NET_REVENUE: 'Net revenue',
    MONTHLY_BREAKDOWN: 'Monthly breakdown',
    HEALTH_INSURANCE_COMPANIES: 'Health Insurance Companies',
    COORDINATOR_NAME: 'Coordinator name',
    RESET: 'Reset',
    CONFIRM_PASSWORD: 'Confirm password',
    COORDINATOR_PHONE: 'Coordinator phone',
    MESSAGE: 'How Do you need Help?',
    INVALID_PHONE: 'Invalid mobile number Ex. 1091021133',
    FEEDBACK_MESSAGE: 'Write your feedback here...',
    COMMERCIAL_RECORD: 'Commercial Record',
    TOTAL: 'Total',
    SUBMIT: 'SUBMIT',
    CERTIFICATES_AND_OTHER_DOCS: 'Certificates and Other Docs',
    KASHF: 'Kashf',
    TO_NEXT_LANGUAGE: 'To next language',
    ACTIVITY_LOGS: 'Activity Logs',
    CASH: 'Cash',
    DROP_FILES_HERE: 'Drop files here... ',
    TERMS_AND_CONDITIONS_AGREEMENT: 'I agree to all terms and conditions',
    RECEIPT: 'Receipt',
    KASHF_PERCENTAGE: 'Kashf Percentage',
    DRAG_AND_DROP_FILES_HERE: 'Drag and Drop Files here',
    FROM: 'From',
    TO: 'To',
    HOME_VISIT_SCHEDULE: 'Home Visit Schdecule',
    ONLINE_CONSULTATION_SCHEDULE: 'Online Consultation Schdecule',
    CLINIC_SCHEDULE: 'Clinic Schdecule',
    FILTER: 'Filter',
    HOME_VISIT_FEES: 'Home Visit Fees',
    SUBTOTAL: 'Subtotal',
    POLICY_AGREEMENT_1: 'I read and agree to',
    DUE_DATE: 'Due Date',
    POLICY_AGREEMENT_2: 'Terms of Service and Privacy Policy',
    ONLINE_CONSULTATION_FEES: 'Online Consultation Fees',
    SCHEDULE_WRONG_ENTRY: 'Wrong entry, Please try again',
    REGISTERATION_THANK_YOU_MESSAGE: 'Thank you for registering with kashf. We will contact you soon.',
    INVALID_EMAIL: 'Invalid E-mail',
    CLINIC_FEES: 'Clinic Fees',
    DONE: 'Done',
    DESC_MAX_LENGTH_VALIDATION: 'Description must not exceed 500 characters',
    MESSAGE_RECEIVED_SUCCESSFULLY: 'Message received successfully',
    PASSWORD_LENGTH_ERROR: 'Password should be at least 8 characters',
    TRANSACTION_TYPE: 'Transaction Type',
    TRANSACTION_ID: 'Transaction ID',
    BANK_NAME: 'Bank Name',
    DR_ID: 'Doctor ID',
    SETTLE: 'Settle',
    FILE_PICKER_FILED_LENGTH: 'Attach only 1 item',
    PASSWORD_MUST_MATCH: 'password must match',
    PASSWORD_CHANGED_SUCCESSFULLY: 'Password changed successfully',
    BANK_ACCOUNT: 'Bank Account',
    EMAIL_SENT_SUCCESSFULLY: 'Please check your Email to reset your password',
    EMAIL_NOT_EXIST: 'Email doesn\'t exist',
    FAQ_SUBTITLE: 'Welcome to our support center. Here you can find the most frequently asked questions',
    FAQS_TITLE: 'FAQs',
    FAQ_QUESTION_1: 'I want to subscribe to 724 detection services what should I do?',
    FAQ_ANSWER_1: `You can visit the 724 detection 
    You can meet them easier on our website www.kashf247.com
    And if there is any problem, we are with you, or send us WhatsApp 01020020087
    
    Doctor
    Subscribe only via our website www.kashf247.com
    Then click on create new account
    Fill in the form
    If you finished it, you will submit
    The administrator will review the form and receive a message on your phone to start the activation
    And if in any problem we are with you or send us WhatsApp 01020020087.
    hospital
    Subscribe only via our website www.kashf247.com
    Then click on create new account
    Fill in the form
    If you finished it, you will submit
    The administrator will review the form and receive a message on your phone to start the activation
    And if in any problem we are with you or send us WhatsApp 01020020087.
    `,
    FAQ_QUESTION_2: 'Will the patient pay in cash or visa or both?',
    FAQ_ANSWER_2: `If the patient is asking for a home visit, he will choose
    If a doctor calls, a credit card is required    
    `,
    FAQ_QUESTION_3: 'Will the patient pay extra charge for house visits if the place is very far to the doctor chosen?',
    FAQ_ANSWER_3: 'Currently no, but the doctor has the right to accept or not accept the visit as well.',
    FAQ_QUESTION_4: 'How much money will be deducted for taxes?',
    FAQ_ANSWER_4: `If you are a doctor:
    5% of your net fees if you register your tax card
    20% if you did not register your tax card on Kashf.
    `,
    FAQ_QUESTION_5: 'May I take a receipt for the insurance?',
    FAQ_ANSWER_5: 'If you are sick, you are entitled to a bill that you request from the doctor during the home visit',
    FAQ_QUESTION_6: 'When will the hospital get its money from the visits ?',
    FAQ_ANSWER_6: 'Per month, with six shares of each doctor they have',
    FAQ_QUESTION_7: 'When will the doctor get his money?',
    FAQ_ANSWER_7: `In case the patient pays in cash immediately,
    in case the patient pays on credit within days, up to a maximum of two weeks, and the doctor has a share from a hospital account, the hospital shall review it for that.`,
    FAQ_QUESTION_8: 'What is the difference between this and Vezeeta?',
    FAQ_ANSWER_8: `Kashf that differs from Vezeeta is that Vezeeta makes reservations with doctors in their clinics upon request. There is no invitation to hospitals or services that are revealed like the doctor’s call you choose or home visits by choosing the doctor, budget and payment method.You can try and see for yourself`,
    FAQ_QUESTION_9: 'What happen if the doctor accepts a visit and couldn’t do it. Any penalty?',
    FAQ_ANSWER_9: 'Inform the call center immediately, if this command is repeated without anexcuse, it is liable to stop operation.',
    FAQ_QUESTION_10: 'Will the patient be able to track the doctor location if I accept the visit similar to Uber and Careem?',
    FAQ_ANSWER_10: 'yes',
    FAQ_QUESTION_11: 'Will the foreigners pay the fees like the Egyptians?',
    FAQ_ANSWER_11: 'Currently yes, the fees are the same.',
    FAQ_QUESTION_12: 'Can the patient request any investigation at Home through the app or just examination?',
    FAQ_ANSWER_12: 'So far, there are x-rays, sonar, and Doppler, as available.',
    PASSWORD_CHANGED_FAILED: 'Failed to change the password',
    SIGN_UP_USER_CODE: 'Signup User Code',
    DONATION: 'Donations',
    DONATION_MONTHLY_BREAKDOWN: 'Donation Monthly breakdown'
  },
  [LanguagesKey.AR]: {
    '': '',
    LANDLINE_NUMBER: 'رقم الخط الارضي',
    POLICY_AGREEMENT_1: 'قرأت وأوافق على',
    POLICY_AGREEMENT_2: 'الشروط والاحكام',
    SCHEDULE_WRONG_ENTRY: 'خطأ حاول مره اخرى',
    PASSWORD_CHANGED_SUCCESSFULLY: 'تم تغيير كلمة المرور بنجاح',
    PASSWORD_LENGTH_ERROR: 'كلمة السر يجب ان لا تقل عن 8 حروف',
    EMAIL_NOT_EXIST: 'البريد الالكترونى غير موجود',
    EMAIL: 'البريد الالكترونى',
    CERTIFICATES: 'الشهادات',
    EMAIL_SENT_SUCCESSFULLY: 'من فضلك قم بفحص بريدك الالكترونى لتغيير كلمة السر',
    INVALID_EMAIL: 'البريد الالكترونى غير صالح',
    ARABIC_NAME: 'الاسم العربي',
    DONE: 'موافق',
    REGISTERATION_THANK_YOU_MESSAGE: 'شكرا للتسجيل لدى كشف. سوف نتصل بك لاحقا',
    HOME_VISIT_SCHEDULE: 'جدول الزياره المنزليه',
    ONLINE_CONSULTATION_SCHEDULE: 'جدول الاستشارات الاونلاين',
    CLINIC_SCHEDULE: 'جدول العيادة',
    KASHF: 'كشف',
    HOME_VISIT_FEES: 'أتعاب الزياره المنزليه',
    ONLINE_CONSULTATION_FEES: 'أتعاب الاستشاره الاونلاين',
    CLINIC_FEES: 'أتعاب العيادة',
    DESC_MAX_LENGTH_VALIDATION: 'الوصف يجب الا يزيد عن 500 حرف',
    PASSWORD_CHANGED_FAILED: 'حدث خطأ فى تغيير كلمة السر من فضلك حاول مره اخرى',
    FAQ_ANSWER_12: 'حتى الأن توجد الأشعات والسونار والدوبلر حسب المتاح',
    FAQ_QUESTION_12: 'يمكن للمريض طلب أي فحوصات أخرى في المنزل من خلال التطبيق أومجرد فحص الطبيب ؟',
    FAQ_ANSWER_11: 'حاليا نعم، الرسوم متساوية',
    FAQ_QUESTION_11: 'هل يدفع الأجانب رسوما مثل المصريين؟',
    FAQ_ANSWER_10: 'نعم',
    FAQ_QUESTION_10: 'هل سيكون المريض قادراعلى تتبع موقعي إذا قبلت زيارة مثل تطبيقات أوبر و كريم ؟',
    FAQ_ANSWER_9: 'يبلغ الكول سنتر فورا، لو تكرر منه هذا الأمر بدون عذر فهو عرضة لإيقاف التشغيل',
    FAQ_QUESTION_9: 'ماذا يحدث إذا قبل الطبيب زيارة ولم يتمكن من القيام بذلك. أي عقوبة؟',
    FAQ_ANSWER_8: `كشف يختلف عن فيزيتا ان فيزيتا بتحجز مع الاطباء في عياداتهم حسب الطلب ما لوش دعوة بالمستشفيات ولا بالخدمات اللي كشف بتعملها زي مكالمة الطبيب اللي تختاره أو الزيارات المنزلية باختيار الطبيب والميزانية وطريقة الدفع 
    وتقدر تجرب وتشوف بنفسك
    `,
    FAQ_QUESTION_8: 'ماهوالفرق بين كشف وبين فيزيتا؟',
    FAQ_ANSWER_7: `في حالة دفع المريض بالكاش فورا ، في حالة دفع المريض بالائتمان خلال أيام ، أقصاها أسبوع
    لو الطبيب مشترك من حساب مستشفى، يراجع المستشفى لذلك.`,
    FAQ_QUESTION_7: 'متى يحصل الطبيب على ماله من الزيارات والخدمات ؟',
    FAQ_ANSWER_6: 'شهريا ومعه لستة بأنصبة كل طبيب لديهم',
    FAQ_QUESTION_6: 'متى تحصل المستشفى على ما لها من الزيارات والخدمات؟ ',
    FAQ_ANSWER_5: 'لو أنت مريض من حقك فاتورة تطلبها من الطبيب في الزيارة المنزلية',
    FAQ_QUESTION_5: 'هل سأخذ فاتورة للتأمين ؟',
    FAQ_ANSWER_4: `لو أنت طبيب :
    5% من صافي أتعابك لو سجلت بطاقتك الضريبية 
    20% لو لم تسجل بطاقتك الضريبية على موقع كشف.
    `,
    FAQ_QUESTION_4: 'كم سيتم اقتطاع المال من الضرائب؟',
    FAQ_ANSWER_3: 'حاليا لا ولكن من حق الطبيب قبول أو عدم قبول الزيارة كذلك ',
    FAQ_QUESTION_3: 'هل يدفع المريض رسوما إضافية للزيارات المنزلية إذا كان المكان بعيد جدا عن الطبيب المختار؟',
    FAQ_ANSWER_2: `لو المريض طالب زيارة منزلية هو اللي بيختار
    لو طالب مكالمة الطبيب لازم بطاقة ائتمان
    `,
    FAQ_QUESTION_2: 'هل يدفع المريض نقدا أو ببطاقة ائتمان أوكليهما؟',
    FAQ_ANSWER_1: `حضرتك تحب تشترك كمريض ولا طبيب ولا مستشفى أو مركز طبي ؟ 

    مريض
    ممكن تدور على كشف 724 
    ممكن تلاقيهم أسهل على موقعنا www.kashf247.com 
    و لو في أي مشكلة احنا معاك أو ابعث  على واتس أب 01020020087
    
    طبيب
    الاشتراك فقط عن طريق موقعنا www.kashf247.com
    ثم اضغط على create new account
    املأ الفورمة
    لو خلصتها هتدوس submit
    الأدمن هيراجع الفورم وهتوصلك رسالة على تلفونك ببدء التفعيل
    و لو في أي مشكلة احنا معاك أو ابعث  على واتس أب 01020020087.
    مستشفى
    الاشتراك فقط عن طريق موقعنا www.kashf247.com
    ثم اضغط على create new account
    املأ الفورمة
    لو خلصتها هتدوس submit
    الأدمن هيراجع الفورم وهتوصلك رسالة على تلفونك ببدء التفعيل
    و لو في أي مشكلة احنا معاك أو ابعث  على واتس أب 01020020087.
    `,
    FAQ_QUESTION_1: 'أنا عايز أشترك في خدمات كشف 724 أعمل إيه ؟',
    FAQ_SUBTITLE: 'اهلا بك فى مركز الدعم. هنا يمكنك ان تجد الاسئله المتكرره',
    FAQS_TITLE: 'الاسئله المتكرره',
    TRANSACTION_ID: 'رقم التحويل',
    TRANSACTION_TYPE: 'نوع التحويل البنكى',
    BANK_NAME: 'أسم البنك',
    ENGLISH_NAME: 'الاسم الانجليزى',
    FROM: 'من',
    TO: 'الى',
    CERTIFICATES_AND_OTHER_DOCS: 'شهادات ووثائق اخري',
    SETTLEMENT: 'التسوية',
    MONTHLY_BREAKDOWN: 'الايراد الشهرى',
    ADD_DOCTOR_VERIFICATION_MESSAGE: 'سوف يتم إرسال ايميل للدكتور. شكرا لك.',
    DESCRIPTION: 'الوصف',
    SUBTOTAL: 'المجموع الجزئى',
    TERMS_AND_CONDITIONS_AGREEMENT: 'أوافق على الشروط والاحكام',
    KASHF_PERCENTAGE: 'نسبة كشف',
    PASSWORD_MUST_MATCH: 'كلمة السر غير مطابقه',
    HEALTH_INSURANCE_COMPANIES: 'شركات التأمين الصحي',
    DRAG_AND_DROP_FILES_HERE: 'اسحب الملفات وضعها هنا',
    DROP_FILES_HERE: 'ضع الملفات هنا',
    CITY: 'المدينه',
    DOCTOR_PHONE: 'رقم الطبيب',
    SUBMIT: 'إرسال',
    INVALID_PHONE: 'Ex. رقم الهاتف غير صحيح 1091021133',
    AREA: 'المنطقه',
    COMMERCIAL_RECORD: 'الرقم التجاري',
    TO_NEXT_LANGUAGE: 'اللغه التاليه',
    CONSULTATION_TYPE: 'النوع',
    COORDINATOR_PHONE: 'رقم الهاتف',
    SPECIALITIES: 'التخصصات',
    NATIONAL_ID_FRONT: 'الصوره الاماميه لبطاقة الرقم القومى',
    REQUEST_ID: 'رقم الطلب',
    BANK_ACCOUNT: 'حساب بنكى',
    LANGUAGES: 'اللغات',
    RECENTLY_ADDED: 'مضاف مؤخرا',
    RECENTLY_UPDATED: 'محدث مؤخرا',
    CASH: 'كاش',
    ONLINE_CONSULTATION_REVENUE: 'إيرادات الاستشارات الاونلاين',
    HOME_VISIT_REVENUE: 'إيرادات الزيارات المنزلية',
    CLINIC_REVENUE: 'إيرادات العيادات',
    SETTLE: 'تسوية',
    GROSS_REVENUE: 'إجمالي الإيرادات',
    NET_REVENUE: 'صافي الإيرادات',
    DUE_DATE: 'تاريخ الانتهاء',
    ABOUT_DOCTOR: 'عن الطبيب',
    TOTAL: 'المجموع الكلي',
    TAX_ID: 'البطاقه الضريبيه',
    RESET: 'إعادة تعيين',
    NATIONAL_ID_BACK: 'الصوره الخلفيه لبطاقة الرقم القومى',
    RECEIPT: 'الفاتوره',
    CHANGE_LANGUAGE: 'تغيير اللغه',
    REQUESTS_LIST: ':طلبات الاستشاره',
    CERTIFICATE_ID: 'الشهاده',
    STARTED_AT: 'بدأت فى',
    DR_ID: 'رقم الطبيب',
    NAME_VALIIDATION: 'غير صالح',
    MESSAGE: 'كيف تحتاج الى مساعدة؟',
    FINISHED_AT: 'انتهى فى',
    TODAYS_REQUESTS: 'طلبات اليوم',
    DR: 'الطبيب',
    CONFIRM_PASSWORD: 'تأكيد كلمة السر',
    LOCATION: 'الموقع',
    WEBSITE: 'الموقع الالكترونى',
    PATIENT_PHONE: 'رقم المريض',
    INVALID_WEBSITE: 'غير صحيح مثال: http://www.google.com',
    FILE_PICKER_FILED_LENGTH: 'قم بإدخال ملف واحد فقط',
    COORDINATOR_NAME: 'الاسم',
    GOVERNORATE: 'المحافظه',
    PASSWORD: 'كلمة المرور',
    GRADE: 'الدرجه العلميه',
    GENDER: 'النوع',
    FEEDBACK_MESSAGE: '...من فضلك قم بكتابة رد فعلك هنا',
    DOCTOR_NOTES: 'ملاحظات الطبيب',
    PATIENT_NOTES: 'ملاحظات المريض',
    SYNDICATE_ID: 'رقم النقابه',
    FEEDBACK: 'رد فعل',
    AGE: 'العمر',
    REQUEST_A_CALL: 'طلب مكالمه',
    REQUEST_CALL: 'طلب مكالمه',
    DOCTOR_NOT_FOUND: 'غير موجود',
    SEND_FEEDBACK: 'أرسال رد فعل',
    LAST_CHECKIN: 'أخر تسجيل دخول',
    ACCEPT_ONE_FILE: 'يقبل ملف واحد فقط',
    Male: 'ذكر',
    PASSWORD_VALIDATION: 'يجب ان تحتوى كلمة السر على حروف كبيره وصغيره وارقام',
    Female: 'أنثي',
    MOBILE_NUMBER: 'رقم الهاتف',
    HELP_CENTER: 'مركز المساعدة',
    IMAGE: 'الصوره',
    SPECIALITY: 'التخصص',
    PATIENT: 'المريض',
    DOCTOR: 'الدكتور',
    NEED_HELP: 'تحتاج الى مساعده؟',
    MESSAGE_RECEIVED_SUCCESSFULLY: 'تم استلام رسالتك بنجاح',
    CODE: 'الكود',
    DESC_LENGTH_VALIDATION: 'يجب الا يذيد عن 500 حرف',
    AVATAR: 'الصوره',
    NOTES: 'ملاحظات',
    NAME: 'الاسم',
    DELETE: 'حذف',
    STATUS: 'الحاله',
    VIEW: 'التفاصيل',
    ADD_NEW_DOCTOR: 'إضافة دكتور جديد',
    RECENTLY_VIEWED: 'شوهدت مؤخرا',
    TIME: 'الوقت',
    EDIT_PROFILE: 'تعيل الصفحة الشخصيه',
    ID: 'الرقم',
    FILTER: 'فلتر',
    FORGOT_PASSWORD: 'نسيت كلمة المرور؟',
    EDIT: 'تعديل',
    DATE: 'التاريخ',
    ACCEPTS_LETTERS_ONLY: 'يقبل حروف فقط',
    ACCEPTS_NUMBERS_ONLY: 'يقبل أرقام فقط',
    TYPE: 'النوع',
    EXPORT_DATA: 'أستخراج البيانات فى ملف خارجي',
    DELETE_DOCTOR_CONFIRMATION: 'هل أنت متأكد من حذف هذا الطبيب؟',
    Clinic: 'عياده',
    'Home Visit': 'زيارة منزليه',
    ADD: 'إضافة',
    'Online Consultation': 'أستشاره اونلاين',
    LOGIN: 'تسجيل الدخول',
    ONLINE_CONSULTATION: 'أستشارات اونلاين',
    CLINICS: 'عيادات',
    SIGNUP: 'إنشاء حساب جديد',
    HOME_VISITS: 'زيارات منزليه',
    ALL: 'الكل',
    REQUIRED_FIELD: 'هذا الحقل مطلوب',
    INVALID_FIELD: 'غير صالح',
    COUNTRY_CODE: 'كود البلد',
    WRONG_CREDENTIALS: 'خطأ فى البريد الالكترونى او كلمة المرور',
    HOME: 'الرئيسية',
    LOGOUT: 'تسجيل الخروج',
    SEARCH: 'البحث',
    ACTIVITY_LOGS: 'سجل الانشطه',
    SERVICES: 'الخدمات',
    DOCTORS: 'الاطباء',
    PATIENTS: 'المرضى',
    PAYMENT: 'الدفع',
    PROFILE: 'الصفحه الشخصيه',
    SIGN_UP_USER_CODE: 'كود التسجيل',
    DONATION: 'التبرعات',
    DONATION_MONTHLY_BREAKDOWN: 'الايراد الشهري من التبرعات',
  },
};
