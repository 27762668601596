/* eslint-disable max-classes-per-file */
import React,
{
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  FilterTypes,
  TableColumnTypes,
} from 'src/components/GeneralTableView/types';
import {
  useStores,
} from 'hooks';
import {
  useHistory,
} from 'react-router';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import AddIcon from '@material-ui/icons/Add';
import i18n from 'i18n-js';
import {
  Assets,
} from 'assets';
import SearchFilterDropdown from 'src/components/SearchFilterComponent';
import {
  baseScreen,
} from 'hoc';
import GeneralTableView from '../../components/GeneralTableView';
import {
  Doctor,
  ListFilter,
  DoctorsTable,
} from './types';

const doctorsScreen: React.FC = () => {
  const {
    backend: {
      doctors,
    },
  } = useStores();
  const history = useHistory();
  const ref = useRef<any|null>(null);
  const [
    currentFilter,
    setCurrentFilter,
  ] = useState((history.location.state as any)?.filter);
  const onFilterChange = (val: string) => {
    const {
      filter,
    } = doctors;
    setCurrentFilter(val as ListFilter);
    if (val !== ListFilter.RECENTLY_VIEWED && val !== ListFilter.ALL) {
      doctors.updateFilter({
        ...filter,
        sort: {
          by: val,
          type: 'ASC',
        },
      });
    }
    if (val === ListFilter.ALL) {
      doctors.updateFilter({});
    }
    ref.current?.onQueryChange();
  };
  useEffect(() => {
    const {
      state,
    } = history.location as any;
    if (state?.filter) {
      onFilterChange(state.filter);
    }
  }, []);
  return (
    <div>
      <SearchFilterDropdown
        label="Filter"
        style={{
          margin: 16,
        }}
        searchFilterDropdown={{
          filters: [
            {
              name: i18n.t('ALL'),
              value: ListFilter.ALL,
            },
            {
              name: i18n.t('RECENTLY_VIEWED'),
              value: ListFilter.RECENTLY_VIEWED,
            },
            {
              name: i18n.t('RECENTLY_UPDATED'),
              value: ListFilter.UPDATED_AT,
            }, {
              name: i18n.t('RECENTLY_ADDED'),
              value: ListFilter.CREATED_AT,
            },
          ],
          value: currentFilter || 'all',
          initialValue: currentFilter || 'all',
          onChange: onFilterChange,
        }}
      />
      <GeneralTableView<DoctorsTable>
        tableProps={{
          tableRef: ref,
          title: i18n.t('DOCTORS'),
          options: {
            actionsColumnIndex: 10,
          },
          actions: [
            {
              icon: () => (
                <img
                  src={Assets.images.common.trash}
                  alt={i18n.t('DELETE')}
                />
              ),
              onClick: async (event, data) => {
                if (!Array.isArray(data)) {
                  const response = window.confirm(i18n.t('DELETE_DOCTOR_CONFIRMATION'));
                  if (response) {
                    await doctors.delete(data.id);
                    if (ref && ref.current) {
                      ref.current.onQueryChange();
                    }
                  }
                }
              },
            },
            {
              icon: () => (
                <img
                  src={Assets.images.common.edit}
                  alt={i18n.t('EDIT')}
                />
              ),
              onClick: async (event, data) => {
                const dr: any = data;
                history.push(`doctors/doctor-form/edit/${dr.id}`);
              },
            },
            {
              icon: () => (
                <img
                  src={Assets.images.common.eye}
                  alt={i18n.t('VIEW')}
                />
              ),
              onClick: async (event, data) => {
                const dr: any = data;
                history.push(`doctors/doctor-form/view/${dr.id as any}`);
              },
            },
            {
              isFreeAction: true,
              icon: ImportExportIcon,
              onClick: () => {
                window.open(doctors.download(), '_blanck');
              },
              tooltip: i18n.t('EXPORT_DATA'),
            },
            {
              isFreeAction: true,
              icon: AddIcon,
              onClick: () => {
                history.push('doctors/doctor-form/new/id');
              },
              tooltip: i18n.t('ADD_NEW_DOCTOR'),
            },
          ],
        }}
        options={{
          search: true,
          pageSize: 10,
        }}
        get={async (query) => {
          if (currentFilter === ListFilter.RECENTLY_VIEWED) {
            const data = doctors._recentlyViewed || [];
            return ({
              data,
              totalCount: 20,
              page: 1,
            });
          }
          await doctors.entityList.updateOptions(query);
          return {
            data: doctors.entityList.data.map((item) => Doctor.fromDTO(item)),
            totalCount: doctors.entityList.meta.itemCount,
            page: doctors.entityList.meta.page - 1,
          };
        }}
        tableColumns={[
          {
            field: 'code',
            title: i18n.t('CODE'),
            type: TableColumnTypes.NUMERIC,
            filterType: FilterTypes.VALUE,
          },
          {
            field: 'avatar',
            title: i18n.t('AVATAR'),
            type: TableColumnTypes.STRING,
            columnProps: {
              render: (data) => (
                <img
                  src={data.avatar}
                  style={{
                    width: 40,
                  }}
                  alt={data.name}
                />
              ),
            },
          },
          {
            field: 'name',
            title: i18n.t('NAME'),
            type: TableColumnTypes.STRING,
            filterType: FilterTypes.VALUE,
          },
          {
            field: 'syndicateNumber',
            title: i18n.t('SYNDICATE_ID'),
            type: TableColumnTypes.STRING,
          },
          {
            field: 'specialty',
            title: i18n.t('SPECIALITY'),
            type: TableColumnTypes.STRING,
          },
          {
            field: 'grade',
            title: i18n.t('GRADE'),
            type: TableColumnTypes.STRING,
          },
          {
            field: 'phone',
            title: i18n.t('MOBILE_NUMBER'),
            type: TableColumnTypes.STRING,
            filterType: FilterTypes.VALUE,
          },
          {
            field: 'status',
            title: i18n.t('STATUS'),
            type: TableColumnTypes.STRING,
            filterType: FilterTypes.VALUE,
          },
          {
            field: 'lastCheckIn',
            title: i18n.t('LAST_CHECKIN'),
            type: TableColumnTypes.STRING,
            filterType: FilterTypes.VALUE,
          },
        ]}
      />
    </div>
  );
};

export const Doctors = baseScreen(doctorsScreen, {
  allowedRoles: ['NONE', 'ADMIN'],
});
