import {
  baseScreen,
} from 'hoc';
import {
  useStores,
} from 'hooks';
import React,
{
  useState,
} from 'react';
import {
  useHistory,
  useParams,
} from 'react-router';
import GeneralFormView from 'src/components/GeneralFormView';
import * as Yup from 'yup';
import {
  FormFieldsBuilder,
} from 'src/components/GeneralFormView/Utils/FormFieldsBuilder';
import GeneralFormFields from 'src/components/GeneralFormView/types';
import {
  isRemoteFile,
} from 'src/components/GeneralFormView/FormField/types';
import EditIcon from '@material-ui/icons/Edit';
import {
  uploadFile,
} from 'utils';
import {
  IconButton,
} from '@material-ui/core';
import i18n from 'i18n-js';
import {
  ProfileFormViewModel,
} from './types';

const profileScreen: React.FC = () => {
  const {
    mode: formMode,
  } = useParams() as any;
  const history = useHistory();
  const {
    backend: {
      auth,
      users,
    },
  } = useStores();
  const [
    mode,
    setMode,
  ] = useState(formMode || 'view');
  const form = new FormFieldsBuilder<ProfileFormViewModel>()
    .addField('avatar', {
      title: i18n.t('IMAGE'),
      type: GeneralFormFields.FILE_PICKER,
      validationSchema: Yup.array().max(1) as any,
      fieldOptions: {
        size: 200,
        accept: 'image/*',
        multipleImages: false,
        disabled: true,
      },
    })
    .addField('name', {
      type: GeneralFormFields.INPUT_FIELD,
      title: i18n.t('NAME'),
      fieldOptions: {
        disabled: true,
      },
    })
    .addField('type', {
      type: GeneralFormFields.INPUT_FIELD,
      title: i18n.t('TYPE'),
      fieldOptions: {
        disabled: true,
      },
    })
    .addField('email', {
      type: GeneralFormFields.INPUT_FIELD,
      title: i18n.t('EMAIL'),
      fieldOptions: {
        disabled: true,
      },
    })
    .addField('phone', {
      type: GeneralFormFields.INPUT_FIELD,
      title: i18n.t('MOBILE_NUMBER'),
      fieldOptions: {
        disabled: true,
      },
    })
    .addField('landlineNumber', {
      type: GeneralFormFields.INPUT_FIELD,
      title: i18n.t('LANDLINE_NUMBER'),
      fieldOptions: {
        disabled: true,
      },
    })
    .addField('website', {
      type: GeneralFormFields.INPUT_FIELD,
      title: i18n.t('WEBSITE'),
      validationSchema: Yup.string().url(i18n.t('INVALID_WEBSITE')).notRequired().nullable() as any,
      fieldOptions: {},
    })
    .addField('location', {
      fieldOptions: {
        disabled: true,
      },
      title: i18n.t('LOCATION'),
      type: GeneralFormFields.LOCATION_FIELD,
    })
    .addField('taxId', {
      title: i18n.t('TAX_ID'),
      type: GeneralFormFields.FILE_PICKER,
      validationSchema: Yup.array().max(1) as any,
      fieldOptions: {
        size: 200,
        accept: 'image/*',
        disabled: true,
        multipleImages: false,
        disableRemoving:true,
      },
    })
    .addField('commercialRecord', {
      title: i18n.t('COMMERCIAL_RECORD'),
      type: GeneralFormFields.FILE_PICKER,
      validationSchema: Yup.array().max(1) as any,
      fieldOptions: {
        size: 200,
        disabled: true,
        accept: 'image/*',
        multipleImages: false,
        disableRemoving:true,
      },
    })
    .addField('certificate', {
      title: i18n.t('CERTIFICATES'),
      type: GeneralFormFields.FILE_PICKER,
      fieldOptions: {
        size: 200,
        accept: 'image/*',
        disabled: false,
        multipleImages: true,
        disableRemoving:true,
      },
    })
    .addField('healthInsuranceCompanies', {
      title: i18n.t('HEALTH_INSURANCE_COMPANIES'),
      type: GeneralFormFields.FILE_PICKER,
      fieldOptions: {
        size: 200,
        accept: 'image/*',
        multipleImages: false,
        disabled: true,
      },
    })
    .addField('signupCode', {
      type: GeneralFormFields.INPUT_FIELD,
      title: i18n.t('SIGN_UP_USER_CODE'),
      validationSchema: Yup.string().notRequired().default(null).nullable() as any,
      fieldOptions: {
        disabled: mode !== 'edit',
      },
    });

  if (mode !== 'view') {
    form.addField('password', {
      title: i18n.t('PASSWORD'),
      type: GeneralFormFields.INPUT_FIELD,
      validationSchema: Yup.string().notRequired() as any,
      fieldOptions: {
        type: 'password',
        disabled: mode !== 'edit',
      },
    })
      .addField('confirmPassword', {
        title: i18n.t('CONFIRM_PASSWORD'),
        type: GeneralFormFields.INPUT_FIELD,
        validationSchema: Yup.string().oneOf([Yup.ref('password')], i18n.t('PASSWORD_MUST_MATCH'))
          .notRequired() as any,
        fieldOptions: {
          type: 'password',
          disabled: mode !== 'edit',
        },
      });
  }

  return (
    <div>
      <IconButton
        size="medium"
        color="primary"
        onClick={() => {
          setMode('edit');
        }}
      >
        <EditIcon />
      </IconButton>
      <GeneralFormView<any, ProfileFormViewModel>
        defaultLang=""
        title={i18n.t('PROFILE')}
        identifier="id"
        otherLanguages={[]}
        viewMode={mode === 'view'}
        formData={form.formFields}
        create={async () => ({} as any)}
        update={async (id, data) => {
          let commercialRecord: string;
          if (!isRemoteFile(data.commercialRecord[0])) {
            commercialRecord = await uploadFile({
              file: data.commercialRecord[0].file, filePath: `commercial-record-${Date.now()}`,
            });
          } else {
            commercialRecord = auth.currentUser.serviceProvider.commercialRecord;
          }
          const docs = await Promise.all(data.certificate.map(async (doc) => {
            let docUrl = '';
            if (isRemoteFile(doc)) {
              docUrl = doc.source;
            } else {
              docUrl = await uploadFile({
                file: doc.file, filePath: `doc-${Date.now()}`,
              });
            }
            return docUrl;
          }));
          let signupCode:string=auth.currentUser.serviceProvider.signupCode;
          if(data.signupCode){
            signupCode=data.signupCode
          }
          const res = await users.updateUser({
            serviceProvider: {
              ...auth.currentUser.serviceProvider,
              signupCode,
              commercialRecord,
              docs,
              website: data.website,
            },
          });
          if (data.password) {
            await auth.changePassword(data.password);
          }
          setMode('view');
          history.push('/');
          return res;
        }}
        get={async () => new ProfileFormViewModel(auth.currentUser)}
        isFetchSuccessful
      />
    </div>
  );
};

export const Profile = baseScreen(profileScreen, {
  allowedRoles: ['ADMIN', 'NONE'],
});
