import {
  ServiceProviderType,
} from 'shared';
import {
  observable,
} from 'mobx';
import {
  ListBackendEntity,
} from 'utils';
import {
  BaseBackendStore,
} from '../types';
import {
  serviceProviderTypes,
} from './requests';
import {
  BackendStores,
} from '..';

export class ServiceProvidersStore extends BaseBackendStore {
  constructor(
    public parent: BackendStores,
  ) {
    super();
    this.makeObservable();
    this.registerObservableDrivers();
  }

  // eslint-disable-next-line max-len
  private getServiceProviderTypes = async (): Promise<ServiceProviderType[]> => this.connections.backend.httpGet(serviceProviderTypes);

  @observable private _serviceProviderTypes: ServiceProviderType[] = [];

  @observable public serviceProviderTypes = new ListBackendEntity<ServiceProviderType>(
    this,
    '_serviceProviderTypes',
    this.getServiceProviderTypes,
  );
}
