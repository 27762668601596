import {
  LanguagesKey,
} from 'src/translations/types';

export class Language {
  constructor(
    public key: LanguagesKey,
    public isRTL: boolean,
    public title?: string,
  ) { }
}
