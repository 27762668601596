import {
  LanguagesKey,
} from 'src/translations/types';
import {
  Language,
} from './types';

export const LANGUAGES = [
  new Language(
    LanguagesKey.EN,
    false,
    'English',
  ),
  new Language(
    LanguagesKey.AR,
    true,
    'العربية',
  ),
] as const;

export * from './types';
