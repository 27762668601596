import React from 'react';
import {
  Assets,
} from 'assets';
import {
  FieldFile,
} from '../../types';

const OtherPreview: React.FC<FieldFile> = () => (
  <img
    style={{
      height: 128,
      objectFit: 'contain',
    }}
    src={Assets.images.common.placeholder}
    alt="file preview"
  />
);
export default OtherPreview;
