import React from 'react';
import {
  Query,
  QueryResult,
  Column,
  MaterialTableProps,
  Options,
} from 'material-table';
import {
  TableFilterProps,
} from './Filters/types';
import DateRangeFilter from './Filters/DateRangeFilter';
import DateFilter from './Filters/DateFilter';
import NumberRangeFilter from './Filters/NumberRangeFilter';
import LocationRadiusFilter from './Filters/LocationRadiusFilter';

// eslint-disable-next-line no-shadow
export enum GeneralTableFields {
  TEXT_FIELD = 'textField',
  FILE_FIELD = 'fileField',
  ACTION_FIELD = 'actionField',
}

export interface Props<
  TableModel extends Record<string, unknown>,
  > {
  get: (query: Query<TableModel>) => Promise<QueryResult<TableModel>>;
  tableColumns: TableColumn<TableModel, TableColumnTypes>[];
  tableProps?: Partial<MaterialTableProps<TableModel>>;
  options?: Options<TableModel>;
  filtering?: boolean;
}

export interface RouteParams {
  id: string;
}

// eslint-disable-next-line no-shadow
export enum TableColumnTypes {
  STRING = 'string',
  BOOLEAN = 'boolean',
  NUMERIC = 'numeric',
  DATE = 'date',
  DATETIME = 'datetime',
  TIME = 'time',
  LOCATION = 'location',
}

export const TABLE_TO_MATERIAL_TABLE_TYPE = {
  [TableColumnTypes.STRING]: 'string',
  [TableColumnTypes.BOOLEAN]: 'boolean',
  [TableColumnTypes.NUMERIC]: 'numeric',
  [TableColumnTypes.DATE]: 'date',
  [TableColumnTypes.DATETIME]: 'datetime',
  [TableColumnTypes.TIME]: 'time',
  [TableColumnTypes.LOCATION]: 'string',
} as const;

// eslint-disable-next-line no-shadow
export enum FilterTypes {
  VALUE,
  VALUE_RANGE,
}

export type FilterTypesOf<T extends TableColumnTypes> = T extends TableColumnTypes.STRING
  ? FilterTypes.VALUE
  : T extends TableColumnTypes.BOOLEAN
    ? FilterTypes.VALUE
    : T extends TableColumnTypes.NUMERIC
      ? FilterTypes
      : T extends TableColumnTypes.DATE
        ? FilterTypes
        : T extends TableColumnTypes.TIME
          ? FilterTypes
          : T extends TableColumnTypes.DATETIME
            ? FilterTypes
            : T extends TableColumnTypes.LOCATION
              ? FilterTypes.VALUE_RANGE
              : unknown;

export interface TableColumn<
  T extends Record<string, unknown>,
  K extends TableColumnTypes,
  > {
  field: keyof T;
  type: K,
  filterType?: FilterTypesOf<K>;
  title: string;
  filterProps?: any;
  columnProps?: Column<T>;
}

export const FILTER_COMPONENTS: {
  [K in TableColumnTypes]: {
    [T in FilterTypesOf<K>]: React.FC<TableFilterProps<Record<string, unknown>, any>> | undefined;
  };
} = {
  [TableColumnTypes.BOOLEAN]: {
    [FilterTypes.VALUE]: undefined,
  },
  [TableColumnTypes.DATE]: {
    [FilterTypes.VALUE]: DateFilter,
    [FilterTypes.VALUE_RANGE]: DateRangeFilter,
  },
  [TableColumnTypes.DATETIME]: {
    [FilterTypes.VALUE]: DateFilter,
    [FilterTypes.VALUE_RANGE]: DateRangeFilter,
  },
  [TableColumnTypes.NUMERIC]: {
    [FilterTypes.VALUE]: undefined,
    [FilterTypes.VALUE_RANGE]: NumberRangeFilter,
  },
  [TableColumnTypes.STRING]: {
    [FilterTypes.VALUE]: undefined,
  },
  [TableColumnTypes.LOCATION]: {
    [FilterTypes.VALUE_RANGE]: LocationRadiusFilter,
  },
  [TableColumnTypes.TIME]: {
    [FilterTypes.VALUE]: DateFilter,
    [FilterTypes.VALUE_RANGE]: DateRangeFilter,
  },
};
