import React from 'react';
import i18n from 'i18n-js';
import {
  Typography,
} from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import {
  Theme,
  createStyles,
  makeStyles,
} from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

const FAQs = () => {
  const classes = useStyles();

  const ACCORDION_SECTIONS_HEADERS = [
    i18n.t('FAQ_QUESTION_1'),
    i18n.t('FAQ_QUESTION_2'),
    i18n.t('FAQ_QUESTION_3'),
    i18n.t('FAQ_QUESTION_4'),
    i18n.t('FAQ_QUESTION_5'),
    i18n.t('FAQ_QUESTION_6'),
    i18n.t('FAQ_QUESTION_7'),
    i18n.t('FAQ_QUESTION_8'),
    i18n.t('FAQ_QUESTION_9'),
    i18n.t('FAQ_QUESTION_10'),
    i18n.t('FAQ_QUESTION_11'),
    i18n.t('FAQ_QUESTION_12'),
  ];

  const ACCORDION_SECTIONS_CONTENTS = [
    i18n.t('FAQ_ANSWER_1'),
    i18n.t('FAQ_ANSWER_2'),
    i18n.t('FAQ_ANSWER_3'),
    i18n.t('FAQ_ANSWER_4'),
    i18n.t('FAQ_ANSWER_5'),
    i18n.t('FAQ_ANSWER_6'),
    i18n.t('FAQ_ANSWER_7'),
    i18n.t('FAQ_ANSWER_8'),
    i18n.t('FAQ_ANSWER_9'),
    i18n.t('FAQ_ANSWER_10'),
    i18n.t('FAQ_ANSWER_11'),
    i18n.t('FAQ_ANSWER_12'),
  ];

  const content = ACCORDION_SECTIONS_HEADERS.map((item: string, index: number) => (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography className={classes.heading}>{item}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography>
          {ACCORDION_SECTIONS_CONTENTS[index]}
        </Typography>
      </AccordionDetails>
    </Accordion>
  ));

  return (
    <div>
      {content}
    </div>
  );
};

export default FAQs;
