import {
  FieldFile,
  FieldFileCommonTypes,
  isRemoteFile,
} from 'src/components/GeneralFormView/FormField/types';
import {
  CreateServiceProviderDTO,
} from 'shared';
import {
  uploadFile,
  CountryCodes,
} from 'utils';
import {
  parsePhoneNumber,
  parsePhoneNumberFromString,
} from 'libphonenumber-js';

import {
  AvailableLanguages,
  MapLocation,
} from '../../../components/GeneralFormView/types';
import {
  BaseFormViewModel,
} from '../../types';

export class RegisterSPFormViewModel extends BaseFormViewModel<CreateServiceProviderDTO> {
  id?: number;

  avatar?: FieldFile[];

  description?: string;

  serviceProviderTypeId: number[];

  nameEn: string;

  nameAr?: string;

  countryCode: string[];

  phone: string;

  landlinePhone: string;

  email: string;

  website?: string;

  location?: MapLocation;

  governorateId?: number[];

  cityId?: number[];

  area?: string;

  password: string;

  confirmPassword: string;

  coordinatorName?: string;

  coordinatorPhone?: string;

  commercialRecord: FieldFile[];

  taxId: FieldFile[];

  docs?: FieldFile[];

  agreeToTermsAndPolicy: boolean;

  constructor(dto: CreateServiceProviderDTO) {
    super();
    const {
      avatar,
      serviceProviderTypeId,
      translations,
      phone,
      landlinePhone,
      email,
      website,
      location,
      governorateId,
      cityId,
      area,
      coordinatorName,
      coordinatorPhone,
      commercialRecord,
      taxId,
      docs,
    } = dto;

    const avatars: FieldFile[] = (avatar ? [avatar] : []).map((item) => ({
      source: item,
      type: FieldFileCommonTypes.IMAGE,
    }));

    const english = translations.find((t: any) => t.languageCode === AvailableLanguages.ENGLISH);
    const arabic = translations.find((t: any) => t.languageCode === AvailableLanguages.ARABIC);

    const {
      name: nameEn,
      description: descriptionEn,
    } = english || {};
    const {
      name: nameAr,
      description: descriptionAr,
    } = arabic || {};

    const description = descriptionEn || descriptionAr;

    let mobNationalNum;
    let countryCode;
    if (phone) {
      try {
        const mobileNumber = parsePhoneNumber(phone); // +201273604089
        const {
          countryCallingCode,
          nationalNumber,
        } = mobileNumber;
        mobNationalNum = nationalNumber;

        countryCode = CountryCodes.find(
          (country) => country.dial_code?.replace(/\s+/g, '') === `+${countryCallingCode}`,
        )?.code;
      } catch (error) {
        console.log('RegisterSPFormViewModel', 'CONSTRUCTOR', 'phone not valid', error);
      }
    }

    const commercialRecords: FieldFile[] = (commercialRecord ? [commercialRecord] : []).map((item) => ({
      source: item,
      type: FieldFileCommonTypes.IMAGE,
    }));

    const taxIds: FieldFile[] = (taxId ? [taxId] : []).map((item) => ({
      source: item,
      type: FieldFileCommonTypes.IMAGE,
    }));

    const docsArray: FieldFile[] = (docs && docs.length ? [...docs] : []).map((item) => ({
      source: item,
      type: FieldFileCommonTypes.IMAGE,
    }));

    const agreeToTermsAndPolicy = false;

    Object.assign(this, {
      description,
      nameEn,
      nameAr,
      avatar: avatars,
      serviceProviderTypeId: [serviceProviderTypeId],
      translations,
      countryCode: [countryCode],
      phone: mobNationalNum,
      landlinePhone,
      email,
      website,
      location,
      governorateId: [governorateId],
      cityId: [cityId],
      area,
      coordinatorName,
      coordinatorPhone,
      commercialRecord: commercialRecords,
      taxId: taxIds,
      docs: docsArray,
      agreeToTermsAndPolicy,
    });
  }

  public async toDTO() {
    const phone = parsePhoneNumberFromString(this.phone, this.countryCode[0] as any);
    const landlinePhone = parsePhoneNumberFromString(this.landlinePhone, this.countryCode[0] as any);

    // get avatar url
    let avatar = '';
    if (this.avatar && this.avatar.length) {
      const currentAvatar = this.avatar[0];
      if (isRemoteFile(currentAvatar)) {
        avatar = currentAvatar.source;
      } else {
        avatar = await uploadFile({
          file: currentAvatar.file, filePath: `service-providers/avatar-${Date.now()}`,
        });
      }
    }

    // get commercial record url
    let commercialRecord = '';
    const currentCommercialRecord = this.commercialRecord[0];
    if (isRemoteFile(currentCommercialRecord)) {
      commercialRecord = currentCommercialRecord.source;
    } else {
      commercialRecord = await uploadFile({
        file: currentCommercialRecord.file, filePath: `service-providers/commercial-record-${Date.now()}`,
      });
    }

    // get tax id url
    let taxId = '';
    const currentTaxId = this.taxId[0];
    if (isRemoteFile(currentTaxId)) {
      taxId = currentTaxId.source;
    } else {
      taxId = await uploadFile({
        file: currentTaxId.file, filePath: `service-providers/tax-id-${Date.now()}`,
      });
    }

    // get docs & certificates urls
    let docs: string[] = [];
    if (this.docs && this.docs.length) {
      docs = await Promise.all(this.docs.map(async (doc) => {
        let docUrl = '';
        if (isRemoteFile(doc)) {
          docUrl = doc.source;
        } else {
          docUrl = await uploadFile({
            file: doc.file, filePath: `service-providers/doc-${Date.now()}`,
          });
        }
        return docUrl;
      }));
    }

    return {
      avatar,
      serviceProviderTypeId: this.serviceProviderTypeId[0],
      password: this.password,
      translations: [
        {
          name: this.nameAr,
          description: this.description,
          languageCode: 'ar',
        },
        {
          name: this.nameEn,
          description: this.description,
          languageCode: 'en',
        },
      ],
      phone: phone?.number,
      landlinePhone: landlinePhone?.number,
      email: this.email,
      website: this.website,
      location: this.location,
      governorateId: this.governorateId && this.governorateId.length
        ? this.governorateId[0] : null,
      cityId: this.cityId && this.cityId.length
        ? this.cityId[0] : null,
      area: this.area,
      coordinatorName: this.coordinatorName,
      coordinatorPhone: this.coordinatorPhone,
      commercialRecord,
      taxId,
      docs,
      agreeToTermsAndPolicy: this.agreeToTermsAndPolicy,
    } as CreateServiceProviderDTO;
  }
}
