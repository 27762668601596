import {
  createStyles,
  makeStyles,
} from '@material-ui/core';

export const useStyles = makeStyles(() => createStyles({
  container: {
    width: '100%',
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'center',
  },
  content: {
    alignSelf: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    borderColor: '#000',
    borderWidth: 1,
    borderStyle: 'solid',
    height: 350,
    padding: 16,
  },
  img: {
    height: 180,
    width: 180,
    marginBottom: 40,
    display: 'block',
    alignSelf: 'center',
  },
  text: {
    textAlign: 'center',
  },
  btn: {
    marginTop: 20,
    backgroundColor: '#EF7D00',
    width: 160,
    alignSelf: 'center',
  },
}));
