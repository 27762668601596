/* eslint-disable max-len */
import {
  action,
  observable,
} from 'mobx';
import {
  BackendEntity,
  ListBackendEntity,
  ModelOf,
} from 'utils';
import {
  Settings,
} from 'settings';
import {
  BaseBackendStore,
} from '../types';
import {
  bills,
  download,
  payments,
} from './requests';
import {
  PaymentDTO,
} from './types';
import {
  BackendStores,
} from '..';
import {
  SearchObj,
} from '../DoctorsStore/types';

export class PaymentStore extends BaseBackendStore {
  @observable private _entityList: PaymentDTO[] = [];

  @observable private _paymentsList: PaymentDTO[] = [];

  @observable filter: SearchObj = {
    filters: [],
  };

  @action public getAll = async (options: any): Promise<PaymentDTO[]> => {
    this.filter.filters = options.filters;
    this.filter.search = {
      by: 'any',
      value: options.search,
    };
    if (options.orderBy) {
      this.filter.sort = {
        by: options.orderBy.field,
        type: options.orderDirection.toUpperCase(),
      };
    }
    const response = await this.connections.backend.httpGet(`${bills}?take=${options.pageSize || 10}&page=${options.page + 1}&query=${JSON.stringify(this.filter)}`);
    return response;
  };

  @observable entityList = new ListBackendEntity(
    this,
    '_entityList',
    this.getAll,
  );

  @action public getPayments = async (options: any): Promise<PaymentDTO[]> => {
    this.filter.filters = options.filters;
    this.filter.search = {
      by: 'any',
      value: options.search,
    };
    if (options.orderBy) {
      this.filter.sort = {
        by: options.orderBy.field,
        type: options.orderDirection.toUpperCase(),
      };
    }
    const response = await this.connections.backend.httpGet(`${payments}?take=${options.pageSize || 10}&page=${options.page + 1}&query=${JSON.stringify(this.filter)}`);
    return response;
  };

  @observable paymentsList = new ListBackendEntity(
    this,
    '_paymentsList',
    this.getPayments,
  );

  constructor(
    public parent: BackendStores,
  ) {
    super();
    this.makeObservable();
    this.registerObservableDrivers();
  }

  @observable private _selectedEntity: PaymentDTO;

  @observable selectedEntity = new BackendEntity(
    this,
    '_selectedEntity',
    this.getOne,
  );

  @action
  public async create(data: PaymentDTO): Promise<void> {
    await this.connections.backend.httpPost(bills, data);
    return Promise.resolve();
  }

  @action
  public async getOne(id: string): Promise<ModelOf<PaymentDTO>> {
    console.log(id);
    return Promise.resolve({} as any);
  }

  @action updateFilter = (item: SearchObj) => {
    this.filter = {
      ...this.filter,
      ...item,
    };
  };

  public download() {
    return `${Settings.config.REACT_APP_BASE_URL + download}?query=${JSON.stringify(this.filter)}&lang=${this.parent.parent.ui.localization.currentLanguage.key}`;
  }
}
