import React, {
  useState,
} from 'react';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import i18n from 'i18n-js';
import {
  useLocalization,
} from 'hooks';
import {
  LanguagesKey,
} from 'src/translations/types';
import {
  useStyles,
} from './styles';

export const AuthFooter = () => {
  const classes = useStyles();
  const {
    currentLanguage,
    setLanguage,
  } = useLocalization();
  const [
    displayList,
    setDisplayList,
  ] = useState(false);
  return (
    <div
      className={classes.container}
    >
      <ul
        style={{
          display: 'flex',
        }}
      >
        <li
          className={classes.language}
        >
          {displayList && (
          <div
            className={classes.languages}
          >
            <div
              style={{
                borderBottomStyle: 'solid',
                borderBottomWidth: 1,
                borderBottomColor: '#999',
              }}
            >
              <div
                onClick={() => {
                  setLanguage({
                    isRTL: false,
                    key: LanguagesKey.EN,
                  });
                  setDisplayList(false);
                }}
                onKeyDown={() => {
                }}
                tabIndex={0}
                role="button"
                className={classes.languageItem}
              >
                English
              </div>
            </div>
            <div>
              <div
                onClick={() => {
                  setLanguage({
                    isRTL: true,
                    key: LanguagesKey.AR,
                  });
                  setDisplayList(false);
                }}
                onKeyDown={() => {
                }}
                className={classes.languageItem}
                tabIndex={0}
                role="button"
              >

                العربية
              </div>
            </div>
          </div>
          )}
          <div
            onClick={() => {
              setDisplayList(!displayList);
            }}
            onKeyDown={() => {
            }}
            tabIndex={0}
            role="button"
            style={{
              cursor: 'pointer',
            }}
          >
            <span>
              {currentLanguage.key === LanguagesKey.AR ? 'العربية' : 'English'}
            </span>
            {' '}
            <ArrowDropDownIcon className={classes.icon} />
          </div>
        </li>
        <li
          style={{
            marginRight: 50,
            marginLeft: 25,
          }}
          className={classes.item}
        >
          <a
            href="/help-center"
          >
            {i18n.t('NEED_HELP')}
          </a>
        </li>
      </ul>
    </div>
  );
};
