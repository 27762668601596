import {
  Connections,
} from 'connections';
import {
  Stores,
} from '..';
import {
  UserStore,
} from './users';
import {
  AuthStore,
} from './auth';
import {
  StaticTextStore,
} from './staticTexts';
import {
  HelpCenterStore,
} from './HelpCenterStore';
import {
  ServiceProvidersStore,
} from './serviceProviders';
import {
  GeoStore,
} from './geo';
import {
  RequestsStore,
} from './RequestsStore';
import {
  DoctorsStore,
} from './DoctorsStore';
import {
  PatientsStore,
} from './PatientsStore';
import {
  PaymentStore,
} from './PaymentStore';
import {
  BillsStore,
} from './BillsStore';
import { DonationStore } from './DonationStore';
import { DonationBillsStore } from './DonationBillStore';

export class BackendStores {
  connections: Connections;

  users = new UserStore(this);

  auth = new AuthStore(this);

  doctors = new DoctorsStore(this);

  patients = new PatientsStore(this);

  paymentStore = new PaymentStore(this);

  donationStore = new DonationStore(this);

  billsStore = new BillsStore(this);

  donationBillsStore = new DonationBillsStore(this);

  staticTexts = new StaticTextStore(this);

  requests = new RequestsStore(this);

  serviceProviders = new ServiceProvidersStore(this);

  helpCenterStore = new HelpCenterStore(this);

  geo = new GeoStore(this);

  constructor(
    public parent: Stores,
  ) { }

  hydrate() {
    return Promise.all([
      this.auth.hydrate(),
      this.patients.hydrate(),
      this.doctors.hydrate(),
      this.users.hydrate(),
      this.staticTexts.hydrate(),
      this.serviceProviders.hydrate(),
      this.geo.hydrate(),
    ]);
  }

  updateConnections(connections: Connections) {
    this.connections = connections;
    this.users.connections = connections;
    this.paymentStore.connections = connections;
    this.donationStore.connections = connections;
    this.billsStore.connections = connections;
    this.donationBillsStore.connections = connections;
    this.helpCenterStore.connections = connections;
    this.patients.connections = connections;
    this.auth.connections = connections;
    this.requests.connections = connections;
    this.doctors.connections = connections;
    this.staticTexts.connections = connections;
    this.serviceProviders.connections = connections;
    this.geo.connections = connections;
  }
}
