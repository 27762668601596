import {
  BillsDTO,
  BillStatus,
} from 'src/stores/backend/BillsStore/types';
import {
  BaseFormViewModel,
} from '../../types';

export enum ListFilter {
  ALL = 'all',
  HOME_VISIT = 'Home Visit',
  ONLINE_CONSULTATION = 'Online Consultation',
  CLINICS = 'Clinic',
}

export class Bill extends BaseFormViewModel<BillsDTO> {
  id?: number;

  balance: number;

  date: Date;

  email: string;

  grossRevenue: number;

  clinicVisitRevenue: number;

  status: BillStatus;

  netRevenue: number;

  kashfPercentage: number;

  homeVisitRevenue: string;

  onlineConsulationRevenue: number;

  service_provider_type: string;

  constructor(data: BillsDTO) {
    super();
    this.id = data.id;
    this.balance = data.balance;
    this.date = data.end;
    this.email = data.email;
    this.grossRevenue = data.grossRevenue;
    this.clinicVisitRevenue = data.clinicVisitRevenue;
    this.onlineConsulationRevenue = data.onlineConsulationRevenue;
    this.status = data.status;
    this.kashfPercentage = data.kashfPercentage;
    this.netRevenue = data.netRevenue;
    this.homeVisitRevenue = data.homeVisitRevenue;
    this.service_provider_type = data.type;
  }

  toDTO(): BillsDTO {
    return { } as BillsDTO;
  }
}
