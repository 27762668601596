import {
  createStyles,
  makeStyles,
} from '@material-ui/core';

export const Styles = makeStyles(() => createStyles({
  images: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 10,
    marginBottom: 10,
    alignItems: 'center',
    position: 'relative',
  },
  bell: {
    height: 30,
    width: 30,
    marginRight: 10,
  },
  avatar: {
    height: 40,
    width: 40,
    borderRadius: 20,
  },
  avatarContainer: {
    flexDirection: 'row',
    display: 'flex',
    marginLeft: 16,
    marginRight: 16,
  },
  name: {
    lineHeight: 2.6,
    marginLeft: 10,
    marginRight: 10,
  },
  chevronBottom: {
    height: 25,
    alignSelf: 'center',
  },
  optionsContainer: {
    position: 'absolute',
    top: 40,
    width: 200,
    borderColor: '#DDD',
    borderStyle: 'solid',
    borderWidth: 1,
    listStyle: 'none',
    padding: 5,
    textAlign: 'center',
    zIndex: 11,
    background: '#fafafa',
  },
  link: {
    width: '100%',
    padding: 10,
  },
  li: {
    color: '#000',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#DDD',
    },
  },
}));
