/* eslint-disable import/extensions */
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from 'app';
import * as firebase from 'firebase/app';
import * as serviceWorker from './serviceWorker';

const initFirebase = async () => {
  firebase.initializeApp({
    apiKey: 'AIzaSyC-9i6eX6iLuY0GYjqQbEJonin7tZtVXho',
    authDomain: 'kashf-prod.firebaseapp.com',
    databaseURL: 'https://kashf-prod-default-rtdb.europe-west1.firebasedatabase.app',
    projectId: 'kashf-prod',
    storageBucket: 'kashf-prod.appspot.com',
    messagingSenderId: '564918949674',
    appId: '1:564918949674:web:b00f04e051039068c33053',
    measurementId: 'G-N04JQ0VD84',
  });
};

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
initFirebase();
