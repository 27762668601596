/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
} from '@material-ui/core';
import {
  getIn,
  useFormikContext,
} from 'formik';
import React from 'react';
import {
  isCustomFormPreview,
  isFormField,
} from '../../types';
import FieldError from '../../Utils/FieldError';
import FormFieldCondition from '../../Utils/FormFieldCondition';
import {
  FieldPropsCommon,
  NestedFormFieldValue,
} from '../types';

type Props<T, K extends keyof T> = FieldPropsCommon & NestedFormFieldValue<T, K>;

const NestedFormField = <FormModel, FormModelKey extends keyof FormModel>(props: Props<FormModel, FormModelKey>) => {
  const {
    values,
    errors,
    touched,
  } = useFormikContext<FormModel>();

  const {
    fieldOptions,
    viewMode,
    title,
    location,
  } = props;

  const error = getIn(errors, location);
  const isTouched = getIn(touched, location);

  return (
    <Card
      style={{
        margin: 8,
      }}
    >
      <CardHeader
        title={title}
      />
      <CardContent>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'stretch',
          }}
        >
          {
            fieldOptions.formData.map((formField) => {
              if (isCustomFormPreview<FormModel>(formField)) {
                const CustomComponent = formField.component as any;
                return (
                  <CustomComponent {...values} />
                );
              }
              if (isFormField<FormModel, FormModelKey>(formField)) {
                return (
                  <FormFieldCondition<FormModel, FormModelKey>
                    key={`${location}.${formField.key}`}
                    fieldData={formField as any}
                    location={`${location}.${formField.key}`}
                    viewMode={viewMode}
                  />
                );
              }
              return null;
            })
          }
        </div>
      </CardContent>
      <CardActions>
        <FieldError
          errors={error}
          touched={isTouched}
        />
      </CardActions>
    </Card>
  );
};

export default NestedFormField;
/* eslint-enable @typescript-eslint/no-explicit-any */
