import {
  action,
} from 'mobx';
import {
  identity,
  pickBy,
} from 'lodash';
import {
  BaseBackendStore,
} from '../types';
import {
  feedbacks,
  requestCall,
} from './requests';
import {
  BackendStores,
} from '..';
import {
  FeedbackDTO,
  RequestCallDTO,
} from './types';

export class HelpCenterStore extends BaseBackendStore {
  constructor(
    public parent: BackendStores,
  ) {
    super();
    this.makeObservable();
    this.registerObservableDrivers();
  }

  @action
  public async createCallRequest(data: RequestCallDTO): Promise<number> {
    try {
      await this.connections.backend.httpPost(requestCall, pickBy(data, identity));
      return 1;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  @action
  public async createFeedback(data: FeedbackDTO): Promise<number> {
    try {
      await this.connections.backend.httpPost(feedbacks, pickBy(data, identity));
      return 1;
    } catch (error) {
      return Promise.reject(error);
    }
  }
}
