import {
  Request,
} from 'src/stores/backend/RequestsStore/types';
import {
  BaseFormViewModel,
} from '../types';

export class RequestModelView extends BaseFormViewModel<Request> {
  id?: number;

  date: Date;

  doctorName: string;

  doctorNotes: string;

  finishedAt: Date;

  from: Date;

  patientName: string;

  patientNotes: string;

  status: string;

  type: string;

  constructor(dto: Request) {
    super();
    Object.assign(this, {
      id: dto.id,
      date: dto.date,
      doctorName: dto.doctor.user.name,
      doctorNotes: dto.doctorNotes,
      finishedAt: dto.finishedAt,
      from: dto.from,
      patientName: dto.patient.user.name,
      patientNotes: dto.patientNotes,
      status: dto.status,
      type: dto.type,
    });
  }

  async toDTO() {
    return {} as Request;
  }
}
