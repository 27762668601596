import {
  createStyles,
  makeStyles,
} from '@material-ui/core';

export const useStyles = makeStyles(() => createStyles({
  container: {
    height: 30,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'flex-end',
    paddingBottom: 20,
  },
  item: {
    display: 'block',
  },
  language: {
    marginRight: 50,
    display: 'block',
    position: 'relative',
    marginTop: -1,
  },
  icon: {
    marginBottom: -5,
  },
  languages: {
    position: 'absolute',
    top: -90,
    backgroundColor: '#FFF',
    right: 6,
    borderStyle: 'solid',
    borderWidth: 1,
    cursor: 'pointer',
    borderColor: '#999',
    borderRadius: 5,
  },
  languageItem: {
    paddingRight: 18,
    paddingLeft: 20,
    paddingTop: 10,
    paddingBottom: 10,
  },
}));
