import React, {
  useEffect,
  useState,
} from 'react';
import Spinner from '../../../../Spinner';
import {
  FieldFile,
  isRemoteFile,
} from '../../types';
import {
  getFilePreview,
} from '../utils';

const ImagePreview: React.FC<FieldFile> = (props) => {
  const [preview, setPreview] = useState<string>('');

  useEffect(() => {
    if (!isRemoteFile(props)) {
      const getPreview = async () => {
        const source = await getFilePreview(props.file);
        setPreview(source);
      };
      getPreview();
    }
  }, [
    props,
  ]);

  if (isRemoteFile(props)) {
    const {
      source,
    } = props;
    return (
      <img
        style={{
          height: 128,
          objectFit: 'contain',
        }}
        src={source}
        alt="preview"
      />
    );
  }
  if (preview) {
    return (
      <img
        style={{
          height: 128,
          objectFit: 'contain',
        }}
        src={preview}
        alt="preview"
      />
    );
  }
  return (
    <div
      style={{
        height: 128,
        objectFit: 'contain',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Spinner />
    </div>
  );
};

export default ImagePreview;
