import {
  City,
  Governorate,
} from 'shared';
import {
  observable,
} from 'mobx';
import {
  ListBackendEntity,
} from 'utils';
import {
  BaseBackendStore,
} from '../types';
import {
  cities,
  governorates,
} from './requests';
import {
  BackendStores,
} from '..';

export class GeoStore extends BaseBackendStore {
  constructor(
    public parent: BackendStores,
  ) {
    super();
    this.makeObservable();
    this.registerObservableDrivers();
  }

  private getGovernorates = async (): Promise<Governorate[]> => this.connections.backend.httpGet(governorates);

  @observable private _governoratesList: Governorate[] = [];

  @observable public governoratesList = new ListBackendEntity<Governorate>(
    this,
    '_governoratesList',
    this.getGovernorates,
  );

  private getCities = async (governorateId: string): Promise<City[]> => this.connections.backend.httpGet(cities, {
    params: {
      governorateId,
    },
  });

  @observable private _citiesList: City[] = [];

  @observable public citiesList = new ListBackendEntity<City>(
    this,
    '_citiesList',
    this.getCities,
  );
}
