/* eslint-disable max-classes-per-file */
import React,
{
  useRef,
  useEffect,
  useState,
} from 'react';
import {
  useStores,
} from 'hooks';
import {
  baseScreen,
} from 'hoc';
import {
  createStyles,
  makeStyles,
  Typography,
} from '@material-ui/core';
import GeneralFormView from 'src/components/GeneralFormView';
import {
  FormFieldsBuilder,
} from 'src/components/GeneralFormView/Utils/FormFieldsBuilder';
import GeneralFormFields from 'src/components/GeneralFormView/types';
import {
  SearchObj,
} from 'src/stores/backend/DoctorsStore/types';
import * as Yup from 'yup';
import {
  useHistory,
  useParams,
} from 'react-router';
import {
  ComponentWithLoading,
} from 'components';
import i18n from 'i18n-js';
import GeneralTableView from 'src/components/GeneralTableView';
import {
  RequestTable,
  Request,
} from 'src/stores/backend/RequestsStore/types';
import {
  toJS,
} from 'mobx';
import {
  FilterTypes,
  TableColumnTypes,
} from 'src/components/GeneralTableView/types';
import {
  Assets,
} from 'assets';
import moment,
{
  Moment,
} from 'moment';
import {
  Calendar,
  Event,
  momentLocalizer,
  View,
} from 'react-big-calendar';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import SearchFilterDropdown from 'src/components/SearchFilterComponent';
import {
  DoctorModelView,
  Schedule,
  ScheduleType,
  WorkingDay,
} from './types';
import {
  Doctor,
} from '../doctors/types';
import 'react-big-calendar/lib/css/react-big-calendar.css';

const localizer = momentLocalizer(moment);

const WEEK_DAY_MAP: any = {
  Sunday: 0,
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6,
};

const getAllWeekDaysWithinDuration = (startDate: Moment, endDate: Moment, weekDay: number) => {
  const dates: Moment[] = [];
  while (true) {
    const currentDate = dates.length
      ? dates[dates.length - 1].clone().add(1, 'week')
      : startDate.clone().add((weekDay - startDate.day() + 7) % 7, 'days');
    if (currentDate.isBetween(startDate, endDate, 'days', '[]')) {
      dates.push(currentDate.clone());
    } else {
      break;
    }
  }
  return dates;
};

const parseTime = (time: string) => time.split(':').slice(0, -1).map(Number);

const useStyles = makeStyles(() => createStyles({
  search: {
    height: 40,
    width: 200,
    borderWidth: 0,
    borderBottomWidth: 1,
    borderColor: '#000',
  },
  searchContainer: {
    marginTop: 8,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  doctorNotFound: {
    textAlign: 'center',
    alignSelf: 'center',
    marginTop: 200,
    fontSize: 45,
    fontWeight: 800,
    color: '#CCC',
  },
}));
const doctorDetails: React.FC = () => {
  const history = useHistory();
  const classes = useStyles();
  const {
    backend: {
      doctors,
      auth,
      requests: requestsStore,
    },
  } = useStores();
  const [
    searchValue,
    setSearchValue,
  ] = useState('');
  const [
    isLoading,
    setLoading,
  ] = useState(false);
  const [
    noDoctorFound,
    setNoDoctorFound,
  ] = useState(false);
  const [
    currentFilter,
    setCurrentFilter,
  ] = useState((history.location.state as any)?.filter);
  const [
    selectedDoctor,
    setSelectedDoctor,
  ] = useState<Doctor>();
  const {
    id,
    mode,
  } = useParams() as any;
  const [
    doctorId,
    setDoctorId,
  ] = useState<number>();

  const [startDate, setStartDate] = useState<Moment>(moment().startOf('month'));
  const [endDate, setEndDate] = useState<Moment>(moment().endOf('month'));
  const [view, setView] = useState<View>('month');
  const [date, setDate] = useState<Date>(new Date());
  const [events, setEvents] = useState<Event[]>([]);

  useEffect(() => {
    if (!!startDate && !!endDate && doctors.doctorSchedule.data) {
      const newEvents: Event[] = [];
      doctors.doctorSchedule.data.forEach((schedule) => {
        if (!schedule.isEnabled) {
          return;
        }
        schedule.workingDays.forEach((workingDay) => {
          if (!workingDay.isActive) {
            return;
          }
          const dayIndex: number = WEEK_DAY_MAP[workingDay.day];
          const rangeWeekDays = getAllWeekDaysWithinDuration(startDate, endDate, dayIndex);
          if (workingDay.containsBreak) {
            rangeWeekDays.forEach((rangeWeekDay) => {
              const [startHour, startMinute] = parseTime(workingDay.from);
              const [breakStartHour, breakStartMinute] = parseTime(workingDay.breakFrom);
              const [breakEndHour, breakEndMinute] = parseTime(workingDay.breakTo);
              const [endHour, endMinute] = parseTime(workingDay.to);
              newEvents.push({
                allDay: false,
                title: schedule.type,
                start: rangeWeekDay.clone().set('hour', startHour).set('minute', startMinute).toDate(),
                end: rangeWeekDay.clone().set('hour', breakStartHour).set('minute', breakStartMinute).toDate(),
                resource: schedule.type,
              });
              newEvents.push({
                allDay: false,
                title: schedule.type,
                start: rangeWeekDay.clone().set('hour', breakEndHour).set('minute', breakEndMinute).toDate(),
                end: rangeWeekDay.clone().set('hour', endHour).set('minute', endMinute).toDate(),
                resource: schedule.type,
              });
            });
          } else {
            rangeWeekDays.forEach((rangeWeekDay) => {
              const [startHour, startMinute] = parseTime(workingDay.from);
              const [endHour, endMinute] = parseTime(workingDay.to);
              newEvents.push({
                allDay: false,
                title: schedule.type,
                start: rangeWeekDay.clone().set('hour', startHour).set('minute', startMinute).toDate(),
                end: rangeWeekDay.clone().set('hour', endHour).set('minute', endMinute).toDate(),
                resource: schedule.type,
              });
            });
          }
        });
      });
      setEvents(newEvents);
    }
  }, [startDate, endDate, doctors.doctorSchedule.data]);
  const ref = useRef<any|null>(null);

  const onFilterChange = (val: string) => {
    const {
      filter,
    } = requestsStore;
    setCurrentFilter(val as ScheduleType);
    if (val !== ScheduleType.ALL) {
      requestsStore.updateFilter({
        ...filter,
        filters: [
          {
            by: 'consultation_type',
            type: 'FIXED',
            value: val,
          },
          {
            by: 'doctor',
            type: 'FIXED',
            value: (doctorId as number).toString(),
          },
        ],
      });
    }
    if (val === ScheduleType.ALL) {
      requestsStore.updateFilter({
        ...filter,
        filters: [
          {
            by: 'doctor',
            type: 'FIXED',
            value: (doctorId as number).toString(),
          },
        ],
      });
    }
    ref.current?.onQueryChange();
  };
  useEffect(() => {
    const {
      state,
    } = history.location as any;
    if (state?.filter) {
      onFilterChange(state.filter);
    }
    const getData = async () => {
      if (!Number.isNaN(Number(id))) {
        setLoading(true);
        setDoctorId(Number(id));
        const doctor = await doctors.getOne(id);
        await doctors.doctorSchedule.updateOptions(id);
        setSelectedDoctor(doctor);
        setNoDoctorFound(false);
        setLoading(false);
        // if (mode === 'view') {
        //   doctors.updateRecentlyViewed(doctor);
        // }
      }
    };
    getData();
  }, []);

  const toTableData = (data: Request): RequestTable => ({
    date: moment(data.date).format('YYYY-MM-DD'),
    status: data.status,
    time: data.from ? moment(data.from, 'HH:mm:ss').format('HH:mm') : '',
    doctor: data.doctor.user.name,
    doctorMobileNumber: data.doctor.user.phone,
    id: data.id,
    patient: data.patient.user.name,
    patientMobileNumber: data.patient.user.phone,
    consultation_type: data.type,
    doctorNotes: data.doctorNotes,
    startedAt: data.startedAt ? moment(data.startedAt).format('HH:mm') : '',
    finishedAt: data.finishedAt ? moment(data.finishedAt).format('HH:mm') : '',
  });

  const formatSchedule = (workingDays: WorkingDay[]) => workingDays.map((item, index) => {
    const day: WorkingDay = {
      ...item,
      containsBreak: !!item.breakFrom && !!item.breakTo,
      order: index,
    };
    if (!day.breakFrom) delete day.breakFrom;
    if (!day.breakTo) delete day.breakTo;
    return day;
  });
  const [viewModeEnabled] = useState(mode === 'view');

  return (
    <div>
      <form
        onSubmit={async (event) => {
          try {
            event.preventDefault();
            let doctor: any;
            setLoading(true);
            if (Number.isNaN(Number.parseInt(searchValue, 10))) {
              doctor = await doctors.searchDoctors({
                filters: [{
                  type: 'FIXED',
                  by: 'email',
                  value: searchValue,
                }],
              });
              const data: Doctor = doctor.data[0];
              setSelectedDoctor(data);
              setNoDoctorFound(!data);
            } else {
              setDoctorId(Number.parseInt(searchValue, 10));
              doctor = await doctors.getOne(searchValue);
              setSelectedDoctor(doctor);
              setNoDoctorFound(false);
            }
          } catch (error) {
            setNoDoctorFound(true);
          } finally {
            setLoading(false);
          }
        }}
        className={classes.searchContainer}
      >
        <input
          placeholder={i18n.t('SEARCH')}
          className={classes.search}
          onChange={(event) => setSearchValue(event.target.value)}
        />
      </form>
      <ComponentWithLoading
        isLoading={isLoading}
      >
        <div>
          {noDoctorFound && (
          <Typography
            className={classes.doctorNotFound}
          >
            {i18n.t('DOCTOR_NOT_FOUND')}
          </Typography>
          )}
          {(selectedDoctor && selectedDoctor.user && !noDoctorFound) && (
          <GeneralFormView<any, DoctorModelView>
            title={i18n.t('DOCTOR')}
            identifier="id"
            defaultLang="en"
            viewMode={viewModeEnabled}
            otherLanguages={[]}
            formData={new FormFieldsBuilder<DoctorModelView>()
              .addField('avatar', {
                title: i18n.t('IMAGE'),
                type: GeneralFormFields.FILE_PICKER,
                validationSchema: Yup.array().max(1) as any,
                fieldOptions: {
                  disabled: true,
                  size: 200,
                  accept: 'image/*',
                  multipleImages: false,
                },
              })
              .addField('name', {
                type: GeneralFormFields.INPUT_FIELD,
                title: i18n.t('NAME'),
                fieldOptions: {
                  disabled: true,
                },
              })
              .addField('syndicateNumber', {
                type: GeneralFormFields.INPUT_FIELD,
                validationSchema: Yup.string().notRequired().nullable() as any,
                title: i18n.t('SYNDICATE_ID'),
                fieldOptions: {
                  disabled: true,
                },
              })
              .addField('phone', {
                type: GeneralFormFields.INPUT_FIELD,
                validationSchema: Yup.string().notRequired() as any,
                title: i18n.t('MOBILE_NUMBER'),
                fieldOptions: {
                  disabled: true,
                },
              })
              .addField('email', {
                type: GeneralFormFields.INPUT_FIELD,
                title: i18n.t('EMAIL'),
                validationSchema: Yup.string().notRequired() as any,
                fieldOptions: {
                  disabled: true,
                },
              })
              .addField('specialities', {
                type: GeneralFormFields.INPUT_FIELD,
                title: i18n.t('SPECIALITIES'),
                fieldOptions: {
                  disabled: true,
                },
              })
              .addField('grade', {
                type: GeneralFormFields.INPUT_FIELD,
                title: i18n.t('GRADE'),
                fieldOptions: {
                  disabled: true,
                },
              })
              .addField('website', {
                type: GeneralFormFields.INPUT_FIELD,
                validationSchema: Yup.string().notRequired().nullable() as any,
                title: i18n.t('WEBSITE'),
                fieldOptions: {
                  disabled: true,
                },
              })
              .addField('languages', {
                type: GeneralFormFields.INPUT_FIELD,
                validationSchema: Yup.string().notRequired() as any,
                title: i18n.t('LANGUAGES'),
                fieldOptions: {
                  disabled: true,
                },
              })
              .addField('certificateId', {
                type: GeneralFormFields.FILE_PICKER,
                title: i18n.t('CERTIFICATE_ID'),
                fieldOptions: {
                  size: 200,
                  disabled: true,
                  accept: 'image/*',
                  multipleImages: false,
                },
              })
              .addField('syndicateIdURL', {
                type: GeneralFormFields.FILE_PICKER,
                title: i18n.t('SYNDICATE_ID'),
                fieldOptions: {
                  size: 200,
                  accept: 'image/*',
                  disabled: true,
                  multipleImages: false,
                },
              })
              .addField('nationalIdFrontURL', {
                type: GeneralFormFields.FILE_PICKER,
                title: i18n.t('NATIONAL_ID_FRONT'),
                validationSchema: Yup.array().max(1) as any,
                fieldOptions: {
                  size: 200,
                  accept: 'image/*',
                  disabled: true,
                  multipleImages: false,
                },
              })
              .addField('nationalIdBackURL', {
                type: GeneralFormFields.FILE_PICKER,
                title: i18n.t('NATIONAL_ID_BACK'),
                fieldOptions: {
                  size: 200,
                  accept: 'image/*',
                  disabled: true,
                  multipleImages: false,
                },
              })
              .addField('taxId', {
                type: GeneralFormFields.FILE_PICKER,
                title: i18n.t('TAX_ID'),
                fieldOptions: {
                  size: 200,
                  accept: 'image/*',
                  disabled: true,
                  multipleImages: false,
                },
              })
              .addField('about', {
                type: GeneralFormFields.INPUT_FIELD,
                title: i18n.t('ABOUT_DOCTOR'),
                validationSchema: Yup.string().notRequired().nullable() as any,
                fieldOptions: {
                  disabled: true,
                },
              })
              .addPreviewComponent(() => {
                if (!viewModeEnabled) {
                  return null;
                }
                return (
                  <div style={{
                    height: 720,
                  }}
                  >
                    <Calendar
                      views={['day', 'week', 'month']}
                      onView={setView}
                      view={view}
                      onNavigate={(newDate) => setDate(newDate)}
                      date={date}
                      eventPropGetter={(event) => {
                        let backgroundColor: string = 'orange';
                        if (event.title === ScheduleType.CLINIC) {
                          backgroundColor = 'blue';
                        } else if (event.title === ScheduleType.ONLINE_CONSULTATION) {
                          backgroundColor = 'green';
                        }
                        return {
                          style: {
                            backgroundColor,
                          },
                        };
                      }}
                      onRangeChange={(range) => {
                        let newStartDate: Moment;
                        let newEndDate: Moment;
                        if (Array.isArray(range)) {
                          newStartDate = moment(range[0]).startOf('day');
                          newEndDate = moment(range[range.length - 1]).endOf('day');
                        } else {
                          newStartDate = moment(range.start).startOf('day');
                          newEndDate = moment(range.end).endOf('day');
                        }
                        setStartDate(newStartDate);
                        setEndDate(newEndDate);
                      }}
                      localizer={localizer}
                      events={events}
                    />
                  </div>
                );
              })
              .addField('scheduleHomeVisitFees', {
                type: GeneralFormFields.INPUT_FIELD,
                title: i18n.t('HOME_VISIT_FEES'),
                validationSchema: Yup.string().required(i18n.t('REQUIRED_FIELD')) as any,
                fieldOptions: {
                  type: 'number',
                  disabled: viewModeEnabled,
                },
              })
              .addField('homeVisitSchedule', {
                type: GeneralFormFields.SCHEDULE,
                title: i18n.t('HOME_VISIT_SCHEDULE'),
                fieldOptions: {
                  disabled: viewModeEnabled,
                },
              })
              .addField('scheduleOnlineConsultationFees', {
                type: GeneralFormFields.INPUT_FIELD,
                title: i18n.t('ONLINE_CONSULTATION_FEES'),
                validationSchema: Yup.string().required(i18n.t('REQUIRED_FIELD')) as any,
                fieldOptions: {
                  type: 'number',
                  disabled: viewModeEnabled,
                },
              })
              .addField('onlineConsultationSchedule', {
                type: GeneralFormFields.SCHEDULE,
                title: i18n.t('ONLINE_CONSULTATION_SCHEDULE'),
                fieldOptions: {
                  disabled: viewModeEnabled,
                },
              })
              .addField('scheduleClinicFees', {
                type: GeneralFormFields.INPUT_FIELD,
                title: i18n.t('CLINIC_FEES'),
                validationSchema: Yup.string().required(i18n.t('REQUIRED_FIELD')) as any,
                fieldOptions: {
                  disabled: viewModeEnabled,
                  type: 'number',
                },
              })
              .addField('clinicSchedule', {
                type: GeneralFormFields.SCHEDULE,
                title: i18n.t('CLINIC_SCHEDULE'),
                fieldOptions: {
                  disabled: viewModeEnabled,
                },
              })
              .formFields}
            isFetchSuccessful
            update={async (_id, obj) => {
              const data: Schedule[] = [
                {
                  id: obj.homeVisitScheduleId,
                  type: ScheduleType.HOME_VISIT,
                  fees: Number.parseFloat(obj.scheduleHomeVisitFees.toString()),
                  workingDays: formatSchedule(obj.homeVisitSchedule),
                },
                {
                  id: obj.onlineConsultationScheduleId,
                  type: ScheduleType.ONLINE_CONSULTATION,
                  fees: Number.parseFloat(obj.scheduleOnlineConsultationFees.toString()),
                  workingDays: formatSchedule(obj.onlineConsultationSchedule),
                },
                {
                  type: ScheduleType.CLINIC,
                  id: obj.clinicScheduleId,
                  fees: Number.parseFloat(obj.scheduleClinicFees.toString()),
                  workingDays: formatSchedule(obj.clinicSchedule),
                },
              ];
              const alreadyExists = selectedDoctor.serviceProviders
                .filter((item) => item.id === auth.currentUser.serviceProvider.id).length > 0;
              if (!alreadyExists) {
                await doctors.addDoctorToServiceProvider(selectedDoctor.id, data);
              } else {
                await doctors.editDoctorToServiceProvider(selectedDoctor.id, data);
              }
              if (alreadyExists) {
                history.push('/doctors/:id');
              } else {
                history.push('/add-doctor-verification');
              }
              return Promise.resolve();
            }}
            create={async () => Promise.resolve(1)}
            get={async () => Promise.resolve(new DoctorModelView(selectedDoctor))}
          />
          )}
          {(!!doctorId && viewModeEnabled && !noDoctorFound) && (
          <GeneralTableView<RequestTable>
            tableProps={{
              title: i18n.t('REQUESTS_LIST'),
              actions: [
                {
                  icon: () => (
                    <img
                      src={Assets.images.common.eye}
                      alt={i18n.t('VIEW')}
                    />
                  ),
                  onClick: async (event, data: any) => {
                    console.log('VIEW:', data);
                  },
                },
              ],
            }}
            options={{
              search: false,
              pageSize: 10,
            }}
            get={async (query) => {
              const filter: SearchObj = {
                filters: [
                  {
                    by: 'doctor',
                    type: 'FIXED',
                    value: (doctorId as number).toString(),
                  },
                  // {
                  //   by: 'date',
                  //   type: 'RANGE',
                  //   min: moment().subtract(7, 'days').format('YYYY-MM-DD'),
                  //   max: moment().format('YYYY-MM-DD'),
                  // },
                ],
              };
              if (query.orderBy?.field) {
                filter.sort = {
                  by: query.orderBy.field,
                  type: query.orderDirection.toUpperCase() as any,
                };
              }
              await requestsStore.updateFilter(filter);
              const response: any = await requestsStore.getAll(filter);
              const requests = toJS(response).data.map((item: any) => Request.toTableData(item));
              const len = requests.length;
              return Promise.resolve({
                data: requests,
                totalCount: len,
                page: response.meta.page - 1,
              });
            }}
            tableColumns={[
              {
                field: 'consultation_type',
                title: i18n.t('TYPE'),
                type: TableColumnTypes.STRING,
                filterType: FilterTypes.VALUE,
              },
              {
                field: 'date',
                title: i18n.t('DATE'),
                type: TableColumnTypes.STRING,
              },
              {
                field: 'time',
                title: i18n.t('TIME'),
                type: TableColumnTypes.STRING,
              },
              {
                field: 'status',
                title: i18n.t('STATUS'),
                type: TableColumnTypes.STRING,
                filterType: FilterTypes.VALUE,
              },
            ]}
          />
          )}
          {(!!doctorId && viewModeEnabled && !noDoctorFound) && (
          <div>
            <SearchFilterDropdown
              label={i18n.t('STATUS')}
              style={{
                margin: 16,
              }}
              searchFilterDropdown={{
                filters: [
                  {
                    name: i18n.t('ALL'),
                    value: ScheduleType.ALL,
                  },
                  {
                    name: i18n.t('HOME_VISITS'),
                    value: ScheduleType.HOME_VISIT,
                  }, {
                    name: i18n.t('ONLINE_CONSULTATION'),
                    value: ScheduleType.ONLINE_CONSULTATION,
                  },
                  {
                    name: i18n.t('CLINICS'),
                    value: ScheduleType.CLINIC,
                  },
                ],
                value: currentFilter || 'all',
                initialValue: currentFilter || 'all',
                onChange: onFilterChange,
              }}
            />
            <GeneralTableView<RequestTable>
              tableProps={{
                tableRef: ref,
                title: i18n.t('ACTIVITY_LOGS'),
                options: {
                  actionsColumnIndex: 10,
                },
                actions: [
                  {
                    isFreeAction: true,
                    icon: ImportExportIcon,
                    onClick: () => {
                      window.open(requestsStore.download(), '_blank');
                    },
                    tooltip: i18n.t('EXPORT_DATA'),
                  },
                ],
              }}
              options={{
                search: false,
                pageSize: 10,
                draggable: false,
              }}
              get={async (query) => {
                if (query.orderBy?.field) {
                  await requestsStore.updateFilter({
                    ...requestsStore.filter,
                    sort: {
                      by: query.orderBy.field,
                      type: query.orderDirection.toUpperCase() as any,
                    },
                  });
                }
                await requestsStore.entityList.updateOptions(query);
                return {
                  data: requestsStore.entityList.data.map((item) => toTableData(item)),
                  totalCount: requestsStore.entityList.data.length,
                  page: requestsStore.entityList.meta.page - 1,
                };
              }}
              tableColumns={[
                {
                  field: 'consultation_type',
                  title: i18n.t('TYPE'),
                  type: TableColumnTypes.STRING,
                  filterType: FilterTypes.VALUE,
                },
                {
                  field: 'date',
                  title: i18n.t('DATE'),
                  type: TableColumnTypes.STRING,
                },
                {
                  field: 'time',
                  title: i18n.t('TIME'),
                  type: TableColumnTypes.STRING,
                },
                {
                  field: 'status',
                  title: i18n.t('STATUS'),
                  type: TableColumnTypes.STRING,
                  filterType: FilterTypes.VALUE,
                },
                {
                  field: 'startedAt',
                  title: i18n.t('STARTED_AT'),
                  type: TableColumnTypes.STRING,
                  filterType: FilterTypes.VALUE,
                },
                {
                  field: 'finishedAt',
                  title: i18n.t('FINISHED_AT'),
                  type: TableColumnTypes.STRING,
                  filterType: FilterTypes.VALUE,
                },
              ]}
            />
          </div>
          )}
        </div>
      </ComponentWithLoading>

    </div>
  );
};
export const DoctorDetails = baseScreen(doctorDetails, {
  allowedRoles: ['ADMIN', 'NONE'],
});
