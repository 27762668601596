export const Styles: any = {
  root: {
    display: 'flex',
    paddingTop: 50,
    flexDirection: 'column',
    justifyContent: 'center',
    height: '93.5vh',
  },
  loginForm: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-around',
    width: '100%',
  },
  logo: {
    margin: '20px auto',
  },
  loginButton: {
    marginTop: '20px',
    background: '#EF7D00',
  },
  footer: {
    marginTop: 'auto',
    alignText: 'right',
  },
  forgetPassword: {
    marginTop: 20,
  },
};
