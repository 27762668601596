import {
  AvailableLanguages,
  MapLocation,
} from 'src/components/GeneralFormView/types';
import {
  ModelOf,
} from 'utils';
import {
  BaseDTO,
} from '../types';

export interface ServiceProviderTypeTranslation {
  id: number,
  createdAt?: Date,
  updatedAt?: Date,
  title: string,
  languageCode: AvailableLanguages,
}

export interface ServiceProviderType {
  id: number,
  translations: ServiceProviderTypeTranslation[]
}

interface TranslationItem {
  name: string,
  description?: string,
  languageCode: AvailableLanguages,
}

export class LoginDTO extends BaseDTO {
  email: string = '';

  password: string = '';

  constructor(initValues?: Partial<ModelOf<LoginDTO>>) {
    super();
    Object.assign(this, initValues);
  }
}
export class CreateServiceProviderDTO extends BaseDTO {
  translations: TranslationItem[] = [
    {
      name: '',
      languageCode: AvailableLanguages.ENGLISH,
      description: '',
    },
    {
      name: '',
      description: '',
      languageCode: AvailableLanguages.ARABIC,
    },
  ];

  id?: number;

  email: string = '';

  phone: string = '';

  password: string = '';

  avatar?: string = '';

  // appLanguage?: string = '';

  serviceProviderTypeId: number = 0;

  landlinePhone?: string = '';

  cityId?: number = 0;

  governorateId?: number = 0;

  area?: string = '';

  location?: MapLocation = {
    latitude: 0,
    longitude: 0,
  };

  website?: string = '';

  commercialRecord: string = '';

  taxId: string = '';

  docs?: string[] = [];

  coordinatorName: string = '';

  coordinatorPhone: string = '';

  agreeToTermsAndPolicy: boolean = false;

  constructor(initValues?: Partial<ModelOf<CreateServiceProviderDTO>>) {
    super();
    if (initValues) {
      Object.assign(this, initValues);
    }
  }
}
