/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  FormControl,
  // MenuItem,
  // Select,
  // Theme,
  // Typography,
  // useTheme,
} from '@material-ui/core';
import {
  Form,
  Formik,
} from 'formik';
import React, {
  PropsWithChildren,
  useEffect,
  useState,
} from 'react';
import {
  useHistory,
  useLocation,
  useParams,
} from 'react-router';
import i18n from 'i18n-js';
import * as Yup from 'yup';
import {
  BaseDTO as DTO,
} from 'shared';
import merge from 'lodash/merge';
import clonedeep from 'lodash/cloneDeep';
import {
  BaseFormViewModel,
} from '../../screens/types';
import Spinner from '../Spinner';
import {
  FORM_FIELDS,
} from './constants';
import {
  // MenuProps,
  useStyles,
} from './FormField/SelectField';
// import CustomSelectInput from './FormField/SelectField/CustomSelectInput';
// import CustomSelectInputLabel from './FormField/SelectField/CustomSelectInputLabel';
import GeneralFormFields, {
  FieldsData,
  FormFieldData,
  isCustomFormPreview,
  isFormField,
  Props,
  RequestStatus,
} from './types';
import FormFieldCondition from './Utils/FormFieldCondition';

// const getStyles = (value: string, selectedValues: string[], theme: Theme) => ({
//   fontWeight:
//     !selectedValues.includes(value)
//       ? theme.typography.fontWeightRegular
//       : theme.typography.fontWeightMedium,
// });

export const generateInitialValues = <
  EntityDTO extends DTO,
  FormViewModel extends BaseFormViewModel<EntityDTO>,
  >(
    value: FormFieldData<FormViewModel, keyof FormViewModel>,
  ): FormViewModel[keyof FormViewModel] => {
  if (value.initialValue) {
    return value.initialValue;
  }
  let initValue = (clonedeep(FORM_FIELDS[value.type].initialValue) as unknown) as (FormViewModel[keyof FormViewModel]);
  if (value.type === GeneralFormFields.NESTED_FORM) {
    const newInitValue: Record<keyof FormViewModel, FormViewModel[keyof FormViewModel]> = {} as any;
    value.fieldOptions.formData
      .filter((formEntry) => !isCustomFormPreview(formEntry))
      .forEach((formEntry) => {
        if (isFormField<FormViewModel, keyof FormViewModel>(formEntry)) {
          const innerKey = (formEntry.key as keyof FormViewModel);
          const innerValue = formEntry as FormFieldData<FormViewModel, keyof FormViewModel>;
          newInitValue[innerKey] = generateInitialValues(innerValue);
        }
      });
    initValue = newInitValue as any;
  }
  return initValue;
};

function generateValidationSchema<
  EntityDTO extends DTO,
  FormViewModel extends BaseFormViewModel<EntityDTO>,
  >(value: FormFieldData<FormViewModel, keyof FormViewModel>): Yup.SchemaOf<any> {
  let schema: Yup.SchemaOf<any>;
  if (value.validationSchema) {
    schema = value.validationSchema;
  } else {
    schema = FORM_FIELDS[value.type].validationSchema;
  }
  if (value.type === GeneralFormFields.FIELD_ARRAY) {
    schema = (schema.clone() as Yup.ArraySchema<any>).of(generateValidationSchema(value.fieldOptions.formData as any));
  } else if (value.type === GeneralFormFields.NESTED_FORM) {
    // eslint-disable-next-line max-len
    const newValidationSchema: Record<keyof FormViewModel, Yup.SchemaOf<any>> = {} as Record<keyof FormViewModel, Yup.SchemaOf<any>>;
    value.fieldOptions.formData
      .filter((formEntry) => !isCustomFormPreview(formEntry))
      .forEach((formEntry) => {
        if (isFormField<FormViewModel, keyof FormViewModel>(formEntry)) {
          const innerKey = (formEntry.key as keyof FormViewModel);
          const innerValue = formEntry as FormFieldData<FormViewModel, keyof FormViewModel>;
          newValidationSchema[innerKey] = generateValidationSchema(innerValue).clone();
        }
      });
    schema = Yup.object(newValidationSchema);
  }
  return schema;
}

const processFormData = <
  EntityDTO extends DTO,
  FormViewModel extends BaseFormViewModel<EntityDTO>,
  >(formData: FieldsData<FormViewModel>, mappedStoreData: any, isCurrentlyEditting = false) => {
  // eslint-disable-next-line max-len
  const newValidationSchema: Record<keyof FormViewModel, Yup.SchemaOf<any>> = {} as Record<keyof FormViewModel, Yup.SchemaOf<any>>;
  const newInitialValues: FormViewModel = {} as FormViewModel;
  formData
    .filter((formEntry) => !isCustomFormPreview(formEntry))
    .forEach((formEntry) => {
      if (isFormField<FormViewModel, keyof FormViewModel>(formEntry)) {
        const {
          key,
        } = formEntry;
        const value = formEntry;
        newValidationSchema[key] = generateValidationSchema(value).clone();
        newInitialValues[key] = generateInitialValues(value);
      }
    });
  return {
    validationSchema: Yup.object(newValidationSchema),
    initialValues: isCurrentlyEditting ? merge(newInitialValues, mappedStoreData) : newInitialValues,
  };
};

const GeneralFormView = <
  EntityDTO extends DTO,
  FormViewModel extends BaseFormViewModel<EntityDTO>,
  >(props: PropsWithChildren<Props<EntityDTO, FormViewModel>>) => {
  const {
    defaultLang,
    otherLanguages,
    create,
    formData,
    get,
    isFetchSuccessful,
    title,
    update,
    createOrUpdateStatus,
    viewMode = false,
  } = props;

  const [languages] = useState<string[]>([defaultLang, ...otherLanguages]);

  // const theme = useTheme();
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const queryParams = new URLSearchParams(location.search);
  const paramLang = queryParams.get('lang');

  const {
    id,
  } = useParams<{ id: string }>();
  const isEditting = !!id;

  const [isInitialize, setIsInitialize] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState<FormViewModel>();
  const [validationSchema, setValidationSchema] = useState<Yup.SchemaOf<any>>();
  const isLastLanguage = languages.indexOf(paramLang || defaultLang) + 1 === languages.length;

  useEffect(
    () => {
      const onLocationChange = async () => {
        // if (
        //   !paramLang
        //   || !languages.includes(paramLang)
        //   || (!id && paramLang !== defaultLang)
        // ) {
        //   history.replace({
        //     pathname: location.pathname,
        //     search: `?lang=${defaultLang}`,
        //   });
        // }
        const initialData = isEditting ? await get(id, paramLang || defaultLang) : null;

        const {
          validationSchema: newValidationSchema,
          initialValues: newInitialValues,
        } = processFormData(formData, initialData, isEditting);

        setInitialValues(newInitialValues);
        setValidationSchema(newValidationSchema);
        setIsInitialize(true);
      };
      onLocationChange();
    },
    [
      paramLang,
      id,
      isEditting,
    ],
  );

  const removeLastRoute = (path: string) => path.slice(0, path.lastIndexOf('/'));

  const getBaseURL = (pathName: string) => {
    let returnValue = removeLastRoute(pathName);
    if (pathName.includes('edit')) {
      returnValue = removeLastRoute(returnValue);
    }
    return returnValue;
  };

  const onSubmit = async (values: FormViewModel) => {
    const baseURL: string = getBaseURL(location.pathname);
    if (isEditting) {
      await update(id, values, paramLang || defaultLang);
      if (!isLastLanguage) {
        const index = languages.indexOf(paramLang || defaultLang);
        const nextLang = languages[index + 1];
        history.push({
          pathname: `${baseURL}/${id}/edit`,
          search: `?lang=${nextLang}`,
        });
      }
    } else {
      await create(values);
      // const entityId = await create(values);
      // if (isLastLanguage) {
      //   history.push(baseURL);
      // } else {
      //   const index = languages.indexOf(paramLang || defaultLang);
      //   const nextLang = languages[index + 1];
      //   history.push({
      //     pathname: `${baseURL}/${entityId}/edit`,
      //     search: `?lang=${nextLang}`,
      //   });
      // }
    }
  };

  if (!isEditting || (isFetchSuccessful && isInitialize)) {
    return (
      <Card>
        {!!title && (
        <CardHeader
          title={`${isEditting ? i18n.t('EDIT') : i18n.t('ADD')} ${title}`}
          action={(isEditting && (
          <FormControl className={classes.formControl}>
            {/* <Select
                value={paramLang}
                placeholder="Language"
                onChange={(event) => {
                  const {
                    value,
                  } = event.target;
                  history.replace({
                    pathname: location.pathname,
                    search: `?lang=${value}`,
                  });
                }}
                input={<CustomSelectInput />}
                renderValue={((selected: string) => (
                  <Typography
                    variant="subtitle1"
                  >
                    {selected}
                  </Typography>
                )) as any}
                MenuProps={MenuProps}
                style={{
                  width: 120,
                }}
              >
                {languages.map((item) => (
                  <MenuItem key={item} value={item} style={getStyles(item, languages, theme)}>
                    {item}
                  </MenuItem>
                ))}
              </Select> */}
            {/* <CustomSelectInputLabel
              style={{
                paddingLeft: 14,
              }}
            >
              Language
            </CustomSelectInputLabel> */}
          </FormControl>
          ))}
        />
        )}
        <CardContent>
          <Formik
            initialValues={initialValues || {} as FormViewModel}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
            enableReinitialize
          >
            {
              (formikBag) => (
                <Form
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'stretch',
                  }}
                >
                  {
                    formData.map((formEntry) => {
                      if (isCustomFormPreview(formEntry)) {
                        const CustomComponent = formEntry.component;
                        return (
                          <CustomComponent {...formikBag.values} />
                        );
                      }
                      if (isFormField<FormViewModel, keyof FormViewModel>(formEntry)) {
                        const {
                          key,
                        } = formEntry;
                        const value = formEntry;
                        if (!formikBag.values || formikBag.values[key] === undefined) {
                          return null;
                        }
                        return (
                          <FormFieldCondition<FormViewModel, keyof FormViewModel>
                            key={`${key}`}
                            fieldData={value}
                            location={`${key}`}
                            viewMode={viewMode}
                          />
                        );
                      }
                      return null;
                    })
                  }
                  {!viewMode && (
                  <Button
                    variant="contained"
                    onClick={() => formikBag.handleSubmit}
                    color="primary"
                    type="submit"
                    disabled={createOrUpdateStatus === RequestStatus.LOADING}
                    style={{
                      margin: 8,
                    }}
                  >
                    {
                      createOrUpdateStatus === RequestStatus.LOADING && (
                        <Spinner />
                      )
                    }
                    {
                      isLastLanguage
                        ? i18n.t('SUBMIT')
                        : i18n.t('TO_NEXT_LANGUAGE')
                    }
                  </Button>
                  )}
                </Form>
              )
            }
          </Formik>
        </CardContent>
      </Card>
    );
  }
  return (
    <Spinner />
  );
};

export default GeneralFormView;
/* eslint-enable @typescript-eslint/no-explicit-any */
