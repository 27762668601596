import {
  useFormikContext,
} from 'formik';
import React, {
  PropsWithChildren,
  useEffect,
  useState,
} from 'react';
import ArrayFormField from '../../FormField/ArrayFormField';
import DateTimeField from '../../FormField/DateTimeField';
import TextAreaField from '../../FormField/TextAreaFeild';
import FilePickerField from '../../FormField/FilePickerField';
import InputField from '../../FormField/InputField';
import LocationField from '../../FormField/LocationField';
import NestedFormField from '../../FormField/NestedFormField';
import RichTextField from '../../FormField/RichTextField';
import ScheduleField from '../../FormField/ScheduleField';
import SelectFormField from '../../FormField/SelectField';
import ToggleField from '../../FormField/ToggleField';
import {
  isArrayProps,
  isDateTimeProps,
  isImagePickerProps,
  isInputProps,
  isLocationProps,
  isNestedFormProps,
  isRichTextProps,
  isSelectProps,
  isTextAreaProps,
  isToggleFieldProps,
  isScheduleProps,
} from '../../FormField/types';
import {
  FormFieldActions,
  FormFieldStatus,
} from '../../types';
import {
  FormFieldConditionProps,
} from './types';

const FormFieldCondition = <T, K extends keyof T>(props: PropsWithChildren<FormFieldConditionProps<T, K>>) => {
  const [status, setStatus] = useState<FormFieldStatus>(FormFieldStatus.ENABLED);
  const formFieldActions: Record<FormFieldActions, () => void> = {
    [FormFieldActions.DISABLE]: () => setStatus(FormFieldStatus.DISABLED),
    [FormFieldActions.ENABLE]: () => setStatus(FormFieldStatus.ENABLED),
    [FormFieldActions.HIDE]: () => setStatus(FormFieldStatus.HIDDEN),
  };

  const {
    values,
  } = useFormikContext();

  const {
    fieldData: {
      title,
      type,
      condition,
      fieldOptions,
    },
    location,
    viewMode,
  } = props;
  useEffect(() => {
    if (values && condition) {
      condition(values, formFieldActions, location).execute();
    }
  }, [condition, formFieldActions, values]);

  if (status === FormFieldStatus.HIDDEN) {
    return null;
  }

  const newProps: any = {
    title,
    location,
    fieldOptions,
    type,
    viewMode,
    disabled: status === FormFieldStatus.DISABLED,
  };

  if (isInputProps<T, K>(newProps)) {
    return (
      <InputField
        {...newProps}
      />
    );
  } if (isTextAreaProps<T, K>(newProps)) {
    return (
      <TextAreaField
        {...newProps}
      />
    );
  } if (isImagePickerProps<T, K>(newProps)) {
    return (
      <FilePickerField
        {...newProps}
      />
    );
  } if (isNestedFormProps<T, K>(newProps)) {
    return (
      <NestedFormField
        {...newProps}
      />
    );
  } if (isArrayProps<T, K>(newProps)) {
    return (
      <ArrayFormField
        {...newProps}
      />
    );
  } if (isSelectProps<T, K>(newProps)) {
    return (
      <SelectFormField
        {...newProps}
      />
    );
  } if (isRichTextProps<T, K>(newProps)) {
    return (
      <RichTextField
        {...newProps}
      />
    );
  } if (isDateTimeProps<T, K>(newProps)) {
    return (
      <DateTimeField
        {...newProps}
      />
    );
  } if (isToggleFieldProps<T, K>(newProps)) {
    return (
      <ToggleField
        {...newProps}
      />
    );
  } if (isLocationProps<T, K>(newProps)) {
    return (
      <LocationField
        {...newProps}
      />
    );
  }
  if (isScheduleProps<T, K>(newProps)) {
    return (
      <ScheduleField
        {...newProps}
      />
    );
  }
  return null;
};

export default FormFieldCondition;
