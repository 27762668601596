export const InputRegex = {
  // eslint-disable-next-line no-useless-escape
  url: /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/,
  arabic: /^[\u0621-\u064A\s]*$/,
  english: /^[a-zA-Z\s'-]*$/,
  lowercase: /(?=.*[a-z])/,
  uppercase: /(?=.*[A-Z])/,
  numbers: /(?=.*[0-9])/,
  mobileNum: /^\d{10}$/,
  landlineNum: /^\d{8,9}$/,
};
