import placeholder from './placeholder.png';
import bell from './bell.svg';
import edit from './edit.svg';
import eye from './eye.svg';
import trash from './trash.svg';
import kashfLogo from './logo-200.png';
import kashfTextLogo from './logo-text-200.png';
import doctors from './doctors.png';
import requests from './requests.png';
import patients from './patients.png';
import search from './search.png';
import profile from './profile.png';
import chevronBottom from './chevron-bottom.png';
import whatsup from './whatsup.png';

export class Common {
  placeholder = placeholder;

  kashfLogo = kashfLogo;

  whatsup = whatsup;

  kashfTextLogo = kashfTextLogo;

  search = search;

  requests = requests;

  profile = profile;

  edit = edit;

  chevronDown = chevronBottom;

  doctors = doctors;

  trash = trash;

  eye = eye;

  patients = patients;

  bell = bell;
}
