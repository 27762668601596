import {
  baseScreen,
} from 'hoc';
import React,
{
  useRef,
  useState,
} from 'react';
import GeneralTableView from 'src/components/GeneralTableView';
import i18n from 'i18n-js';
import {
  useStores,
} from 'hooks';
import {
  toJS,
} from 'mobx';
import {
  FilterTypes,
  TableColumnTypes,
} from 'src/components/GeneralTableView/types';
import {
  useParams,
} from 'react-router';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import SearchFilterDropdown from 'src/components/SearchFilterComponent';
import moment from 'moment';
import {
  Button,
} from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import DateRange from 'src/components/DateRange';
import {
  Donation,
} from './types';

const donationMonthlyBreakdown: React.FC = () => {
  const {
    backend: {
      donationStore,
      auth: {
        currentUser,
      },
    },
  } = useStores();
  const ref = useRef<any|null>(null);
  const [
    currentFilter,
    setCurrentFilter,
  ] = useState('all');
  const [
    filterDisplayed,
    setFilterDisplayed,
  ] = useState(false);
  const {
    date,
  } = useParams() as any;
  const [
    range,
    setRange,
  ] = useState<{
    min: Date,
    max: Date,
  }>(
    {
      min: moment(date).startOf('month').toDate(),
      max: moment(date).endOf('month').toDate(),
    },
  );
  return (
    <div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginBottom: 16,
          cursor: 'pointer',
        }}
        onClick={() => setFilterDisplayed(!filterDisplayed)}
        tabIndex={0}
        role="button"
        onDoubleClick={() => {
          setFilterDisplayed(!filterDisplayed);
        }}
        onKeyDown={() => {
        }}
      >
        <span
          style={{
            marginLeft: 20,
            marginRight: 20,
          }}
        >
          {i18n.t('FILTER')}
        </span>
        <FilterListIcon />
      </div>
      {filterDisplayed && (
        <div>
          <SearchFilterDropdown
            label={i18n.t('STATUS')}
            style={{
              margin: 16,
            }}
            searchFilterDropdown={{
              filters: [
                {
                  name: i18n.t('ALL'),
                  value: 'all',
                },
                {
                  name: i18n.t('HOME_VISITS'),
                  value: 'Home Visit',
                }, {
                  name: i18n.t('ONLINE_CONSULTATION'),
                  value: 'Online Consultation',
                },
                {
                  name: i18n.t('CLINICS'),
                  value: 'Clinic',
                },
              ],
              onChange: (val) => {
                setCurrentFilter(val);
                ref.current?.onQueryChange();
              },
              value: 'all',
              initialValue: 'all',
            }}
          />
          <DateRange
            onChange={async (start, end) => {
              await setRange({
                min: start,
                max: end,
              });
              ref.current?.onQueryChange();
            }}
          />
          <div
            style={{
              justifyContent: 'flex-end',
              display: 'flex',
              marginBottom: 16,
            }}
          >
            <Button
              style={{
                background: '#3f51b5',
                color: '#FFF',
              }}
              onClick={async () => {
                await setFilterDisplayed(false);
                await setRange({} as any);
                await setCurrentFilter('all');
                ref.current?.onQueryChange();
              }}
            >
              {i18n.t('RESET')}
            </Button>
          </div>
        </div>
      )}

      <GeneralTableView<Donation>
        tableProps={{
          tableRef: ref,
          options: {
            actionsColumnIndex: 10,
            search: true,
            paging: true,
          },
          title: i18n.t('MONTHLY_BREAKDOWN'),
          actions: [
            {
              isFreeAction: true,
              icon: ImportExportIcon,
              onClick: () => {
                window.open(donationStore.download(), '_blanck');
              },
              tooltip: i18n.t('EXPORT_DATA'),
            },
          ],
        }}
        options={{
          search: false,
          pageSize: 10,
          filtering: false,
        }}
        get={async (query) => {
          const q: any = {
            ...query,
            filters: [
              {
                type: 'FIXED',
                by: 'service_provider',
                value: currentUser.serviceProvider.id,
              },
            ],
          };
          if (range.min) {
            q.filters.push({
              type: 'RANGE',
              by: 'date',
              min: range.min,
              max: range.max,
            });
          } else {
            q.filters.push({
              type: 'RANGE',
              by: 'date',
              min: moment().subtract(1, 'months').startOf('month').toDate(),
              max: moment().subtract(1, 'months').endOf('month').toDate(),
            });
          }
          if (currentFilter !== 'all') {
            q.filters.push({
              by: 'consultation_type',
              value: currentFilter,
              type: 'FIXED',
            });
          }
          await donationStore.donationList.updateOptions(q);
          return {
            data: toJS(donationStore.donationList.data).map((item) => new Donation(item)),
            totalCount: donationStore.donationList.meta.itemCount,
            page: donationStore.donationList.meta.page - 1,
          };
        }}
        tableColumns={[
          {
            field: 'requestId',
            title: i18n.t('REQUEST_ID'),
            type: TableColumnTypes.NUMERIC,
            filterType: FilterTypes.VALUE,
          },
          {
            field: 'type',
            title: i18n.t('CONSULTATION_TYPE'),
            type: TableColumnTypes.STRING,
            filterType: FilterTypes.VALUE,
          },
          {
            field: 'date',
            title: i18n.t('DATE'),
            type: TableColumnTypes.STRING,
            filterType: FilterTypes.VALUE,
          },
          {
            field: 'time',
            title: i18n.t('TIME'),
            type: TableColumnTypes.STRING,
            filterType: FilterTypes.VALUE,
          },
          // {
          //   field: 'doctorName',
          //   title: i18n.t('DR'),
          //   type: TableColumnTypes.NUMERIC,
          //   filterType: FilterTypes.VALUE,
          // },
          // {
          //   field: 'doctorId',
          //   title: i18n.t('DR_ID'),
          //   type: TableColumnTypes.NUMERIC,
          //   filterType: FilterTypes.VALUE,
          // },
          {
            field: 'patientName',
            title: i18n.t('PATIENT'),
            type: TableColumnTypes.STRING,
            filterType: FilterTypes.VALUE,
          },
          {
            field: 'total',
            title: i18n.t('TOTAL'),
            type: TableColumnTypes.STRING,
            filterType: FilterTypes.VALUE,
          },
          {
            field: 'kashfPercentage',
            title: i18n.t('KASHF_PERCENTAGE'),
            type: TableColumnTypes.NUMERIC,
            filterType: FilterTypes.VALUE,
          },
          {
            field: 'subTotal',
            title: i18n.t('SUBTOTAL'),
            type: TableColumnTypes.NUMERIC,
            filterType: FilterTypes.VALUE,
          },
        ]}
      />
    </div>
  );
};

export const DonationMonthlyBreakdown = baseScreen(donationMonthlyBreakdown, {
  allowedRoles: ['ADMIN', 'NONE'],
});
