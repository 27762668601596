import {
  BaseDTO,
} from 'shared';

export class BillsDTO extends BaseDTO {
  id?: number;

  balance: number;

  end: Date;

  email: string;

  grossRevenue: number;

  clinicVisitRevenue: number;

  status: BillStatus;

  kashfPercentage: number;

  netRevenue: number;

  homeVisitRevenue: string;

  onlineConsulationRevenue: number;

  type: string;
}

export enum BillStatus {
  NOT_SETTLED = 'NotSettled',
  SETTLED = 'Settled',
}

export class SettlementDTO extends BaseDTO {
  date: Date;

  transactionId: string;

  billId: number;

  receipt: string;

  bankName: string;
}
