import React from 'react';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ErrorIcon from '@material-ui/icons/Error';
import i18n from 'i18n-js';
import {
  Button,
  Typography,
} from '@material-ui/core';
import {
  useStyles,
} from './styles';
import {
  Props,
} from './types';

export const ForgotPasswordModal = (props: Props) => {
  const classes = useStyles();
  const {
    mode,
    onDoneClick,
  } = props;
  return (
    <div
      className={classes.container}
    >
      <div style={{
        flexDirection: 'row',
        display: 'flex',
      }}
      >
        { mode === 'success'
          ? <CheckCircleOutlineIcon color="primary" fontSize="large" className={classes.icon} />
          : <ErrorIcon color="secondary" fontSize="large" className={classes.icon} />}
        <Typography variant="h6" className={classes.msg}>
          {mode === 'success' ? i18n.t('EMAIL_SENT_SUCCESSFULLY') : i18n.t('EMAIL_NOT_EXIST')}
        </Typography>
      </div>
      <Button variant="outlined" color="primary" className={classes.btn} onClick={onDoneClick}>
        {i18n.t('DONE')}
      </Button>
    </div>
  );
};
