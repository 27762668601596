// import Patients from 'src/screens/patients';
// import Services from 'src/screens/services';
import {
  DoctorDetails,
  Doctors,
  HomeScreen,
  LoginScreen,
  PatientsScreen,
  Profile,
  MonthlyBreakdown,
  Settlement,
  RegisterScreen,
  Requests,
  Payment,
  // TestScreen,
  ThankYou,
  RequestDetails,
  AddDoctorVerification,
  PatientDetails,
} from 'src/screens';
import {
  ModuleTypes,
  Route,
} from './types';
import { Donation } from 'src/screens/balance/donation';
import { DonationMonthlyBreakdown } from 'src/screens/balance/donationmonthlyBreakdown';

const home = new Route({
  path: '',
  type: ModuleTypes.CONTAINER,
  allowedRoles: [],
  Component: HomeScreen,
  name: 'HOME',
  sideNavConfig: {},
});

const thankYouScreen = new Route({
  path: 'thank-you',
  type: ModuleTypes.SCREEN,
  allowedRoles: [],
  Component: ThankYou,
  name: '',
});

const addDoctorVerification = new Route({
  path: 'add-doctor-verification',
  type: ModuleTypes.SCREEN,
  allowedRoles: [],
  Component: AddDoctorVerification,
  name: '',
});

const requests = new Route({
  path: 'services/:id',
  type: ModuleTypes.CONTAINER,
  allowedRoles: [],
  Component: Requests,
  name: 'SERVICES',
  sideNavConfig: {},
});

const dateRequests = new Route({
  path: 'services/:id/:date',
  type: ModuleTypes.CONTAINER,
  allowedRoles: [],
  Component: Requests,
  name: '',
});

const requestDetails = new Route({
  path: 'service-details/view/:id',
  type: ModuleTypes.SCREEN,
  allowedRoles: [],
  Component: RequestDetails,
  name: '',
});

const doctorDetails = new Route({
  path: 'doctor-form/:mode/:id',
  type: ModuleTypes.SCREEN,
  allowedRoles: [],
  Component: DoctorDetails,
  name: '',
});

const doctors = new Route({
  path: 'doctors/:id',
  type: ModuleTypes.CONTAINER,
  allowedRoles: [],
  Component: Doctors,
  name: 'DOCTORS',
  sideNavConfig: {},
}).addChild(doctorDetails);

// const routeCA = new Route({
//   path: 'route-a',
//   type: ModuleTypes.SCREEN,
//   allowedRoles: [],
//   Component: testScreenTable,
//   name: 'Route C A',
//   sideNavConfig: {
//     icon: Assets.images.common.placeholder,
//   },
// });

// const Test = new Route({
//   path: 'test/:id',
//   type: ModuleTypes.SCREEN,
//   allowedRoles: [],
//   Component: TestScreen,
//   name: 'test',
//   sideNavConfig: {
//     icon: Assets.images.common.placeholder,
//   },
// });

const patients = new Route({
  path: '/patients/:id',
  type: ModuleTypes.CONTAINER,
  allowedRoles: [],
  Component: PatientsScreen,
  name: 'PATIENTS',
  sideNavConfig: {},
});

const patientDetails = new Route({
  path: 'patient-details/:id',
  type: ModuleTypes.SCREEN,
  allowedRoles: [],
  Component: PatientDetails,
  name: '',
});

const payment = new Route({
  path: 'payment',
  type: ModuleTypes.CONTAINER,
  Component: Payment,
  allowedRoles: [],
  name: 'PAYMENT',
  sideNavConfig: {},
});
const monthlyBreakdown = new Route({
  path: 'payment/monthly-breakdown/:date',
  type: ModuleTypes.CONTAINER,
  Component: MonthlyBreakdown,
  allowedRoles: [],
  name: 'MONTHLY_BREAKDOWN',
});
const donation = new Route({
  path: 'donation',
  type: ModuleTypes.CONTAINER,
  Component: Donation,
  allowedRoles: [],
  name: 'DONATION',
  sideNavConfig: {},
});
const donationMonthlyBreakdown = new Route({
  path: 'donation/monthly-breakdown/:date',
  type: ModuleTypes.CONTAINER,
  Component: DonationMonthlyBreakdown,
  allowedRoles: [],
  name: 'DONATION_MONTHLY_BREAKDOWN',
});
const donationSettlement = new Route({
  path: 'donation/settlement/:id',
  type: ModuleTypes.CONTAINER,
  Component: Settlement,
  allowedRoles: [],
  name: 'SETTLEMENT',
});
const settlement = new Route({
  path: 'payment/settlement/:id',
  type: ModuleTypes.CONTAINER,
  Component: Settlement,
  allowedRoles: [],
  name: 'SETTLEMENT',
});

const profile = new Route({
  path: 'profile/:mode/:id',
  type: ModuleTypes.CONTAINER,
  allowedRoles: [],
  name: 'PROFILE',
  Component: Profile,
  sideNavConfig: {},
});

const RegisterRoute = new Route({
  path: 'register',
  type: ModuleTypes.SCREEN,
  allowedRoles: [],
  name: 'Register',
  Component: RegisterScreen,
  sideNavConfig: {},
});

export const AuthRoute = new Route({
  path: '/',
  type: ModuleTypes.CONTAINER,
  allowedRoles: [],
  name: 'Authentication',
  Component: LoginScreen,
  sideNavConfig: {},
})
  .addChild(RegisterRoute)
  .addChild(thankYouScreen);

// const EditUserrRoute = new Route({
//   path: ':id/edit',
//   type: ModuleTypes.SCREEN,
//   allowedRoles: [],
//   name: 'Edit user',
//   Component: RegisterScreen,
//   sideNavConfig: {
//     icon: Assets.images.common.kashfLogo,
//   },
// });

export const RootRoute = new Route({
  path: '/',
  type: ModuleTypes.CONTAINER,
  allowedRoles: [],
  name: 'Home',
  Component: HomeScreen,
})
  .addChild(home)
  .addChild(requests)
  .addChild(dateRequests)
  .addChild(requestDetails)
  .addChild(doctors)
  .addChild(patients)
  .addChild(payment)
  .addChild(monthlyBreakdown)
  .addChild(donation)
  .addChild(donationMonthlyBreakdown)
  .addChild(settlement)
  .addChild(donationSettlement)
  .addChild(patientDetails)
  .addChild(profile)
  // .addChild(Test)
  .addChild(addDoctorVerification);
