import React from 'react';
import {
  useStores,
} from 'hooks';
import {
  baseScreen,
} from 'hoc';
import GeneralFormView from 'src/components/GeneralFormView';
import {
  FormFieldsBuilder,
} from 'src/components/GeneralFormView/Utils/FormFieldsBuilder';
import GeneralFormFields from 'src/components/GeneralFormView/types';
import i18n from 'i18n-js';
import {
  RequestModelView,
} from './types';

const requestDetails: React.FC = () => {
  const {
    backend: {
      requests: {
        getOne,
      },
    },
  } = useStores();
  return (
    <GeneralFormView<any, RequestModelView>
      title=""
      identifier="id"
      defaultLang="en"
      otherLanguages={[]}
      formData={new FormFieldsBuilder<RequestModelView>()
        .addField('id', {
          title: i18n.t('ID'),
          type: GeneralFormFields.INPUT_FIELD,
          fieldOptions: {},
        })
        .addField('date', {
          title: i18n.t('DATE'),
          type: GeneralFormFields.INPUT_FIELD,
          fieldOptions: {},
        })
        .addField('doctorName', {
          type: GeneralFormFields.INPUT_FIELD,
          title: i18n.t('DOCTOR'),
          fieldOptions: {},
        })
        .addField('doctorNotes', {
          type: GeneralFormFields.INPUT_FIELD,
          title: i18n.t('DOCTOR_NOTES'),
          fieldOptions: {},
        })
        .addField('patientName', {
          type: GeneralFormFields.INPUT_FIELD,
          title: i18n.t('PATIENT'),
          fieldOptions: {},
        })
        .addField('patientNotes', {
          type: GeneralFormFields.INPUT_FIELD,
          title: i18n.t('PATIENT_NOTES'),
          fieldOptions: {},
        })
        .addField('status', {
          type: GeneralFormFields.INPUT_FIELD,
          title: i18n.t('STATUS'),
          fieldOptions: {},
        })
        .addField('type', {
          type: GeneralFormFields.INPUT_FIELD,
          title: i18n.t('TYPE'),
          fieldOptions: {},
        })
        .addField('finishedAt', {
          type: GeneralFormFields.INPUT_FIELD,
          title: i18n.t('FINISHED_AT'),
          fieldOptions: {},
        })
        .addField('from', {
          type: GeneralFormFields.INPUT_FIELD,
          title: i18n.t('STARTED_AT'),
          fieldOptions: {},
        })
        .formFields}
      isFetchSuccessful
      update={async () => Promise.resolve()}
      create={async () => Promise.resolve(1)}
      viewMode
      get={async (id) => {
        const request = await getOne(id);
        return Promise.resolve(new RequestModelView(request));
      }}
    />
  );
};
export const RequestDetails = baseScreen(requestDetails, {
  allowedRoles: ['ADMIN', 'NONE'],
});
