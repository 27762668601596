import {
  DateTimeFormFieldOptions,
  FormFieldArrayOptions,
  GeneralFormFields,
  ImagePickerFieldOption,
  InputFieldOptions,
  LocationFormFieldOptions,
  NestedFormFieldOptions,
  RichTextFieldOptions,
  ScheduleFieldOptions,
  SelectFieldOptions,
  TextAreaFieldOptions,
  ToggleFormFieldOptions,
} from '../types';

export type FieldProps<T, K extends keyof T> = FieldPropsCommon & (
  InputFieldValue
  | ScheduleFieldValue
  | TextAreaFieldValue
  | ImagePickerFieldValue
  | NestedFormFieldValue<T, K>
  | ArrayFormFieldFieldValue<T, K>
  | SelectFieldValue<T, K>
  | RichTextFieldValue
  | DateTimeFieldValue
  | ToggleFieldValue
  | LocationFieldValue
);

export type FieldPropsTypes<T, K extends keyof T> = (
  (FieldPropsCommon & InputFieldValue)
  | (FieldPropsCommon & TextAreaFieldValue)
  | (FieldPropsCommon & ScheduleFieldValue)
  | (FieldPropsCommon & ImagePickerFieldValue)
  | (FieldPropsCommon & NestedFormFieldValue<T, K>)
  | (FieldPropsCommon & ArrayFormFieldFieldValue<T, K>)
  | (FieldPropsCommon & SelectFieldValue<T, K>)
  | (FieldPropsCommon & RichTextFieldValue)
  | (FieldPropsCommon & DateTimeFieldValue)
  | (FieldPropsCommon & ToggleFieldValue)
  | (FieldPropsCommon & LocationFieldValue)
);

export interface FieldPropsCommon {
  title: string;
  location: string;
  disabled?: boolean;
  viewMode?: boolean;
}

export interface InputFieldValue {
  type: GeneralFormFields.INPUT_FIELD;
  fieldOptions: InputFieldOptions;
}

export interface ScheduleFieldValue {
  type: GeneralFormFields.SCHEDULE;
  fieldOptions: ScheduleFieldOptions;
}

export function isInputProps<
  T,
  K extends keyof T,
  >(props: FieldProps<T, K>): props is FieldPropsCommon & InputFieldValue {
  return props.type === GeneralFormFields.INPUT_FIELD;
}

export interface TextAreaFieldValue {
  type: GeneralFormFields.TEXT_AREA;
  fieldOptions: TextAreaFieldOptions;
}

export function isTextAreaProps<
  T,
  K extends keyof T,
  >(props: FieldProps<T, K>): props is FieldPropsCommon & TextAreaFieldValue {
  return props.type === GeneralFormFields.TEXT_AREA;
}

export interface ImagePickerFieldValue {
  type: GeneralFormFields.FILE_PICKER;
  fieldOptions: ImagePickerFieldOption;
}

export function isImagePickerProps<
  T,
  K extends keyof T,
  >(props: FieldProps<T, K>): props is FieldPropsCommon & ImagePickerFieldValue {
  return props.type === GeneralFormFields.FILE_PICKER;
}

export interface NestedFormFieldValue<T, K extends keyof T> {
  type: GeneralFormFields.NESTED_FORM;
  fieldOptions: NestedFormFieldOptions<T[K]>;
}

export function isNestedFormProps<
  T,
  K extends keyof T,
  >(props: FieldProps<T, K>): props is FieldPropsCommon & NestedFormFieldValue<T, K> {
  return props.type === GeneralFormFields.NESTED_FORM;
}

export interface ArrayFormFieldFieldValue<T, K extends keyof T> {
  type: GeneralFormFields.FIELD_ARRAY;
  fieldOptions: FormFieldArrayOptions<T, K>;
}

export function isArrayProps<
  T,
  K extends keyof T,
  >(props: FieldProps<T, K>): props is FieldPropsCommon & ArrayFormFieldFieldValue<T, K> {
  return props.type === GeneralFormFields.FIELD_ARRAY;
}

export interface SelectFieldValue<T, K extends keyof T, V = T[K]> {
  type: GeneralFormFields.SELECT_FIELD;
  fieldOptions: SelectFieldOptions<V>;
}

export function isSelectProps<
  T,
  K extends keyof T,
  >(props: FieldProps<T, K>): props is FieldPropsCommon & SelectFieldValue<T, K> {
  return props.type === GeneralFormFields.SELECT_FIELD;
}

export interface RichTextFieldValue {
  type: GeneralFormFields.RICH_TEXT_FIELD;
  fieldOptions: RichTextFieldOptions;
}

export function isRichTextProps<
  T,
  K extends keyof T,
  >(props: FieldProps<T, K>): props is FieldPropsCommon & RichTextFieldValue {
  return props.type === GeneralFormFields.RICH_TEXT_FIELD;
}

export interface DateTimeFieldValue {
  type: GeneralFormFields.DATE_TIME_FIELD;
  fieldOptions: DateTimeFormFieldOptions;
}

export function isDateTimeProps<
  T,
  K extends keyof T,
  >(props: FieldProps<T, K>): props is FieldPropsCommon & DateTimeFieldValue {
  return props.type === GeneralFormFields.DATE_TIME_FIELD;
}

export interface ToggleFieldValue {
  type: GeneralFormFields.TOGGLE_FIELD;
  fieldOptions: ToggleFormFieldOptions;
}

export function isToggleFieldProps<
  T,
  K extends keyof T,
  >(props: FieldProps<T, K>): props is FieldPropsCommon & ToggleFieldValue {
  return props.type === GeneralFormFields.TOGGLE_FIELD;
}

export interface LocationFieldValue {
  type: GeneralFormFields.LOCATION_FIELD;
  fieldOptions: LocationFormFieldOptions;
}

export function isLocationProps<
  T,
  K extends keyof T,
  >(props: FieldProps<T, K>): props is FieldPropsCommon & LocationFieldValue {
  return props.type === GeneralFormFields.LOCATION_FIELD;
}

export function isScheduleProps<
  T,
  K extends keyof T,
  >(props: FieldProps<T, K>): props is FieldPropsCommon & ScheduleFieldValue {
  return props.type === GeneralFormFields.SCHEDULE;
}

// eslint-disable-next-line no-shadow
export enum FieldFileCommonTypes {
  IMAGE,
  VIDEO,
  PDF,
}

export type FieldFileTypes = FieldFileCommonTypes | 'other';

export interface RemoteFile {
  source: string;
  type: FieldFileTypes;
}

export interface LocalFile {
  file: File;
  type: FieldFileTypes;
}

export function isRemoteFile(fieldFile: FieldFile): fieldFile is RemoteFile {
  return !!(fieldFile as RemoteFile).source;
}

export type FieldFile = LocalFile | RemoteFile;
