import {
  ForgotPasswordDTO,
} from 'src/stores/backend/auth/types';
import {
  BaseFormViewModel,
} from '../../types';

export class ForgotPasswordViewModel extends BaseFormViewModel<ForgotPasswordDTO> {
  email: string = '';

  id?: number;

  constructor(dto: ForgotPasswordDTO) {
    super();
    const {
      email,
    } = dto;

    Object.assign(this, {
      email,
    });
  }

  toDTO() {
    return this as ForgotPasswordDTO;
  }
}
