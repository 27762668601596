import {
  getIn,
  useFormikContext,
} from 'formik';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import React, {
  useEffect,
  useState,
} from 'react';
import i18n from 'i18n-js';
import moment from 'moment';
import {
  FieldPropsCommon,
  ScheduleFieldValue,
} from '../types';
import {
  onChange,
} from '../utils';
import {
  days,
  ScheduleKeys,
  TableColumn,
} from './types';
import {
  Styles,
} from './styles';

type Props = FieldPropsCommon & ScheduleFieldValue;

const ScheduleField = <FormModel extends object>(props: Props) => {
  const {
    values,
    handleChange,
    isSubmitting,
    setFieldTouched,
    setFieldError,
  } = useFormikContext<FormModel>();

  const {
    title,
    location,
    fieldOptions: {
      disabled,
    },
  } = props;
  const useStyles = Styles;
  const classes = useStyles();
  const value: any = getIn(values, location);
  const onValueChange = onChange(handleChange, location);
  // const error = getIn(errors, location);
  // const isTouched = getIn(touched, location);
  useEffect(() => {
    if (isSubmitting) {
      setFieldTouched(location);
    }
  }, [isSubmitting, location, setFieldTouched]);

  if (!values) {
    return null;
  }
  const [
    weekDays,
    setWeekDays,
  ] = useState<TableColumn[]>([]);
  const [
    editibleValue,
    setEditibleValue,
  ] = useState({
    index: 0,
    key: '',
  });
  const [
    isValid,
    setValid,
  ] = useState(true);
  const validateSchedule = () => {
    let result = true;
    weekDays.forEach((element) => {
      const from = moment(element.from, 'HH:mm');
      const to = moment(element.to, 'HH:mm');
      const breakFrom = moment(element.breakFrom, 'HH:mm');
      const breakTo = moment(element.breakTo, 'HH:mm');
      const condition = from.isAfter(to)
      || ((breakFrom.isValid() && breakTo.isValid())
      && (breakFrom.isAfter(breakTo) || !breakFrom.isBetween(from, to) || !breakTo.isBetween(from, to)));
      if (condition) {
        result = false;
        setFieldError('schedule', 'invalid input');
      }
    });
    setValid(result);
    return result;
  };
  useEffect(() => {
    const load = async () => {
      if (value.length > 0) {
        await setWeekDays(value);
        validateSchedule();
      } else {
        setWeekDays(days.map((day) => ({
          day,
          from: moment('09:00', 'HH:mm').format('HH:mm'),
          isActive: true,
          breakFrom: '',
          breakTo: '',
          to: moment('18:00', 'HH:mm').format('HH:mm'),
        })));
      }
    };
    load();
  }, []);
  const onDoubleClick = (item: TableColumn, index: number, key: ScheduleKeys) => {
    if (!disabled) {
      setEditibleValue({
        index,
        key,
      });
    }
  };
  useEffect(() => {
    onValueChange(weekDays);
    validateSchedule();
  }, [weekDays]);
  const displayTimeElement = (item: TableColumn, key: ScheduleKeys, index: number) => {
    if (key === editibleValue.key && index === editibleValue.index) {
      return (
        <input
          type="time"
          value={item[key]}
          onChange={async (ev) => {
            await setWeekDays(weekDays.map((element) => {
              if (element.day === item.day) {
                return {
                  ...element,
                  [key]: ev.target.value,
                };
              }
              return element;
            }));
          }}
        />
      );
    }
    return (
      <div
        role="button"
        style={{
          minWidth: '100%',
          minHeight: 40,
          lineHeight: '40px',
        }}
        onDoubleClick={() => {
          onDoubleClick(item, index, key);
        }}
        onKeyDown={() => {
        }}
        tabIndex={0}
      >
        {item[key]?.slice(0, 5)}
      </div>
    );
  };

  return (
    <>
      <p className={classes.title}>{title}</p>
      <p className={classes.error}>
        {isValid ? '' : i18n.t('SCHEDULE_WRONG_ENTRY')}
      </p>
      <table className={classes.table}>
        <tr>
          <th className={classes.th}>Day</th>
          <th className={classes.th}>From</th>
          <th className={classes.th}>To</th>
          <th className={classes.th}>Break From</th>
          <th className={classes.th}>Break To</th>
          <th className={classes.th}>Active</th>
        </tr>
        {
        weekDays.map((item, index) => (
          <tr
            className={classes.tr}
            onBlur={() => {
              onValueChange(weekDays);
              setEditibleValue({
                index: 0,
                key: '',
              });
            }}
          >
            <td className={classes.td}>{item.day}</td>
            <td
              className={classes.td}
            >
              {
                  displayTimeElement(item, ScheduleKeys.FROM, index)
                }
            </td>
            <td className={classes.td}>
              {
                displayTimeElement(item, ScheduleKeys.TO, index)
              }
            </td>
            <td className={classes.td}>
              {
                  displayTimeElement(item, ScheduleKeys.BREAK_FROM, index)
                }
            </td>
            <td className={classes.td}>
              {
                  displayTimeElement(item, ScheduleKeys.BREAK_TO, index)
                }
            </td>
            <td className={classes.td}>
              <div
                role="button"
                onClick={() => {
                  if (!disabled) {
                    setWeekDays(weekDays.map((element) => {
                      if (element.day === item.day) {
                        return {
                          ...item,
                          isActive: !item.isActive,
                        };
                      }
                      return element;
                    }));
                  }
                }}
                onKeyDown={() => {
                }}
                tabIndex={0}
              >
                {
              item.isActive ? (<CheckCircleIcon />) : (<RadioButtonUncheckedIcon />)
            }
              </div>
            </td>
          </tr>
        ))
      }
      </table>
    </>
  );
};

export default ScheduleField;
