import React from 'react';
import {
  Route,
  Switch,
  Redirect,
  withRouter,
} from 'react-router-dom';
import {
  LoginScreen,
  HelpCenter,
  ForgotPassword,
  ResetPassword,
  RegisterScreen,
} from 'screens';

const AuthRoutes = () => (
  <>
    <Switch>
      <Route exact key="login" path="/" component={LoginScreen} />
      <Route exact key="register" path="/register" component={RegisterScreen} />
      <Route exact key="forgot-password" path="/forgot-password" component={ForgotPassword} />
      <Route exact key="reset-password" path="/reset-password" component={ResetPassword} />
      <Route exact key="help-center" path="/help-center" component={HelpCenter} />
      <Redirect to="/" />
    </Switch>
  </>
);

export default withRouter(AuthRoutes);
