import {
  FieldFile,
  FieldFileCommonTypes,
  RemoteFile,
} from 'src/components/GeneralFormView/FormField/types';
import {
  MapLocation,
} from 'src/components/GeneralFormView/types';
import {
  BaseFormViewModel,
} from '../types';
import {
  User,
} from '../doctors/types';

export class ProfileFormViewModel extends BaseFormViewModel<User> {
  id?: number;

  name: string;

  type: string;

  avatar: RemoteFile[];

  email: string;

  phone: string;

  landlineNumber: string;

  website: string;

  password: string;

  confirmPassword: string;

  location: MapLocation;

  commercialRecord: FieldFile[];

  certificate: FieldFile[];

  healthInsuranceCompanies: RemoteFile[];

  taxId: RemoteFile[];

  signupCode: string;

  constructor(dto: User) {
    super();
    this.avatar = [{
      source: dto.avatar,
      type: FieldFileCommonTypes.IMAGE,
    }];
    this.name = dto.name;
    this.type = dto.serviceProvider.serviceProviderType.title;
    this.email = dto.email;
    this.phone = dto.phone;
    this.landlineNumber = dto.serviceProvider.landlinePhone;
    this.website = dto.serviceProvider.website;
    this.location = dto.serviceProvider.location;
    this.signupCode =dto.serviceProvider.signupCode
    this.commercialRecord = dto.serviceProvider.commercialRecord ? [{
      source: dto.serviceProvider.commercialRecord,
      type: FieldFileCommonTypes.IMAGE,
    }] : [];
    this.certificate = dto.serviceProvider.docs?.map((doc) => ({
      source: doc,
      type: FieldFileCommonTypes.IMAGE,
    }));
    this.taxId = dto.serviceProvider.taxId ? [{
      source: dto.serviceProvider.taxId,
      type: FieldFileCommonTypes.IMAGE,
    }] : [];
    this.healthInsuranceCompanies = [];
  }

  public async toDTO() {
    return {} as User;
  }
}

export class ServiceProviderType {
  id: number;

  title: string;
}
