export const verifyPhone = '/auth/phone-verification';
export const loginByCode = '/auth/login';
export const registerByCode = '/auth/registration';
export const refresh = '/auth/refresh';
export const sendNationalId = '/auth/nationalId-verification';
export const loginURL = 'auth/admin/login';
export const currentUserURL = 'auth/me';
export const serviceProviders = '/service-providers';
export const changePassword = 'auth/password';
export const forgetPassword = '/auth/service-provider/forgot-password';
export const resetPassword = '/auth/service-provider/reset-password';
