import {
  useObserver,
} from 'mobx-react';
import React from 'react';
import * as yup from 'yup';
import {
  getIn,
} from 'formik';
import TestStore from 'src/stores/backend/TestStore';
import {
  LoadingState,
} from 'utils';
import {
  baseScreen,
} from 'hoc';
import GeneralFormView from '../../components/GeneralFormView';
import GeneralFormFields, {
  FormFieldActions,
  DateTimeFormFieldTypes,
} from '../../components/GeneralFormView/types';
import Condition, {
  ConditionStatement,
  ConditionalStatementTypes,
  ConditionExpression,
} from '../../components/GeneralFormView/Utils/Condition';
import {
  TestFormViewModel,
} from './types';
import {
  FormFieldsBuilder,
} from '../../components/GeneralFormView/Utils/FormFieldsBuilder';
import {
  FieldFile,
} from '../../components/GeneralFormView/FormField/types';

const testScreen : React.FC = () => {
  const previewComponent: React.FC<TestFormViewModel> = (props) => (
    <div>
      {JSON.stringify(props)}
    </div>
  );
  const nestedForm3 = new FormFieldsBuilder<TestFormViewModel, TestFormViewModel['miniMiniForm'][number]>()
    .addField('miniTitle1', {
      title: 'Mini Title 1',
      type: GeneralFormFields.INPUT_FIELD,
      hasTranslations: true,
      initialValue: '',
      fieldOptions: {},
    })
    .addField('miniTitle2', {
      title: 'Mini Title 2',
      type: GeneralFormFields.INPUT_FIELD,
      hasTranslations: true,
      initialValue: '',
      fieldOptions: {},
      condition: (values, actions, location) => new Condition({
        values,
        initialStatement: new ConditionStatement({
          action: actions[FormFieldActions.HIDE],
          type: ConditionalStatementTypes.IF,
          expression: new ConditionExpression({
            values,
            expression: (val) => {
              const value = getIn(val, location.split('.').slice(0, -1).join('.')).miniTitle1;
              if (value === 'hamada') {
                return true;
              }
              return false;
            },
          }),
        }),
      }).addElse(new ConditionStatement({
        action: actions[FormFieldActions.ENABLE],
        type: ConditionalStatementTypes.ELSE,
        expression: new ConditionExpression({
          values,
          expression: (val) => val.description === 'bbbb',
        }),
      })),
    })
    .addField('images', {
      hasTranslations: true,
      title: 'Image',
      type: GeneralFormFields.FILE_PICKER,
      fieldOptions: {
        size: 200,
        accept: 'image/*',
        multipleImages: false,
      },
    })
    .addPreviewComponent(previewComponent);
  const nestedForm2 = new FormFieldsBuilder<TestFormViewModel, TestFormViewModel['miniForm']['miniForm']>()
    .addPreviewComponent(previewComponent)
    .addField('title1', {
      title: 'Mini Title 1',
      type: GeneralFormFields.INPUT_FIELD,
      hasTranslations: true,
      initialValue: '',
      fieldOptions: {},
    })
    .addField('title2', {
      title: 'Mini Title 2',
      type: GeneralFormFields.INPUT_FIELD,
      hasTranslations: true,
      initialValue: '',
      fieldOptions: {},
    })
    .addField('images', {
      hasTranslations: true,
      title: 'Image',
      type: GeneralFormFields.FILE_PICKER,
      fieldOptions: {
        size: 200,
        accept: 'image/*',
        multipleImages: false,
      },
    });
  const nestedForm = new FormFieldsBuilder<TestFormViewModel, TestFormViewModel['miniForm']>()
    .addField('title1', {
      title: 'Mini Title 1',
      type: GeneralFormFields.INPUT_FIELD,
      hasTranslations: true,
      initialValue: '',
      fieldOptions: {},
    })
    .addField('title2', {
      title: 'Mini Title 2',
      type: GeneralFormFields.INPUT_FIELD,
      hasTranslations: true,
      initialValue: '',
      fieldOptions: {},
    })
    .addField('images', {
      hasTranslations: true,
      title: 'Image',
      type: GeneralFormFields.FILE_PICKER,
      fieldOptions: {
        size: 200,
        accept: 'image/*',
        multipleImages: false,
      },
    })
    .addPreviewComponent(previewComponent)
    .addField('miniForm', {
      title: 'Mini Form',
      type: GeneralFormFields.NESTED_FORM,
      fieldOptions: {
        formData: nestedForm2.formFields,
      },
    });
  const {
    formFields,
  } = new FormFieldsBuilder<TestFormViewModel>()
    .addField('title', {
      fieldOptions: {},
      hasTranslations: true,
      title: 'Title',
      type: GeneralFormFields.INPUT_FIELD,
      condition: (values, actions) => new Condition({
        values,
        initialStatement: new ConditionStatement({
          action: actions[FormFieldActions.DISABLE],
          type: ConditionalStatementTypes.IF,
          expression: new ConditionExpression({
            values,
            expression: (val) => val.description === 'hamada',
          }),
        }),
      }).addElse(new ConditionStatement({
        action: actions[FormFieldActions.ENABLE],
        type: ConditionalStatementTypes.ELSE,
        expression: new ConditionExpression({
          values,
          expression: (val) => val.description === 'bbbb',
        }),
      })),
    })
    .addField('description', {
      fieldOptions: {},
      hasTranslations: true,
      title: 'Description',
      type: GeneralFormFields.TEXT_AREA,
    })
    .addPreviewComponent(previewComponent)
    .addField('image', {
      hasTranslations: true,
      title: 'Image',
      type: GeneralFormFields.FILE_PICKER,
      fieldOptions: {
        size: 200,
        accept: 'image/*',
        multipleImages: false,
      },
    })
    .addField('miniForm', {
      hasTranslations: true,
      title: 'Mini Form',
      type: GeneralFormFields.NESTED_FORM,
      initialValue: {
        title1: '',
        title2: '',
        images: [],
        miniForm: {
          title1: '',
          title2: '',
          images: [],
        },
      },
      validationSchema: yup.object({
        title1: yup.string().required(),
        title2: yup.string().required(),
        images: yup.array<FieldFile>().required(),
        miniForm: yup.object({
          title1: yup.string().required(),
          title2: yup.string().required(),
          images: yup.array<FieldFile>().required(),
        }).required(),
      }).required().strict() as any,
      fieldOptions: {
        formData: nestedForm.formFields,
      },
    })
    .addField('miniMiniForm', {
      title: 'Mini Mini Form Array',
      type: GeneralFormFields.FIELD_ARRAY,
      hasTranslations: true,
      fieldOptions: {
        formData: {
          title: '',
          type: GeneralFormFields.NESTED_FORM,
          fieldOptions: {
            formData: nestedForm3.formFields,
          },
        },
      },
    })
    .addField('persons', {
      hasTranslations: true,
      title: 'Persons',
      type: GeneralFormFields.SELECT_FIELD,
      fieldOptions: {
        isMultiple: true,
        items: new Promise((res) => {
          setTimeout(() => {
            res([
              {
                title: 'Person 1',
                value: 'person1',
              },
              {
                title: 'Person 2',
                value: 'person2',
              },
              {
                title: 'Person 3',
                value: 'person3',
              },
              {
                title: 'Person 4',
                value: 'person4',
              },
              {
                title: 'Person 5',
                value: 'person5',
              },
              {
                title: 'Person 6',
                value: 'person6',
              },
            ]);
          }, 5000);
        }),
      },
    })
    .addField('person', {
      hasTranslations: true,
      title: 'Person',
      type: GeneralFormFields.SELECT_FIELD,
      fieldOptions: {
        isMultiple: false,
        items: [
          {
            title: 'Person 1',
            value: 'person1',
          },
          {
            title: 'Person 2',
            value: 'person2',
          },
          {
            title: 'Person 3',
            value: 'person3',
          },
          {
            title: 'Person 4',
            value: 'person4',
          },
          {
            title: 'Person 5',
            value: 'person5',
          },
          {
            title: 'Person 6',
            value: 'person6',
          },
        ],
      },
    })
    .addField('richDescription', {
      hasTranslations: true,
      title: 'Rich Description',
      type: GeneralFormFields.RICH_TEXT_FIELD,
      fieldOptions: {},
    })
    .addField('date', {
      hasTranslations: true,
      title: 'Date',
      type: GeneralFormFields.DATE_TIME_FIELD,
      fieldOptions: {
        type: DateTimeFormFieldTypes.DATE,
      },
    })
    .addField('time', {
      hasTranslations: true,
      title: 'Time',
      type: GeneralFormFields.DATE_TIME_FIELD,
      fieldOptions: {
        type: DateTimeFormFieldTypes.TIME,
      },
    })
    .addField('dateTime', {
      hasTranslations: true,
      title: 'Date Time',
      type: GeneralFormFields.DATE_TIME_FIELD,
      fieldOptions: {
        type: DateTimeFormFieldTypes.DATE_TIME,
      },
    })
    .addField('isActive', {
      hasTranslations: false,
      title: 'Active',
      type: GeneralFormFields.TOGGLE_FIELD,
      fieldOptions: {},
    })
    .addField('location', {
      fieldOptions: {},
      hasTranslations: false,
      title: 'Location',
      type: GeneralFormFields.LOCATION_FIELD,
    });
  return useObserver(() => (
    <GeneralFormView<any, TestFormViewModel>
      defaultLang="ar"
      title="Test Entity"
      identifier="id"
      otherLanguages={['en']}
      formData={formFields}
      create={async (formValue) => {
        const res = await TestStore.create(await formValue.toDTO());
        return res;
      }}
      update={async () => {
        const res = await TestStore.update();
        return res;
      }}
      get={async (id) => {
        await TestStore.getOne(id);
        return new TestFormViewModel(TestStore.selectedEntity.data as any);
      }}
      isFetchSuccessful={TestStore.selectedEntity.loadingState === LoadingState.SUCCEEDED}
    />
  ));
};

export const TestScreen = baseScreen(testScreen, {
  allowedRoles: ['ADMIN', 'NONE'],
});
