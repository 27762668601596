import {
  createStyles,
  makeStyles,
} from '@material-ui/core';

export const useStyles = makeStyles(() => createStyles({
  container: {
    width: 'fit-content',
    background: '#FFF',
    marginTop: 130,
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingRight: 20,
    paddingLeft: 20,
    paddingTop: 10,
    paddingBottom: 10,
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  icon: {
    marginTop: 7,
    marginRight: 10,
  },
  msg: {
    lineHeight: 2.6,
  },
  btn: {
    alignSelf: 'center',
    marginTop: 10,
    marginBottom: 10,
  },
}));
