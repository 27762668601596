/* eslint-disable max-classes-per-file */
import React,
{
  useState,
} from 'react';
import {
  useStores,
} from 'hooks';
import {
  baseScreen,
} from 'hoc';
import {
  Button,
  createStyles,
  makeStyles,
  Modal,
  Tab,
  Tabs,
  Typography,
} from '@material-ui/core';
import {
  Settings,
} from 'settings';
import GeneralFormView from 'src/components/GeneralFormView';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import {
  FormFieldsBuilder,
} from 'src/components/GeneralFormView/Utils/FormFieldsBuilder';
import GeneralFormFields from 'src/components/GeneralFormView/types';
import * as Yup from 'yup';
import {
  useHistory,
} from 'react-router';
import i18n from 'i18n-js';
import {
  Assets,
} from 'assets';
import {
  CountryCodes,
} from 'src/utils';
import {
  RequestCallModelView,
} from './types';
import {
  ONLY_LETTERS_REGEX,
  ONLY_NUMBERS_REGEX,
} from '../utils';
import FAQs from './faqs';

const useStyles = makeStyles(() => createStyles({
  container: {
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  tabs: {
    display: 'flex',
    alignSelf: 'center',
  },
  content: {
    width: '80%',
    alignSelf: 'center',
    minHeight: '87vh',
  },
  liveChat: {
    position: 'absolute',
    bottom: 45,
    right: 48,
  },
  modalContainer: {
    width: 'fit-content',
    background: '#FFF',
    marginTop: 130,
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingRight: 20,
    paddingLeft: 20,
    paddingTop: 10,
    paddingBottom: 10,
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  icon: {
    marginTop: 7,
    marginRight: 10,
  },
  msg: {
    lineHeight: 2.6,
    marginLeft: 16,
    marginRight: 16,
  },
  btn: {
    alignSelf: 'center',
    marginTop: 10,
    marginBottom: 10,
  },
}));
const helpCenter: React.FC = () => {
  const history = useHistory();
  const classes = useStyles();
  const {
    backend: {
      helpCenterStore,
    },
  } = useStores();
  const [tabIndex, setTabIndex] = useState(0);
  const [
    modalOpened,
    setModalOpened,
  ] = useState(false);
  const onTabChange = (ev: React.ChangeEvent<{}>, value: number) => {
    setTabIndex(value);
  };
  return (
    <div
      className={classes.container}
    >
      <Tabs
        className={classes.tabs}
        value={tabIndex}
        onChange={onTabChange}
        indicatorColor="primary"
      >
        <Tab label={i18n.t('REQUEST_A_CALL')} />
        <Tab label={i18n.t('SEND_FEEDBACK')} />
        <Tab label={i18n.t('FAQS_TITLE')} />
      </Tabs>
      <div
        className={classes.content}
      >
        {tabIndex === 0 && (

          <GeneralFormView<any, RequestCallModelView>
            title={i18n.t('REQUEST_CALL')}
            identifier="id"
            defaultLang="en"
            otherLanguages={[]}
            formData={new FormFieldsBuilder<RequestCallModelView>()
              .addField('name', {
                title: i18n.t('NAME'),
                type: GeneralFormFields.INPUT_FIELD,
                validationSchema: Yup.string().matches(ONLY_LETTERS_REGEX, i18n.t('ACCEPTS_LETTERS_ONLY'))
                  .required(i18n.t('REQUIRED_FIELD')) as any,
                fieldOptions: {},
              })
              .addField('email', {
                title: i18n.t('EMAIL'),
                type: GeneralFormFields.INPUT_FIELD,
                validationSchema: Yup.string().email(i18n.t('INVALID_FIELD')).notRequired() as any,
                fieldOptions: {},
              })
              .addField('countryCode', {
                title: i18n.t('COUNTRY_CODE'),
                type: GeneralFormFields.SELECT_FIELD,
                initialValue: ['+20'],
                validationSchema: Yup.array().length(1, i18n.t('REQUIRED_FIELD')) as any,
                fieldOptions: {
                  isMultiple: false,
                  items: CountryCodes.map((item) => ({
                    title: item.name,
                    value: item.dial_code as string,
                  })),
                },
              })
              .addField('mobileNumber', {
                title: i18n.t('MOBILE_NUMBER'),
                type: GeneralFormFields.INPUT_FIELD,
                validationSchema: Yup.string().matches(ONLY_NUMBERS_REGEX, i18n.t('INVALID_PHONE'))
                  .length(10, i18n.t('INVALID_PHONE')).required(i18n.t('REQUIRED_FIELD')) as any,
                fieldOptions: {},
              })
              .addField('message', {
                title: i18n.t('MESSAGE'),
                type: GeneralFormFields.TEXT_AREA,
                validationSchema: Yup.string().required(i18n.t('REQUIRED_FIELD')) as any,
                fieldOptions: {
                  maxLength: 800,
                },
              })
              .formFields}
            isFetchSuccessful
            update={async () => Promise.resolve()}
            create={async (value) => {
              const data = new RequestCallModelView({
                name: value.name,
                email: value.email,
                message: value.message,
                countryCode: value.countryCode,
                mobileNumber: value.mobileNumber,
              } as any).toDTO();
              await helpCenterStore.createCallRequest(data);
              setModalOpened(true);
              return Promise.resolve(1);
            }}
            get={async () => Promise.resolve({} as any)}
          />

        )}
        {tabIndex === 1 && (
        <GeneralFormView<any, RequestCallModelView>
          title={i18n.t('FEEDBACK')}
          identifier="id"
          defaultLang="en"
          otherLanguages={[]}
          formData={new FormFieldsBuilder<RequestCallModelView>()
            .addField('message', {
              type: GeneralFormFields.TEXT_AREA,
              title: i18n.t('FEEDBACK'),
              validationSchema: Yup.string().required(i18n.t('REQUIRED_FIELD')) as any,
              fieldOptions: {
                maxLength: 800,
              },
            })
            .addField('name', {
              title: i18n.t('NAME'),
              type: GeneralFormFields.INPUT_FIELD,
              validationSchema: Yup.string().matches(ONLY_LETTERS_REGEX, i18n.t('ACCEPTS_LETTERS_ONLY'))
                .required(i18n.t('REQUIRED_FIELD')) as any,
              fieldOptions: {},
            })
            .addField('email', {
              title: i18n.t('EMAIL'),
              type: GeneralFormFields.INPUT_FIELD,
              validationSchema: Yup.string().email(i18n.t('INVALID_FIELD')).notRequired() as any,
              fieldOptions: {},
            })
            .addField('countryCode', {
              title: i18n.t('COUNTRY_CODE'),
              initialValue: ['+20'],
              type: GeneralFormFields.SELECT_FIELD,
              validationSchema: Yup.array().length(1, i18n.t('REQUIRED_FIELD')) as any,
              fieldOptions: {
                isMultiple: false,
                items: CountryCodes.map((item) => ({
                  title: item.name,
                  value: item.dial_code as string,
                })),
              },
            })
            .addField('mobileNumber', {
              title: i18n.t('MOBILE_NUMBER'),
              type: GeneralFormFields.INPUT_FIELD,
              validationSchema: Yup.string().matches(ONLY_NUMBERS_REGEX, i18n.t('INVALID_PHONE'))
                .length(10, i18n.t('INVALID_PHONE')).required(i18n.t('REQUIRED_FIELD')) as any,
              fieldOptions: {},
            })
            .formFields}
          isFetchSuccessful
          update={async () => Promise.resolve()}
          create={async (value) => {
            const data = new RequestCallModelView({
              name: value.name,
              email: value.email,
              message: value.message,
              countryCode: value.countryCode,
              mobileNumber: value.mobileNumber,
            } as any).toDTO();
            await helpCenterStore.createFeedback(data);
            setModalOpened(true);
            return Promise.resolve(1);
          }}
          get={async () => Promise.resolve({} as any)}
        />
        )}

        {tabIndex === 2 && <FAQs />}
        <Modal
          open={modalOpened}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div
            className={classes.modalContainer}
          >
            <div style={{
              flexDirection: 'row',
              display: 'flex',
            }}
            >
              <CheckCircleOutlineIcon color="primary" fontSize="large" className={classes.icon} />
              <Typography variant="h6" className={classes.msg}>
                {i18n.t('MESSAGE_RECEIVED_SUCCESSFULLY')}
              </Typography>
            </div>
            <Button
              variant="outlined"
              color="primary"
              className={classes.btn}
              onClick={() => {
                history.push('/');
              }}
            >
              {i18n.t('DONE')}
            </Button>
          </div>

        </Modal>
      </div>
      <a
        href={`${Settings.config.REACT_APP_WHATSUP_URL}`}
        target="_blank"
        rel="noreferrer"
        role="button"
        className={classes.liveChat}
      >
        <img
          style={{
            width: 60,
          }}
          src={Assets.images.common.whatsup}
          alt="whatsup"
        />
      </a>
    </div>
  );
};
export const HelpCenter = baseScreen(helpCenter, {
  allowedRoles: ['ADMIN', 'NONE'],
});
