/* eslint-disable max-classes-per-file */
import React,
{
  useRef,
} from 'react';
import {
  useStores,
} from 'hooks';
import {
  toJS,
} from 'mobx';
import {
  Assets,
} from 'assets';
import {
  baseScreen,
} from 'hoc';
import {
  RequestTable,
  Request,
} from 'src/stores/backend/RequestsStore/types';
import SearchFilterDropdown from 'src/components/SearchFilterComponent';
import GeneralTableView from 'src/components/GeneralTableView';
import i18n from 'i18n-js';
import {
  FilterTypes,
  TableColumnTypes,
} from 'src/components/GeneralTableView/types';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import {
  useHistory,
  useParams,
} from 'react-router';
import moment from 'moment';

const requests: React.FC = () => {
  const {
    backend: {
      requests: requestsData,
    },
  } = useStores();
  const ref = useRef<any|null>(null);
  const history = useHistory();
  const params = useParams();

  return (
    <div>
      <SearchFilterDropdown
        label={i18n.t('TYPE')}
        style={{
          margin: 16,
        }}
        searchFilterDropdown={{
          filters: [
            {
              name: i18n.t('ALL'),
              value: 'all',
            },
            {
              name: i18n.t('HOME_VISITS'),
              value: 'Home Visit',
            }, {
              name: i18n.t('ONLINE_CONSULTATION'),
              value: 'Online Consultation',
            },
            {
              name: i18n.t('CLINICS'),
              value: 'Clinic',
            },
          ],
          onChange: (val) => {
            const {
              filter,
            } = requestsData;
            if (val === 'all') {
              const newFilter = {
                ...filter,
                filters: filter.filters?.filter((item) => item.by !== 'consultation_type'),
              };
              requestsData.updateFilter(newFilter);
            } else {
              requestsData.updateFilter({
                ...filter,
                filters: [...filter.filters as any, {
                  by: 'consultation_type',
                  value: val,
                  type: 'FIXED',
                }],
              });
            }
            ref.current?.onQueryChange();
          },
          value: 'all',
          initialValue: 'all',
        }}
      />

      <GeneralTableView<RequestTable>
        tableProps={{
          tableRef: ref,
          title: i18n.t('SERVICES'),
          actions: [
            {
              icon: () => (
                <img
                  src={Assets.images.common.eye}
                  alt={i18n.t('VIEW')}
                />
              ),
              onClick: async (event, data) => {
                const request = data as RequestTable;
                history.push(`/service-details/view/${request.id}`);
              },
            },
            {
              isFreeAction: true,
              icon: ImportExportIcon,
              onClick: () => {
                window.open(requestsData.download(), '_blanck');
              },
              tooltip: i18n.t('EXPORT_DATA'),
            },
          ],
        }}
        options={{
          search: true,
          pageSize: 10,
          actionsColumnIndex: 10,
        }}
        get={async (query) => {
          const {
            date,
          } = params as any;
          if (date && moment(date).isValid()) {
            await requestsData.updateFilter({
              ...requestsData.filter,
              filters: [
                ...requestsData.filter.filters || [],
                {
                  by: 'date',
                  min: moment(date, 'DD-MM-yyyy').format('YYYY-MM-DD'),
                  max: moment(date, 'DD-MM-yyyy').format('YYYY-MM-DD'),
                  type: 'RANGE',
                },
              ],
            });
          }
          await requestsData.entityList.updateOptions(query);
          return {
            data: toJS(requestsData.entityList.data).map((item) => Request.toTableData(item)),
            totalCount: requestsData.entityList.meta.itemCount,
            page: requestsData.entityList.meta.page - 1,
          };
        }}
        tableColumns={[
          {
            field: 'id',
            title: i18n.t('ID'),
            type: TableColumnTypes.NUMERIC,
            filterType: FilterTypes.VALUE,
          },
          {
            field: 'consultation_type',
            title: i18n.t('TYPE'),
            type: TableColumnTypes.STRING,
            filterType: FilterTypes.VALUE,
          },
          {
            field: 'date',
            title: i18n.t('DATE'),
            type: TableColumnTypes.STRING,
          },
          {
            field: 'time',
            title: i18n.t('TIME'),
            type: TableColumnTypes.STRING,
          },
          {
            field: 'status',
            title: i18n.t('STATUS'),
            type: TableColumnTypes.STRING,
          },
          {
            field: 'doctor',
            title: i18n.t('DOCTOR'),
            type: TableColumnTypes.STRING,
            filterType: FilterTypes.VALUE,
          },
          {
            field: 'doctorMobileNumber',
            title: i18n.t('DOCTOR_PHONE'),
            type: TableColumnTypes.STRING,
            filterType: FilterTypes.VALUE,
          },
          {
            field: 'patient',
            title: i18n.t('PATIENT'),
            type: TableColumnTypes.STRING,
            filterType: FilterTypes.VALUE,
          },
          {
            field: 'patientMobileNumber',
            title: i18n.t('PATIENT_PHONE'),
            type: TableColumnTypes.STRING,
            filterType: FilterTypes.VALUE,
          },
        ]}
      />
    </div>
  );
};

export const Requests = baseScreen(requests, {
  allowedRoles: ['NONE', 'ADMIN'],
});
