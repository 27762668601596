import {
  FormControlLabel,
  Switch,
  Checkbox,
} from '@material-ui/core';
import {
  getIn,
  useFormikContext,
} from 'formik';
import React, {
  useEffect,
} from 'react';
import FieldError from '../../Utils/FieldError';
import {
  FieldPropsCommon,
  ToggleFieldValue,
} from '../types';
import {
  onChange,
} from '../utils';
import {
  FieldView,
} from '../../Utils/FieldView';

type Props = FieldPropsCommon & ToggleFieldValue;

const ToggleField = <FormModel extends object>(
  props: Props,
) => {
  const {
    values,
    handleChange,
    errors,
    touched,
    setFieldTouched,
    isSubmitting,
  } = useFormikContext<FormModel>();

  const {
    viewMode,
    fieldOptions,
    disabled,
    title,
    location,
  } = props;

  const value: boolean = getIn(values, location);
  const onValueChange = onChange(handleChange, location);
  const error = getIn(errors, location);
  const isTouched = getIn(touched, location);

  useEffect(() => {
    if (isSubmitting) {
      setFieldTouched(location);
    }
  }, [isSubmitting, location, setFieldTouched]);
  if (viewMode) {
    return (
      <FieldView
        marginTop={0}
        title={title}
        value={() => (
          <Checkbox
            disabled
            checked={value}
          />
        )}
      />
    );
  }

  return (
    <>
      <div
        style={{
          margin: 8,
        }}
      >
        <FormControlLabel
          control={(
            <Switch
              color="primary"
              {...(fieldOptions.switchProps || {})}
              disabled={disabled}
              checked={value}
              onBlur={() => setFieldTouched(location)}
              onChange={(newValue) => {
                onValueChange(newValue.target.checked);
              }}
            />
)}
          label={title}
          labelPlacement="start"
          style={{
            marginLeft: 0,
          }}
        />
      </div>
      <FieldError
        errors={error}
        touched={isTouched}
      />
    </>
  );
};

export default ToggleField;
