export type Json =
  | string
  | number
  | boolean
  | null
  | { [property: string]: Json }
  | Json[];

export enum Genders {
  MALE = 'male',
  FEMALE = 'female',
  OTHER = 'other',
}
