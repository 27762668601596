import React,
{
  useState,
} from 'react';
import {
  makeStyles,
} from '@material-ui/core/styles';
import {
  useStores,
} from 'hooks';
import * as Yup from 'yup';
import {
  useHistory,
  useLocation,
} from 'react-router-dom';
import {
  baseScreen,
} from 'hoc';
import i18n from 'i18n-js';
import GeneralFormView from 'src/components/GeneralFormView';
import {
  FormFieldsBuilder,
} from 'src/components/GeneralFormView/Utils/FormFieldsBuilder';
import GeneralFormFields from 'src/components/GeneralFormView/types';
import {
  Modal,
} from '@material-ui/core';
import {
  ResetPasswordViewModel,
} from './types';
import {
  ResetPasswordModal,
} from '../components/resetPasswordModal';

const useStyles = makeStyles({
  container: {
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 50,
    paddingRight: '10%',
    paddingLeft: '10%',
  },
});
const resetPassword: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token');
  const {
    backend: {
      auth,
    },
  } = useStores();
  const [
    modalOpened,
    setModalOpened,
  ] = useState(false);
  const [
    responseMode,
    setResponseMode,
  ] = useState<'success' | 'error'>('success');
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <GeneralFormView<any, ResetPasswordViewModel>
        title=""
        identifier="password"
        defaultLang="en"
        otherLanguages={[]}
        formData={new FormFieldsBuilder<ResetPasswordViewModel>()
          .addField('password', {
            title: i18n.t('PASSWORD'),
            type: GeneralFormFields.INPUT_FIELD,
            validationSchema: Yup.string().min(8, i18n.t('PASSWORD_LENGTH_ERROR'))
              .required(i18n.t('REQUIRED_FIELD')) as any,
            fieldOptions: {
              type: 'password',
            },
          })
          .addField('confirmPassword', {
            title: i18n.t('CONFIRM_PASSWORD'),
            type: GeneralFormFields.INPUT_FIELD,
            validationSchema: Yup.string().oneOf([Yup.ref('password')], i18n.t('PASSWORD_MUST_MATCH'))
              .required(i18n.t('REQUIRED_FIELD')),
            fieldOptions: {
              type: 'password',
            },
          })
          .formFields}
        isFetchSuccessful
        update={async () => Promise.resolve()}
        create={async (value) => {
          const data = new ResetPasswordViewModel({
            password: value.password,
            token,
          } as any).toDTO();
          try {
            await auth.resetPassword(data);
            setResponseMode('success');
          } catch (error) {
            setResponseMode('error');
          } finally {
            setModalOpened(true);
          }
          return Promise.resolve(1);
        }}
        get={async () => Promise.resolve({} as any)}
      />
      <Modal
        open={modalOpened}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <ResetPasswordModal
          mode={responseMode}
          onDoneClick={() => {
            if (responseMode === 'success') {
              history.goBack();
            } else {
              setModalOpened(false);
            }
          }}
        />
      </Modal>

    </div>
  );
};

export const ResetPassword = baseScreen(resetPassword, {
  allowedRoles: ['ADMIN', 'NONE'],
});
