import React,
{
  useEffect,
  useState,
} from 'react';
import {
  AppBar,
  IconButton,
  makeStyles,
  Toolbar,
  Typography,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import {
  ArrowDropDown,
  ArrowDropUp,
} from '@material-ui/icons';
import {
  useTheme,
  Theme,
  createStyles,
  createMuiTheme,
  ThemeProvider,
  jssPreset,
  StylesProvider,
} from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import {
  Settings,
} from 'settings';

import Hidden from '@material-ui/core/Hidden';
import {
  Switch,
  useHistory,
} from 'react-router';
import {
  BrowserRouter,
  Route as BrowserRoute,
} from 'react-router-dom';
import {
  create,
} from 'jss';
import rtl from 'jss-rtl';
import {
  useLocalization,
  useStores,
} from 'hooks';
import {
  LANGUAGES,
  Language,
} from 'shared';
import {
  observer,
} from 'mobx-react';
import {
  Assets,
} from 'assets';
import Spinner from 'src/components/Spinner';
import i18n from 'i18n-js';
import {
  HeaderMenu,
  HeaderLanguages,
} from 'components';
import {
  AuthFooter,
} from 'src/screens/authScreens/components/AuthFooter';
import {
  getMessaging,
  getToken,
  onMessage,
} from 'firebase/messaging';
import {
  useSnackbar,
} from 'notistack';
import {
  ModuleTypes,
  Route,
  SideNavItem,
} from './types';
import {
  Module,
} from './modules';
import {
  RootRoute,
} from './routes';
import AuthRoutes from './authNavigator';

const getSideNavItem = (rootRoute: Route<ModuleTypes>, items: SideNavItem): SideNavItem => {
  if (rootRoute.config.parent && !rootRoute.config.sideNavConfig) {
    return items;
  }
  const sideNavChildren = rootRoute.children.filter((child) => child.config.sideNavConfig);
  const navItem: SideNavItem = {
    name: i18n.t(rootRoute.config.name),
    icon: rootRoute.config.sideNavConfig?.icon,
    path: rootRoute.getPath(),
    children: sideNavChildren.length && rootRoute.config.type === ModuleTypes.CONTAINER
      ? sideNavChildren.map((child) => getSideNavItem(child, items))
      : undefined,
  };
  return navItem;
};

const flattenRoutes = (rootRoute: Route<ModuleTypes>, routes: Route<ModuleTypes>[]): Route<ModuleTypes>[] => {
  let newRoutes = [...routes, rootRoute];
  if (!rootRoute.children.length) {
    return newRoutes;
  }
  rootRoute.children.forEach((route) => {
    newRoutes = flattenRoutes(route, newRoutes);
  });
  return newRoutes;
};

const ltrTheme = createMuiTheme({
  direction: 'ltr',
});
const rtlTheme = createMuiTheme({
  direction: 'rtl',
});

// eslint-disable-next-line max-len
const getSideNavItems = (rootRoute: Route<ModuleTypes>, items: SideNavItem): SideNavItem[] => getSideNavItem(rootRoute, items).children as any;

export const drawerWidth = 270;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    display: 'flex',
    flex: 1,
    minWidth: `Calc(100%-${drawerWidth}px)`,
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  logo: {
    height: 80,
    width: 80,
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: '100%',
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    overflowX: 'hidden',
    marginTop: 65,
  },
  content: {
    flexGrow: 1,
    backgroundColor: 'transparent',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    flex: 1,
    alignItems: 'center',
  },
  search: {
    height: 30,
    width: 300,
    background: '#DDD',
    border: 'none',
  },
  spinnerContainer: {
    flexDirection: 'column',
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    width: '100vw',
    height: '100vh',
  },
}));

interface Props {
  window?: () => Window;
}

export interface DrawerItemProps {
  item: SideNavItem;
  level: number;
}

export const DrawerItem: React.FC<DrawerItemProps> = (props) => {
  const {
    item,
    level,
  } = props;
  const theme = useTheme();
  const [collapsed, setCollapsed] = useState(true);
  const history = useHistory();
  if (item.children && item.children.length) {
    return (
      <div
        style={{
          marginLeft: theme.spacing(level),
        }}
      >
        <ListItem>
          {item.icon && (
            <ListItemIcon>
              <img
                style={{
                  objectFit: 'contain',
                  width: 32,
                  height: 32,
                }}
                src={item.icon}
                alt={item.name}
              />
            </ListItemIcon>
          )}
          <ListItemText primary={item.name} />
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              setCollapsed(!collapsed);
            }}
          >
            {collapsed ? <ArrowDropDown /> : <ArrowDropUp />}
          </IconButton>
        </ListItem>
        {
          !collapsed && (
            item.children.map((child) => (<DrawerItem item={child} level={level + 1} />))
          )
        }
      </div>
    );
  }
  return (
    <ListItem
      style={{
        marginLeft: theme.spacing(2 * level),
      }}
      button
      key={item.path}
      onClick={() => history.push(item.path)}
    >
      {item.icon && (
        <ListItemIcon>
          <img
            style={{
              objectFit: 'contain',
              width: 32,
              height: 32,
            }}
            src={item.icon}
            alt={item.name}
          />
        </ListItemIcon>
      )}
      <ListItemText primary={item.name} />
    </ListItem>
  );
};

const navigationContainer: React.FC<Props> = (props) => {
  const {
    window,
  } = props;
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const stores = useStores();
  const [
    isLoading,
    setLoading,
  ] = useState(true);
  const {
    staticTexts,
  } = stores.backend.staticTexts;
  const {
    enqueueSnackbar,
  } = useSnackbar();
  const {
    updateTranslations,
  } = useLocalization();
  const initLocalization = async () => {
    let language: Language = stores.ui.localization.currentLanguage;
    if (!language) {
      const deviceLocale = (window ? window().navigator.language : 'en-US').split('-')[0];
      const languageIndex = deviceLocale === 'ar' ? 1 : 0;
      language = LANGUAGES[languageIndex];
    }
    if (staticTexts.data && staticTexts.data.length) {
      updateTranslations(language);
    } else {
      await updateTranslations(language);
    }
  };

  const askPermissionToReceiveNotifications = async () => {
    try {
      const messaging = getMessaging();
      onMessage(messaging, (payload) => {
        enqueueSnackbar(payload?.notification?.body, {
          persist: false,
          variant: 'success',
        });
      });
      const fcmToken = await getToken(messaging, {
        vapidKey: Settings.config.REACT_APP_VAPID_KEY,
      });
      stores.backend.users.updateFcmToken(fcmToken);
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    (async () => {
      setLoading(true);
      await stores.hydrate();
      await initLocalization();
      if (stores.backend.auth.isLoggedIn) {
        await stores.backend.auth.updateTokenHeader(stores.backend.auth.getAccessToken());
        await stores.backend.auth.getUser();
        askPermissionToReceiveNotifications();
      }
      document.body.setAttribute('dir', stores.ui.localization.currentLanguage.isRTL ? 'rtl' : 'ltr');
      setLoading(false);
    })();
  }, []);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const drawer = (
    <div>
      <Divider />
      <List>
        {getSideNavItems(RootRoute, {} as any).map((item) => (<DrawerItem item={item} level={0} />))}
      </List>
    </div>
  );

  useEffect(() => {
    setLoading(true);
  }, [stores.ui.localization.currentLanguage]);

  const container = window !== undefined ? () => window().document.body : undefined;

  if (isLoading) {
    return (
      <div
        className={classes.spinnerContainer}
      >
        <Spinner />
      </div>
    );
  }
  const lang = stores.ui.localization.currentLanguage;
  const jss = create({
    plugins: lang.isRTL ? [...jssPreset().plugins, rtl()] : [...jssPreset().plugins],
  });

  return (
    <StylesProvider jss={jss}>
      <ThemeProvider theme={stores.ui.localization.currentLanguage.isRTL ? rtlTheme : ltrTheme}>
        <BrowserRouter>
          {stores.backend.auth.isLoggedIn && stores.backend.auth.currentUser
            ? (
              <div
                className={classes.root}
                style={{
                  paddingLeft: lang.isRTL ? 20 : drawerWidth,
                  paddingRight: lang.isRTL ? drawerWidth : 20,
                  paddingTop: 20,
                }}
              >
                <CssBaseline />
                <AppBar position="fixed" className={classes.appBar}>
                  <Toolbar>
                    <IconButton
                      color="inherit"
                      aria-label="open drawer"
                      edge="start"
                      onClick={handleDrawerToggle}
                      className={classes.menuButton}
                    >
                      <MenuIcon />
                    </IconButton>
                    <div className={classes.header}>
                      <div
                        style={{
                          display: 'flex',
                        }}
                      >
                        <img
                          src={Assets.images.common.kashfLogo}
                          alt="Kashf"
                          style={{
                            height: 50,
                          }}
                        />
                        <Typography
                          variant="h6"
                          noWrap
                          style={{
                            lineHeight: '50px',
                            marginLeft: 16,
                            marginRight: 16,
                          }}
                        >
                          Kashf
                        </Typography>
                      </div>
                      {/* <input
                        placeholder={i18n.t('SEARCH')}
                        className={classes.search}
                      /> */}
                      <div
                        style={{
                          display: 'flex',
                        }}
                      >
                        <HeaderLanguages />
                        <HeaderMenu />
                      </div>
                    </div>
                  </Toolbar>
                </AppBar>
                <nav style={{
                  zIndex: 1,
                }}
                >
                  <Hidden smUp implementation="css">
                    <Drawer
                      container={container}
                      variant="temporary"
                      anchor={stores.ui.localization.currentLanguage.isRTL ? 'right' : 'left'}
                      open={mobileOpen}
                      onClose={handleDrawerToggle}
                      classes={{
                        paper: classes.drawerPaper,
                      }}
                      ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                      }}
                    >
                      {drawer}
                    </Drawer>
                  </Hidden>
                  <Hidden xsDown implementation="css">
                    <Drawer
                      classes={{
                        paper: classes.drawerPaper,
                      }}
                      variant="permanent"
                      open
                    >
                      {drawer}
                    </Drawer>
                  </Hidden>
                </nav>
                <main className={classes.content}>
                  <div
                    className={classes.toolbar}
                  />
                  <Switch>
                    {flattenRoutes(RootRoute, []).map((route) => (
                      <BrowserRoute
                        path={route.getPath()}
                        exact
                        component={() => <Module route={route} />}
                      />
                    ))}
                  </Switch>
                </main>
              </div>
            ) : (
              <>
                <AuthRoutes />
                <AuthFooter />
              </>
            )}
        </BrowserRouter>
      </ThemeProvider>
    </StylesProvider>
  );
};
export const NavigationContainer = observer(navigationContainer);
