import React,
{
  useEffect,
  useState,
} from 'react';

import {
  LoadingState,
  InputRegex,
  CountryCodes,
} from 'utils';
import {
  useStores,
} from 'hooks';
import * as Yup from 'yup';
import {
  useHistory,
} from 'react-router';
import GeneralFormFields from 'src/components/GeneralFormView/types';
import {
  CreateServiceProviderDTO,
} from 'shared';
import {
  baseScreen,
} from 'hoc';
import {
  FieldFile,
} from 'src/components/GeneralFormView/FormField/types';
import i18n from 'i18n-js';

import GeneralFormView from '../../../components/GeneralFormView';
import {
  RegisterSPFormViewModel,
} from './types';
import {
  FormFieldsBuilder,
} from '../../../components/GeneralFormView/Utils/FormFieldsBuilder';

const register: React.FC = () => {
  const {
    backend: {
      auth: authStore,
      serviceProviders: serviceProvidersStore,
      geo: geoStore,
    },
  } = useStores();
  const history = useHistory();
  const [
    isLoading,
    setLoading,
  ] = useState(true);
  useEffect(() => {
    const load = async () => {
      setLoading(true);
      await serviceProvidersStore.serviceProviderTypes.fetch();
      await geoStore.governoratesList.fetch();
      setLoading(false);
    };
    load();
  }, []);
  // const requiredErrMsg = 'This field is required!';

  return (
    <GeneralFormView<any, RegisterSPFormViewModel>
      defaultLang="en"
      title=""
      identifier="id"
      otherLanguages={[]}
      formData={new FormFieldsBuilder<RegisterSPFormViewModel>()
        .addField('avatar', {
          title: i18n.t('IMAGE'),
          type: GeneralFormFields.FILE_PICKER,
          validationSchema: Yup
            .array<FieldFile>()
            .max(1, i18n.t('ACCEPT_ONE_FILE'))
            .notRequired() as any,
          fieldOptions: {
            size: 200,
            accept: 'image/jpg, image/png',
            multipleImages: false,
          },
        })
        .addField('description', {
          title: i18n.t('DESCRIPTION'),
          type: GeneralFormFields.TEXT_AREA,
          validationSchema: Yup.string().max(500, i18n.t('DESC_LENGTH_VALIDATION')).notRequired() as any,
          hasTranslations: true,
          fieldOptions: {},
        })
        .addField('nameAr', {
          title: i18n.t('ARABIC_NAME'),
          type: GeneralFormFields.INPUT_FIELD,
          validationSchema: Yup.string().required(i18n.t('REQUIRED_FIELD')),
          hasTranslations: true,
          fieldOptions: {},
        })
        // .addField('nameEn', {
        //   title: i18n.t('ENGLISH_NAME'),
        //   type: GeneralFormFields.INPUT_FIELD,
        //   validationSchema: Yup.mixed().notRequired(),
        //   hasTranslations: true,
        //   fieldOptions: {},
        // })
        .addField('serviceProviderTypeId', {
          title: i18n.t('TYPE'),
          type: GeneralFormFields.SELECT_FIELD,
          validationSchema: Yup
            .array<number[]>()
            .of(Yup.number().required())
            .min(1, i18n.t('REQUIRED_FIELD'))
            .required(),
          fieldOptions: {
            isMultiple: false,
            items: serviceProvidersStore.serviceProviderTypes.data?.map((type) => ({
              title: type.translations[0].title,
              value: type.id,
            })) || [],
          },
        })
        .addField('email', {
          title: i18n.t('EMAIL'),
          fieldOptions: {},
          validationSchema: Yup.string().email().required(i18n.t('REQUIRED_FIELD')),
          type: GeneralFormFields.INPUT_FIELD,
        })
        .addField('nameEn', {
          title: i18n.t('NAME'),
          type: GeneralFormFields.INPUT_FIELD,
          validationSchema: Yup
            .string()
            .matches(InputRegex.english, i18n.t('NAME_VALIDATION'))
            .required(i18n.t('REQUIRED_FIELD')),
          hasTranslations: true,
          fieldOptions: {},
        })
        // ? not required for now
        // .addField('nameAr', {
        //   title: 'Arabic name',
        //   type: GeneralFormFields.INPUT_FIELD,
        //   validationSchema: Yup
        //     .string()
        //     .matches(InputRegex.arabic, 'Accepts Arabic letters only')
        //     .notRequired() as any,
        //   hasTranslations: true,
        //   fieldOptions: {},
        // })
        .addField('countryCode', {
          title: i18n.t('COUNTRY_CODE'),
          type: GeneralFormFields.SELECT_FIELD,
          validationSchema: Yup
            .array()
            .of(Yup.string().required())
            .min(1, i18n.t('REQUIRED_FIELD'))
            .required(i18n.t('REQUIRED_FIELD')),
          fieldOptions: {
            isMultiple: false,
            items: CountryCodes.map((country) => ({
              title: `${country.name} (${country.dial_code})`,
              value: country.code,
            })),
          },
        })
        .addField('phone', {
          title: i18n.t('MOBILE_NUMBER'),
          type: GeneralFormFields.INPUT_FIELD,
          validationSchema: Yup
            .string()
            .matches(InputRegex.mobileNum, i18n.t('INVALID_PHONE'))
            .required(i18n.t('REQUIRED_FIELD')),
          fieldOptions: {
            placeholder: 'ex. 1091021133',
          },
        })
        .addField('website', {
          title: i18n.t('WEBSITE'),
          type: GeneralFormFields.INPUT_FIELD,
          validationSchema: Yup.string().url(i18n.t('INVALID_FIELD')).notRequired() as any,
          fieldOptions: {
            placeholder: 'http://www.hospital.com',
          },
        })
        .addField('location', {
          fieldOptions: {},
          title: i18n.t('LOCATION'),
          type: GeneralFormFields.LOCATION_FIELD,
          validationSchema: Yup.object({
            latitude: Yup.number().required(),
            longitude: Yup.number().required(),
          }).notRequired(),
        })
        .addField('governorateId', {
          title: i18n.t('GOVERNORATE'),
          type: GeneralFormFields.SELECT_FIELD,
          validationSchema: Yup
            .array().of(
              Yup.number().moreThan(0).required(),
            ).notRequired() as any,
          fieldOptions: {
            isMultiple: false,
            items: geoStore.governoratesList.data?.map((gov) => ({
              title: gov.name,
              value: gov.id,
            })) || [],
            onValueChanged: (value) => {
              geoStore.citiesList.updateOptions(value);
            },
          },
        })
        .addField('cityId', {
          title: i18n.t('CITY'),
          type: GeneralFormFields.SELECT_FIELD,
          validationSchema: Yup
            .array().of(
              Yup.number().moreThan(0).required(),
            ).notRequired() as any,
          fieldOptions: {
            isMultiple: false,
            items: geoStore.citiesList.data.map((city) => ({
              title: city.name,
              value: city.id,
            })) || [],
          },
        })
        .addField('area', {
          title: i18n.t('AREA'),
          type: GeneralFormFields.INPUT_FIELD,
          validationSchema: Yup.string().notRequired() as any,
          fieldOptions: {},
        })
        .addField('coordinatorName', {
          title: i18n.t('COORDINATOR_NAME'),
          type: GeneralFormFields.INPUT_FIELD,
          validationSchema: Yup.string().email().required(i18n.t('REQUIRED_FIELD')),
          fieldOptions: {},
        })
        .addField('coordinatorPhone', {
          title: i18n.t('COORDINATOR_PHONE'),
          type: GeneralFormFields.INPUT_FIELD,
          validationSchema: Yup.string().notRequired() as any,
          fieldOptions: {},
        })
        .addField('password', {
          title: i18n.t('PASSWORD'),
          type: GeneralFormFields.INPUT_FIELD,
          validationSchema: Yup
            .string()
            .matches(InputRegex.lowercase, i18n.t('PASSWORD_VALIDATION'))
            .matches(InputRegex.uppercase, i18n.t('PASSWORD_VALIDATION'))
            .matches(InputRegex.numbers, i18n.t('PASSWORD_VALIDATION'))
            .min(8, i18n.t('PASSWORD_LENGTH_ERROR'))
            .required(i18n.t('REQUIRED_FIELD')),
          fieldOptions: {
            type: 'password',
          },
        })
        .addField('confirmPassword', {
          title: i18n.t('CONFIRM_PASSWORD'),
          type: GeneralFormFields.INPUT_FIELD,
          validationSchema: Yup
            .string()
            .oneOf([Yup.ref('password')], 'Password doesn’t match')
            .required(i18n.t('REQUIRED_FIELD')),
          fieldOptions: {
            type: 'password',
          },
        })
        // TODO unsupported format validation for all FILE_PICKERs >>> code review
        .addField('commercialRecord', {
          title: i18n.t('COMMERCIAL_RECORD'),
          type: GeneralFormFields.FILE_PICKER,
          validationSchema: Yup
            .array<FieldFile>()
            .min(1, i18n.t('REQUIRED_FIELD'))
            .max(1, i18n.t('ACCEPT_ONE_FILE')) as any,
          fieldOptions: {
            size: 200,
            accept: 'image/jpg, image/png',
            multipleImages: false,
          },
        })
        .addField('taxId', {
          title: i18n.t('TAX_ID'),
          type: GeneralFormFields.FILE_PICKER,
          validationSchema: Yup
            .array<FieldFile>()
            .min(1, i18n.t('REQUIRED_FIELD'))
            .max(1, i18n.t('ACCEPT_ONE_FILE')) as any,
          fieldOptions: {
            size: 200,
            accept: 'image/jpg, image/png',
            multipleImages: false,
          },
        })
        .addField('docs', {
          title: i18n.t('CERTIFICATES_AND_OTHER_DOCS'),
          type: GeneralFormFields.FILE_PICKER,
          validationSchema: Yup
            .array<FieldFile>()
            .notRequired() as any,
          fieldOptions: {
            size: 200,
            // TODO include more supported formats: Docx, PDF
            accept: 'image/jpg, image/png',
            multipleImages: true,
          },
        })
        // ? not required for now
        // .addField('coordinatorName', {
        //   title: 'Coordinator name',
        //   type: GeneralFormFields.INPUT_FIELD,
        //   validationSchema: Yup
        //     .string()
        //     .matches(InputRegex.english, 'English characters only')
        //     .nullable(true) as any,
        //   fieldOptions: {},
        // })
        // .addField('coordinatorPhone', {
        //   title: 'Coordinator phone',
        //   type: GeneralFormFields.INPUT_FIELD,
        //   validationSchema: Yup.string().nullable(true) as any,
        //   fieldOptions: {},
        // })
        .addField('agreeToTermsAndPolicy', {
          title: (
            <span>
              {i18n.t('POLICY_AGREEMENT_1')}
              {' '}
              <a
                href="https://www.termsandcondiitionssample.com/"
              >
                {i18n.t('POLICY_AGREEMENT_2')}
              </a>
            </span>
          ),
          type: GeneralFormFields.TOGGLE_FIELD,
          validationSchema: Yup
            .boolean()
            .equals([true], i18n.t('REQUIRED_FIELD')),
          initialValue: false,
          fieldOptions: {},
        }).formFields}
      create={async (formValue) => {
        const formViewModel = new RegisterSPFormViewModel(new CreateServiceProviderDTO());
        Object.assign(formViewModel, formValue);
        setLoading(true);
        const res = await authStore.registerUser(await formViewModel.toDTO());
        history.replace('/thank-you');
        return res;
      }}
      update={async () => ({} as any)}
      get={async () => {
        await authStore.getUser();
        return new RegisterSPFormViewModel(authStore.userToRegister);
      }}
      isFetchSuccessful={
        isLoading
        && serviceProvidersStore.serviceProviderTypes.loadingState === LoadingState.SUCCEEDED
        && geoStore.governoratesList.loadingState === LoadingState.SUCCEEDED
        && geoStore.citiesList.loadingState === LoadingState.SUCCEEDED
      }
    />
  );
};

export const Register = baseScreen(register, {
  allowedRoles: ['NONE'],
});
