import {
  FeedbackDTO,
  RequestCallDTO,
} from 'src/stores/backend/HelpCenterStore/types';
import {
  BaseFormViewModel,
} from '../types';

export class RequestCallModelView extends BaseFormViewModel<RequestCallDTO> {
  id?: number;

  name: string;

  email: string;

  mobileNumber: string;

  message: string;

  countryCode: string[];

  constructor(dto: RequestCallModelView) {
    super();
    this.name = dto.name;
    this.mobileNumber = dto.mobileNumber;
    this.message = dto.message;
    this.countryCode = dto.countryCode;
    this.email = dto.email;
  }

  toDTO() {
    return {
      email: this.email,
      name: this.name,
      message: this.message,
      mobileNumber: `${this.countryCode[0]}${this.mobileNumber}`,
    } as RequestCallDTO;
  }
}

export class FeedbackModelView extends BaseFormViewModel<FeedbackDTO> {
  id?: number;

  name: string;

  email: string;

  mobileNumber: string;

  message: string;

  countryCode: string[];

  constructor(dto: FeedbackModelView) {
    super();
    this.name = dto.name;
    this.mobileNumber = dto.mobileNumber;
    this.message = dto.message;
    this.countryCode = dto.countryCode;
    this.email = dto.email;
  }

  toDTO() {
    return {
      email: this.email,
      name: this.name,
      message: this.message,
      mobileNumber: `${this.countryCode[0]}${this.mobileNumber}`,
    } as FeedbackDTO;
  }
}
