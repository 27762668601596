import {
  ResetPasswordDTO,
} from 'src/stores/backend/auth/types';
import {
  BaseFormViewModel,
} from '../../types';

export class ResetPasswordViewModel extends BaseFormViewModel<ResetPasswordDTO> {
  password: string;

  confirmPassword: string;

  token: string;

  constructor(dto: ResetPasswordDTO) {
    super();
    const {
      password,
      token,
    } = dto;

    Object.assign(this, {
      password,
      token,
    });
  }

  toDTO() {
    return this as ResetPasswordDTO;
  }
}
