import {
  DAY,
} from 'src/components/GeneralFormView/FormField/ScheduleField/types';
import {
  FieldFileCommonTypes,
  RemoteFile,
} from 'src/components/GeneralFormView/FormField/types';
import {
} from 'utils';
import {
  sortBy,
} from 'lodash';
import {
  BaseFormViewModel,
} from '../types';
import {
  Clinic,
  Doctor,
  Rating,
} from '../doctors/types';

export interface Schedule {
  id?: number;
  fees: number;
  type: ScheduleType,
  workingDays: WorkingDay[];
}
export interface Grade {
  id: number;
  title: string;
}

export enum ScheduleType {
  ALL = 'all',
  HOME_VISIT = 'Home Visit',
  ONLINE_CONSULTATION = 'Online Consultation',
  CLINIC = 'Clinic',
}
export enum DoctorStatus {
  'ONLINE' = 'Online',
  'OFFLINE' = 'Offline',
  'BUSY' = 'Busy',
}

export interface WorkingDay {
  id: number;
  day: DAY;
  to: string;
  from: string;
  isActive: boolean;
  containsBreak: boolean;
  breakFrom?: string;
  breakTo?: string;
  order: number;
}
export class DoctorModelView extends BaseFormViewModel<Doctor> {
  id?: number;

  clinics?: Clinic[];

  grade?: string;

  homeVisitFees: number;

  scheduleHomeVisitFees: number;

  isASAPCalls: boolean;

  isFreeCall: boolean;

  nationalIdFrontURL: RemoteFile[];

  syndicateNumber: string;

  name: string;

  homeVisitSchedule: WorkingDay[];

  onlineConsultationSchedule: WorkingDay[];

  homeVisitScheduleId?: number;

  onlineConsultationScheduleId?: number;

  clinicScheduleId?: number;

  clinicSchedule: WorkingDay[];

  isFreeVisit: boolean;

  certificateId: RemoteFile[];

  syndicateIdURL: RemoteFile[];

  website?: string;

  taxId: RemoteFile[];

  isInCall: boolean;

  languages?: string;

  onlineConsultationFees: number;

  scheduleOnlineConsultationFees: number;

  scheduleClinicFees: number;

  nationalIdBackURL: RemoteFile[];

  ratings?: Rating[];

  specialities?: string;

  status: DoctorStatus;

  schedules: Schedule[];

  avatar: RemoteFile[];

  about: string;

  email: string;

  phone: string;

  constructor(dto: Doctor) {
    super();

    this.name = dto.user.name;
    if (dto.nationalIdBackURL) {
      this.nationalIdBackURL = [{
        source: dto.nationalIdBackURL,
        type: FieldFileCommonTypes.IMAGE,
      }];
    }
    if (dto.nationalIdFrontURL) {
      this.nationalIdFrontURL = [{
        source: dto.nationalIdFrontURL,
        type: FieldFileCommonTypes.IMAGE,
      }];
    }
    this.onlineConsultationFees = dto.onlineConsultationFees;
    this.phone = dto.user.phone;
    this.status = dto.status;
    if (dto.taxIdURL) {
      this.taxId = [{
        source: dto.taxIdURL,
        type: FieldFileCommonTypes.IMAGE,
      }];
    }
    if (dto.syndicateIdURL) {
      this.syndicateIdURL = [{
        source: dto.syndicateIdURL,
        type: FieldFileCommonTypes.IMAGE,
      }];
    }
    this.languages = dto.languages.join(' - ');
    this.syndicateNumber = dto.syndicateNumber;
    this.website = dto.website;
    this.about = dto.about;
    const homeVisitSchedule = dto.schedules
      ?.filter((schedule) => schedule.type === ScheduleType.HOME_VISIT)[0];
    const onlineConsultationSchedule = dto.schedules
      ?.filter((schedule) => schedule.type === ScheduleType.ONLINE_CONSULTATION)[0];
    const clinicSchedule = dto.schedules
      ?.filter((schedule) => schedule.type === ScheduleType.CLINIC)[0];
    if (homeVisitSchedule) {
      this.homeVisitScheduleId = homeVisitSchedule?.id;
      this.homeVisitSchedule = sortBy(homeVisitSchedule.workingDays, (o) => o.order);
      this.scheduleHomeVisitFees = homeVisitSchedule.fees;
    }
    if (onlineConsultationSchedule) {
      this.onlineConsultationSchedule = sortBy(onlineConsultationSchedule.workingDays, (o) => o.order);
      this.onlineConsultationScheduleId = onlineConsultationSchedule?.id;
      this.scheduleOnlineConsultationFees = onlineConsultationSchedule.fees;
    }
    if (clinicSchedule) {
      this.clinicScheduleId = clinicSchedule?.id;
      this.clinicSchedule = sortBy(clinicSchedule.workingDays, (o) => o.order);
      this.scheduleClinicFees = clinicSchedule.fees;
    }
    if (dto.user.avatar) {
      this.avatar = [{
        source: dto.user.avatar,
        type: FieldFileCommonTypes.IMAGE,
      }];
    }
    if (dto.certificates) {
      this.certificateId = dto.certificates.map((item) => ({
        source: item,
        type: FieldFileCommonTypes.IMAGE,
      }));
    }
    this.email = dto.user.email;
    this.grade = dto.grade.title;
    this.homeVisitFees = dto.homeVisitFees;
    this.id = dto.id;
    this.specialities = dto.specialities.map((item) => item.title).join(' - ');
  }

  async toDTO() {
    return {} as Doctor;
  }
}
