import {
  Assets,
} from 'assets';
import React from 'react';
import {
  Link,
} from 'react-router-dom';
import i18n from 'i18n-js';
import {
  makeStyles,
} from '@material-ui/core';
import {
  baseScreen,
} from 'hoc';
import moment from 'moment';
import {
  Styles,
} from './styles';

const useStyles = makeStyles(Styles);

const homeScreen: React.FC = () => {
  const classes = useStyles();

  return (
    <div>
      <Link
        to={`/services/:id/${moment().format('DD-MM-yyyy')}`}
        className={classes.link}
      >
        {i18n.t('TODAYS_REQUESTS')}
      </Link>
      <Link
        to="/doctors/doctors/doctor-form/new/id"
        className={classes.link}
      >
        {i18n.t('ADD_NEW_DOCTOR')}
      </Link>
      <img
        style={{
          width: '100%',
        }}
        src={Assets.images.screens.home.charts}
        alt="Charts"
      />
    </div>
  );
};

export const HomeScreen = baseScreen(homeScreen, {
  allowedRoles: ['ADMIN', 'NONE'],
});
