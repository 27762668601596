import {
  Settings,
} from 'settings';

const uploadFileToS3 = async (file: File, url: string) => {
  try {
    const arrayBuffer = await file.arrayBuffer();

    const response = await fetch(url, {
      method: 'PUT',
      body: arrayBuffer,
      headers: {
        'Content-Type': file.type,
        'Content-Encoding': 'base64',
      },
    });

    if (response.ok) {
      console.log('File was successfully uploaded to s3.');
      return;
    }
    throw new Error(`${response.status}: ${response.statusText}`);
  } catch (error) {
    throw new Error(error);
  }
};

const requestUploadUrl = async (url: string, filePath: string) => {
  try {
    const fetchUrl = `${Settings.config.REACT_APP_BASE_URL}/${url}?key=${filePath}`;
    const response = await fetch(fetchUrl, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        accept: 'application/json',
      },
    });

    const result = await response.json();
    if (!result.uploadUrl || !result.filedUrl) {
      const message = 'Error in getting uploadUrl from backend';
      throw new Error(message);
    }
    return result;
  } catch (error) {
    throw new Error(error);
  }
};

type UploadFileParams = {
  file: File;
  filePath: string;
};

export const uploadFile = async ({
  file,
  filePath,
}: UploadFileParams): Promise<string> => {
  try {
    const {
      uploadUrl,
      filedUrl,
    } = await requestUploadUrl('aws/upload-url', filePath);
    await uploadFileToS3(file, uploadUrl);
    return filedUrl as string;
  } catch (error) {
    throw new Error(error);
  }
};

// TODO: Map uploadFile function
export const uploadFiles = () => 0;
