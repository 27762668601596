import React,
{
  useState,
} from 'react';
import {
  Typography,
} from '@material-ui/core';
import {
  useStores,
} from 'hooks';
import {
  Assets,
} from 'assets';
import {
  useObserver,
} from 'mobx-react';
import {
  useHistory,
} from 'react-router';
import i18n from 'i18n-js';
import {
  Styles,
} from './styles';

export const HeaderMenu = () => {
  const {
    backend: {
      auth,
    },
  } = useStores();
  const [
    displayMenu,
    setDisplayMenu,
  ] = useState(false);
  const handleClick = () => {
    setDisplayMenu(!displayMenu);
  };
  const history = useHistory();
  const useStyles = Styles;
  const classes = useStyles();
  // const navigateToChangePassword = () => {
  //   history.push('/forget-password');
  // };
  const logout = () => {
    auth.logout();
    history.replace('/');
  };
  const editProfile = () => {
    history.push('/profile/edit/:id');
  };
  return useObserver(() => (
    <div
      className={classes.images}
      role="button"
      onClick={handleClick}
      onKeyDown={handleClick}
      tabIndex={0}
    >
      <img
        src={Assets.images.common.bell}
        alt="Notifications"
        className={classes.bell}
      />
      <div className={classes.avatarContainer}>
        <img
          src={auth.currentUser.avatar}
          alt={auth.currentUser.name}
          className={classes.avatar}
        />
        <Typography
          className={classes.name}
        >
          {auth.currentUser.name}
        </Typography>
        <img
          src={Assets.images.common.chevronDown}
          alt={auth.currentUser.name}
          className={classes.chevronBottom}
        />
      </div>
      { displayMenu && (
      <ul className={classes.optionsContainer}>
        <li className={classes.li}>
          <div
            role="button"
            onClick={editProfile}
            onKeyDown={editProfile}
            className={classes.link}
            tabIndex={0}
          >
            {i18n.t('EDIT_PROFILE')}
          </div>
        </li>
        <li className={classes.li}>
          <div
            role="button"
            className={classes.link}
            onClick={logout}
            onKeyDown={logout}
            tabIndex={0}
          >
            {i18n.t('LOGOUT')}
          </div>
        </li>
      </ul>
      )}
    </div>
  ));
};
