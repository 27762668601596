import React from 'react';
import {
  FieldFile,
  FieldFileCommonTypes,
  FieldFileTypes,
} from '../types';
import ImagePreview from './ImagePreview';
import OtherPreview from './OtherPreview';
import VideoPreview from './VideoPreview';

export const FilePreviews: Record<FieldFileTypes, React.ComponentType<FieldFile>> = {
  [FieldFileCommonTypes.IMAGE]: ImagePreview,
  [FieldFileCommonTypes.VIDEO]: VideoPreview,
  [FieldFileCommonTypes.PDF]: OtherPreview,
  other: OtherPreview,
};
