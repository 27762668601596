import {
  EditorState,
  convertFromRaw,
  convertToRaw,
} from 'draft-js';
import {
  BaseDTO,
} from 'shared';
import {
  ModelOf,
} from 'utils';
import {
  MapLocation,
} from '../../components/GeneralFormView/types';
import {
  FieldFile,
  FieldFileCommonTypes,
} from '../../components/GeneralFormView/FormField/types';
import {
  BaseFormViewModel,
} from '../types';

export interface MiniMiniForm<T> {
  miniTitle1: string;
  miniTitle2: string;
  images: T[];
}
export interface MiniFormTestEntity<T> {
  title1: string;
  title2: string;
  images: T[];
  miniForm: Omit<MiniFormTestEntity<T>, 'miniForm'>;
}

export class TestDTO extends BaseDTO {
  id: string = '0';

  title: string = '';

  description: string = '';

  image: string[] = [];

  miniForm: MiniFormTestEntity<string> = {
    title1: '',
    title2: '',
    images: [],
    miniForm: {
      title1: '',
      title2: '',
      images: [],
    },
  };

  miniMiniForm: MiniMiniForm<string>[] = [];

  person: string = 'person1';

  persons: string[] = ['person1'];

  richDescription: string = JSON.stringify(convertToRaw(EditorState.createEmpty().getCurrentContent()));

  date: Date = new Date();

  time: Date = new Date();

  dateTime: Date = new Date();

  isActive: boolean = false;

  location: MapLocation = {
    latitude: 0,
    longitude: 0,
  };

  constructor(initValues?: Partial<ModelOf<TestDTO>>) {
    super();
    Object.assign(this, initValues);
  }
}

export class TestFormViewModel extends BaseFormViewModel<TestDTO> {
  id: string;

  title: string;

  description: string;

  image: FieldFile[];

  miniForm: MiniFormTestEntity<FieldFile>;

  miniMiniForm: MiniMiniForm<FieldFile>[];

  person: string[];

  persons: string[];

  richDescription: EditorState;

  date: Date;

  schedule: any;

  time: Date;

  dateTime: Date;

  isActive: boolean;

  location: MapLocation;

  constructor(dto: TestDTO) {
    super();
    const {
      id,
      title,
      description,
      image,
      miniForm,
      miniMiniForm,
      person,
      persons,
      richDescription,
      date,
      time,
      dateTime,
      isActive,
      location,
    } = dto;
    const images: FieldFile[] = image.map((item) => ({
      source: item,
      type: FieldFileCommonTypes.IMAGE,
    }));
    const miniForm1Images: FieldFile[] = miniForm.images.map((item) => ({
      source: item,
      type: FieldFileCommonTypes.IMAGE,
    }));
    const miniForm2Images: FieldFile[] = miniForm.miniForm.images.map((item) => ({
      source: item,
      type: FieldFileCommonTypes.IMAGE,
    }));
    const miniForm3Images: MiniMiniForm<FieldFile>[] = miniMiniForm.map((miniminiform) => {
      const miniFormInternalImages = miniminiform.images.map((item) => ({
        source: item,
        type: FieldFileCommonTypes.IMAGE,
      }));
      return {
        ...miniminiform,
        images: miniFormInternalImages,
      };
    });
    Object.assign(this, {
      id,
      title,
      description,
      image: images,
      person: [person],
      persons,
      date,
      time,
      dateTime,
      isActive,
      location,
      miniForm: {
        ...miniForm,
        images: miniForm1Images,
        miniForm: {
          ...miniForm.miniForm,
          images: miniForm2Images,
        },
      },
      miniMiniForm: miniForm3Images,
      richDescription: EditorState.createWithContent(convertFromRaw(JSON.parse(richDescription))),
    });
  }

  async toDTO() {
    return {} as TestDTO;
  }
}
