/* eslint-disable max-len */
import {
  action,
  observable,
  toJS,
} from 'mobx';
import {
  BackendEntity,
  ListBackendEntity,
  ModelOf,
} from 'utils';
import {
  Doctor,
  DoctorsTable,
} from 'src/screens/doctors/types';
import {
  Settings,
} from 'settings';
import {
  Schedule,
} from 'src/screens/doctorDetails/types';
import {
  persist,
} from 'mobx-persist';
import {
  BaseBackendStore,
} from '../types';
import {
  deleteDoctor,
  doctors,
  getDoctorById,
  download,
  addDoctor,
  doctorScheduleRequest,
} from './requests';
import {
  BackendStores,
} from '..';
import {
  DoctorSchedule,
  SearchObj,
} from './types';

export class DoctorsStore extends BaseBackendStore {
  @observable private _entityList: Doctor[] = [];

  @observable searchResult: Doctor[] = [];

  @persist('list') @observable _recentlyViewed: DoctorsTable[];

  @action getRecentlyViewed = async (): Promise<DoctorsTable[]> => Promise.resolve(this._recentlyViewed);

  @observable recentlyViewed = new ListBackendEntity(
    this,
    '_recentlyViewed',
    this.getRecentlyViewed,
  );

  @observable _doctorSchedule: DoctorSchedule[] = [];

  @action getDoctorSchedule = async (doctorId: number): Promise<DoctorSchedule[]> => this.connections.backend.httpGet(doctorScheduleRequest(doctorId, this.parent.auth.currentUser.serviceProvider.id));

  @observable doctorSchedule = new ListBackendEntity(
    this,
    '_doctorSchedule',
    this.getDoctorSchedule,
  );

  @observable filter: SearchObj = {
    filters: [],
  };

  @action updateFilter = (item: SearchObj) => {
    this.filter = {
      ...this.filter,
      ...item,
    };
  };

  @action updateRecentlyViewed = (doctor: DoctorsTable) => {
    const data = toJS(this._recentlyViewed) || [];
    if (!data.find((item) => item.id === doctor.id)) {
      if (data.length >= 3) {
        data.shift();
      }
      data.unshift(doctor);
      this._recentlyViewed = data;
      this.getRecentlyViewed();
    }
  };

  @action public getAll = async (options: any): Promise<Doctor[]> => {
    if (options.search) {
      this.filter.search = {
        by: 'any',
        value: options.search,
      };
    }
    const response = await this.connections.backend.httpGet(`${doctors}?take=${options.pageSize || 10}&page=${options.page || 1}&query=${JSON.stringify(this.filter)}`);
    return response;
  };

  @action searchDoctors = async (filter: SearchObj) => {
    this.searchResult = await this.connections.backend.httpGet(`${doctors}?query=${JSON.stringify(filter)}`);
    return Promise.resolve(this.searchResult);
  };

  @observable entityList = new ListBackendEntity(
    this,
    '_entityList',
    this.getAll,
  );

  constructor(
    public parent: BackendStores,
  ) {
    super();
    this.makeObservable();
    this.registerObservableDrivers();
  }

  @observable private _selectedEntity: Doctor;

  @observable selectedEntity = new BackendEntity(
    this,
    '_selectedEntity',
    this.getOne,
  );

  @action
  public async create(testEntity: Doctor): Promise<number> {
    console.log('in create', testEntity);
    return 1;
  }

  @action
  public async getOne(id: string): Promise<ModelOf<Doctor>> {
    const response = await this.connections.backend.httpGet(getDoctorById(id));
    this.selectedEntity = response;
    return Promise.resolve(response);
  }

  @action
  public async delete(id: number): Promise<void> {
    const response = await this.connections.backend
      .httpDelete(deleteDoctor(this.parent.auth.currentUser.serviceProvider.id, id));
    return Promise.resolve(response);
  }

  @action
  public addDoctorToServiceProvider = async (doctorId: number, data: Schedule[]) => {
    const response = await this.connections.backend
      .httpPost(addDoctor(this.parent.auth.currentUser.serviceProvider.id, doctorId), {
        serviceProviderSchedule: data,
      });
    return Promise.resolve(response);
  };

  @action
  public editDoctorToServiceProvider = async (doctorId: number, data: Schedule[]) => {
    const response = await this.connections.backend
      .httpPut(addDoctor(this.parent.auth.currentUser.serviceProvider.id, doctorId), {
        serviceProviderSchedule: data,
      });
    return Promise.resolve(response);
  };

  @action
  public async update(): Promise<void> {
    return Promise.resolve();
  }

  public download() {
    return `${Settings.config.REACT_APP_BASE_URL + download(this.parent.auth.currentUser.serviceProvider.id)}&query=${JSON.stringify(this.filter)}&lang=${this.parent.parent.ui.localization.currentLanguage.key}`;
  }
}
