import {
  action,
  observable,
} from 'mobx';
import {
  User,
} from 'src/screens/doctors/types';
import {
  persist,
} from 'mobx-persist';
import {
  BackendStores,
} from '..';
import {
  serviceProviders,
  fcmTokenUrl,
} from './requests';
import {
  BaseBackendStore,
} from '../types';

export class UserStore extends BaseBackendStore {
  @persist @observable role: 'ADMIN' | 'USER' | 'GUEST' | 'NONE' = 'NONE';

  constructor(
    public parent: BackendStores,
  ) {
    super();
    this.makeObservable();
  }

  @action setRole(role: 'ADMIN' | 'USER' | 'GUEST' | 'NONE') {
    this.role = role;
  }

  @action updateFcmToken = async (fcmToken: string) => {
    await this.connections.backend.httpPut(fcmTokenUrl, {
      fcmToken,
    });
  };

  @action getServiceProvider = async (): Promise<User> => this.connections
    .backend.httpGet(serviceProviders(this.parent.auth.currentUser.serviceProvider.id));

  @action updateUser = async (data: Partial<User>) => {
    await this.connections.backend.httpPut(serviceProviders(this.parent.auth.currentUser.serviceProvider.id), {
      website: data.serviceProvider?.website,
      signupCode:data.serviceProvider?.signupCode,
      translations: [
        {
          languageCode: 'ar',
          description: data.description,
        },
        {
          languageCode: 'en',
          description: data.description,
        },
      ],
      commercialRecord: data.serviceProvider?.commercialRecord,
      docs: data.serviceProvider?.docs,
    });
    this.parent.auth.getUser();
  };
}
