import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  FormControl,
  Paper,
  Tooltip,
  Typography,
} from '@material-ui/core';
import classNames from 'classnames';
import {
  getIn,
  useFormikContext,
} from 'formik';
import React, {
  useEffect,
} from 'react';
import Dropzone from 'react-dropzone';
import i18n from 'i18n-js';
import FieldError from '../../Utils/FieldError';
import {
  FieldView,
} from '../../Utils/FieldView';
import {
  FieldFile,
  FieldPropsCommon,
  ImagePickerFieldValue,
  LocalFile,
} from '../types';
import {
  onChange,
} from '../utils';
import {
  FilePreviews,
} from './constants';
import classes from './styles.module.scss';
import {
  getFileName,
  getFileType,
} from './utils';

type Props = FieldPropsCommon & ImagePickerFieldValue;

const FilePickerField = <FormModel extends object>(props: Props) => {
  const {
    values,
    handleChange,
    isSubmitting,
    setFieldTouched,
    errors,
    touched,
  } = useFormikContext<FormModel>();

  const {
    title,
    fieldOptions: {
      disabled,
    },
    location,
    viewMode,
    fieldOptions: {
      accept,
    },
    fieldOptions:{
      disableRemoving
    }
  } = props;

  useEffect(() => {
    if (isSubmitting) {
      setFieldTouched(location);
    }
  }, [isSubmitting, location, setFieldTouched]);

  const value: FieldFile[] = getIn(values, location);
  const onValueChange = onChange(handleChange, location);
  const error = getIn(errors, location);
  const isTouched = getIn(touched, location);

  const onDrop = (acceptedFiles: File[]) => {
    const newFiles: LocalFile[] = acceptedFiles?.map((file) => ({
      file,
      type: getFileType(file),
    })) || [];
    onValueChange(value.concat(newFiles));
    setFieldTouched(location);
  };

  const onRemoveImage = (index: number) => {
    const imageValues: FieldFile[] = value;
    imageValues.splice(index, 1);
    onValueChange(imageValues);
  };

  const swapImage = (index: number, direction: 'left' | 'right') => {
    const imageValues: FieldFile[] = value;
    const targetIndex = direction === 'left' ? index - 1 : index + 1;
    const temp = imageValues[targetIndex];
    imageValues[targetIndex] = imageValues[index];
    imageValues[index] = temp;
    onValueChange(imageValues);
  };
  if (viewMode) {
    return (
      <FieldView
        title={title}
        value={() => (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
            }}
          >
            {value.map((file) => {
              const PreviewComponent = FilePreviews[file.type];
              return (
                <div
                  style={{
                    margin: 8,
                  }}
                >
                  <PreviewComponent {...file} />
                </div>
              );
            })}
          </div>
        )}
      />
    );
  }

  return (
    <div
      className={classes.container}
      style={{
        margin: 8,
      }}
    >
      <FormControl>
        <div className={classNames(classes.gallery)}>
          <Typography
            variant="caption"
            gutterBottom
            className={classNames(classes.label)}
          >
            {title}
          </Typography>
          <Dropzone
            onDrop={onDrop}
            disabled={disabled}
          >
            {({
              getRootProps, getInputProps, isDragActive,
            }) => (
              <Paper
                {...getRootProps()}
                className={classNames('dropzone', {
                  'dropzone--isActive': isDragActive,
                })}
                style={{
                  height: 128,
                  margin: 8,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <input {...getInputProps()} accept={accept as string} onClick={() => setFieldTouched(location)} />
                {
                    isDragActive
                      ? (
                        <Typography variant="subtitle1" gutterBottom>
                          {i18n.t('DROP_FILES_HERE')}
                        </Typography>
                      )
                      : (
                        <Typography variant="subtitle1" gutterBottom>
                          {i18n.t('DRAG_AND_DROP_FILES_HERE')}
                        </Typography>
                      )
                  }
              </Paper>
            )}
          </Dropzone>
          <div className={classes.thumbsContainer}>
            {
              value.map((file, index) => {
                const PreviewComponent = FilePreviews[file.type];
                return (
                  <Card
                    style={{
                      position: 'relative',
                      margin: 8,
                    }}
                    // eslint-disable-next-line react/no-array-index-key
                    key={`${index}`}
                  >
                    <Tooltip
                      title={getFileName(file)}
                    >
                      <div>
                        <CardHeader
                          title={getFileName(file)}
                          className={classes.filePickerCardHeader}
                        />
                      </div>
                    </Tooltip>
                    <CardContent
                      style={{
                        width: 128,
                        height: 128,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        margin: 'auto',
                      }}
                    >
                      <PreviewComponent {...file} />
                    </CardContent>
                    <CardActions>
                      <Button
                        variant="outlined"
                        disabled={index === 0}
                        onClick={() => swapImage(index, 'left')}
                      >
                        {'<'}
                      </Button>
                      <Button
                        variant="outlined"
                        disabled={!!disableRemoving}
                        onClick={() => {
                          if (!disabled) {
                            onRemoveImage(index);
                          }
                        }}
                      >
                        X
                      </Button>
                      <Button
                        variant="outlined"
                        disabled={index === value.length - 1}
                        onClick={() => swapImage(index, 'right')}
                      >
                        {'>'}
                      </Button>
                    </CardActions>
                  </Card>
                );
              })
            }
          </div>
          <FieldError
            errors={error}
            touched={isTouched}
          />
        </div>
      </FormControl>
    </div>
  );
};

export default FilePickerField;
