import {
  Patient,
} from 'shared';
import moment from 'moment';

export class PatientsTable {
  name: string;

  mobileNumber: string;

  email: string;

  gender: string;

  age: number;

  id: number;

  constructor(data: Patient) {
    this.age = moment().diff(moment(data.dateOfBirth), 'years');
    this.email = data.user.email;
    this.gender = data.user.gender;
    this.mobileNumber = data.user.phone;
    this.name = data.user.name;
    this.id = data.id;
  }
}
