import {
  Paper,
  Typography,
} from '@material-ui/core';
import {
  getIn,
  useFormikContext,
} from 'formik';
import React, {
  useEffect,
} from 'react';
import {
  Editor,
  EditorState,
} from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {
  convertToRaw,
} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import FieldError from '../../Utils/FieldError';
import {
  FieldPropsCommon,
  RichTextFieldValue,
} from '../types';
import {
  onChange,
} from '../utils';
import {
  FieldView,
} from '../../Utils/FieldView';

type Props = FieldPropsCommon & RichTextFieldValue;

const RichTextField = <FormModel extends object>(props: Props) => {
  const {
    values,
    handleChange,
    isSubmitting,
    setFieldTouched,
    errors,
    touched,
  } = useFormikContext<FormModel>();

  const {
    fieldOptions: {
      editorProps,
    },
    disabled,
    title,
    viewMode,
    location,
  } = props;

  const value: EditorState = getIn(values, location);
  const onValueChange = onChange(handleChange, location);
  const error = getIn(errors, location);
  const isTouched = getIn(touched, location);

  useEffect(() => {
    if (isSubmitting) {
      setFieldTouched(location);
    }
  }, [isSubmitting, location, setFieldTouched]);
  if (viewMode) {
    return (
      <FieldView
        title={title}
        value={() => (
          <div
            dangerouslySetInnerHTML={{
              __html: draftToHtml(convertToRaw(value.getCurrentContent())),
            }}
          />
        )}
      />
    );
  }

  return (
    <Paper
      style={{
        margin: 8,
        padding: 8,
      }}
    >
      <Typography variant="h6">{title}</Typography>
      <Editor
        {...(editorProps || {})}
        readOnly={disabled}
        editorState={value}
        onBlur={() => setFieldTouched(location)}
        onEditorStateChange={(editorState) => onValueChange(editorState)}
      />
      <FieldError
        errors={error}
        touched={isTouched}
      />
    </Paper>
  );
};

export default RichTextField;
