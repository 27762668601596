import {
  BaseDTO,
  Patient,
} from 'shared';
import {
  MapLocation,
} from 'src/components/GeneralFormView/types';
import {
  DoctorStatus,
  Grade,
  Schedule,
} from '../doctorDetails/types';
import {
  ServiceProviderType,
} from '../profile/types';

export class Doctor extends BaseDTO {
  clinics: Clinic[];

  feesMax: number;

  isFreeVisit: boolean;

  isFreeCall: boolean;

  feesMin: number;

  serviceProviders: DoctorServiceProvider[];

  certificates: string[];

  syndicateIdURL: string;

  nationalIdFrontURL: string;

  nationalIdBackURL: string;

  grade: Grade;

  ratings: Rating[];

  schedules: Schedule[];

  about: string;

  website: string;

  id: number;

  taxIdURL: string;

  homeVisitFees: number;

  isFavourite: boolean;

  onlineConsultationFees: number;

  specialities: Speciality[];

  status: DoctorStatus;

  user: User;

  languages: string[];

  isInCall: boolean;

  isASAPCalls: boolean;

  syndicateNumber: string;

  lastCheckIn: Date;

  static fromDTO = (dto: Doctor): DoctorsTable => ({
    status: dto.status,
    avatar: dto.user.avatar,
    code: dto.id,
    grade: dto.grade.title,
    hospital: 'hospital name',
    lastCheckIn: dto.lastCheckIn,
    name: dto.user.name,
    phone: dto.user.phone,
    specialty: dto.specialities.map((sp) => sp.title).join(' - '),
    syndicateNumber: dto.syndicateNumber,
    id: dto.id,
  });
}

export interface DoctorServiceProvider {
  id: number;
}
export enum ListFilter {
  ALL = 'all',
  RECENTLY_VIEWED = 'recently_viewed',
  UPDATED_AT = 'updatedAt',
  CREATED_AT = 'createdAt',
}
export class User {
  avatar: string;

  averageRating: number;

  gender: string;

  id: number;

  name: string;

  description: string;

  fcmToken: string;

  bloodGroup: string;

  coordinatorName?: string;

  maritalStatus: string;

  serviceProvider: ServiceProvider;

  email: string;

  firebaseUserId: string;

  isSmoking: boolean;

  isEmailVerified: boolean;

  totalConsultations: number;

  phone: string;

  isPhoneVerified: boolean;

  patient: Patient;

  appLanguage: string;

  about: string;
}

export class ServiceProvider {
  agreeToTermsAndPolicy: boolean;

  area: string;

  commercialRecord: string;

  taxId: string;

  coordinatorName: string;

  coordinatorPhone: string;

  createdAt: Date;

  docs: string[];

  website: string;

  id: number;

  isActive: boolean;

  landlinePhone: string;

  serviceProviderType: ServiceProviderType;

  location: MapLocation;

  signupCode: string;
}

export class Speciality {
  id: string;

  title: string;
}
export interface Rating {
  doctorId: number;
  requestId: number;
  rating: number;
  callRating?: number;
  comment?: string;
  callComment?: string;
  createdAt?: Date;
  raterUser?: User;
}

export interface Clinic {
  id: number;
  name: string;
  mobileNumber: string;
  landlineNumber: string;
  fees: string;
  governorate: string;
  city: string;
  area: string;
  address: string;
  isFreeClinic?: boolean;
}
export class DoctorsTable {
  code : number;

  avatar: string;

  id: number;

  name: string;

  syndicateNumber: string;

  specialty : string;

  grade: string;

  phone: string;

  status : DoctorStatus;

  hospital: string;

  lastCheckIn: Date;

  // static toDTO = (table: DoctorsTable) => table as Doctor;
}
