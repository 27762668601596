import TextField from '@material-ui/core/TextField';
import {
  getIn,
  useFormikContext,
} from 'formik';
import React, {
  useEffect,
} from 'react';
import FieldError from '../../Utils/FieldError';
import {
  FieldPropsCommon,
  InputFieldValue,
} from '../types';
import {
  onChange,
} from '../utils';
import {
  FieldView,
} from '../../Utils/FieldView';

type Props = FieldPropsCommon & InputFieldValue;

const InputField = <FormModel extends object>(props: Props) => {
  const {
    values,
    handleChange,
    errors,
    touched,
    isSubmitting,
    setFieldTouched,
  } = useFormikContext<FormModel>();

  const {
    title,
    location,
    fieldOptions: {
      type,
      disabled,
      placeholder,
    },
    viewMode,
  } = props;

  const value: string = getIn(values, location);
  const onValueChange = onChange(handleChange, location);
  const error = getIn(errors, location);
  const isTouched = getIn(touched, location);

  useEffect(() => {
    if (isSubmitting) {
      setFieldTouched(location);
    }
  }, [isSubmitting, location, setFieldTouched]);

  if (viewMode) {
    return (
      <FieldView
        title={title}
        value={value}
      />
    );
  }

  if (!values) {
    return null;
  }

  return (
    <>
      <TextField
        style={{
          margin: 8,
        }}
        value={value}
        variant="outlined"
        disabled={disabled}
        onChange={(e) => {
          onValueChange(e.target.value);
        }}
        onBlur={() => setFieldTouched(location)}
        label={title}
        type={type}
        placeholder={placeholder}
      />
      <FieldError
        errors={error}
        touched={isTouched}
      />
    </>
  );
};

export default InputField;
