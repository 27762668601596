import {
  action,
  computed,
  observable,
} from 'mobx';
import {
  persist,
} from 'mobx-persist';
import {
  createModelSchema,
  primitive,
} from 'serializr';
import {
  LanguagesKey,
} from 'src/translations/types';
import {
  Language,
} from 'shared';
import {
  BaseUIStore,
} from '../types';
import {
  UIStores,
} from '..';

createModelSchema(Language, {
  key: primitive(),
  title: primitive(),
  isRTL: primitive(),
});

export class LocalizationStore extends BaseUIStore {
  @persist('object', Language) @observable private _currentLanguage: Language = new Language(LanguagesKey.EN, false);

  constructor(
    public parent: UIStores,
  ) {
    super();
    this.makeObservable();
    this.registerObservableDrivers();
  }

  @computed get currentLanguage() {
    return this._currentLanguage;
  }

  @action setCurrentLanguage(language: Language) {
    this._currentLanguage = language;
  }
}
